import React, { Component } from 'react';
import { customToFixed, wholeNumberRoundOff, getImgSrc, getSignedUnit, roundOffTHAOrientation } from '../../helpers/GlobalFunctions';
import PatientDetails from './PatientDetails';
import CaseReport from './CaseReport';
import Comments from './Comments';
import { SurgeonPreferences } from '../../helpers/constants';

export default class HIPSummary extends Component {
	render() {
		const { caseData, isLoading, handleNoteSubmit, notesError, notesSaved, isReadOnlyNotes, handleNotesInputChange, getUserRoleType,caseId } = this.props;
		let caseDetails = caseData && caseData.caseDetails ? caseData.caseDetails : '';
		let caseSummary = caseData && caseData.caseSummary ? caseData.caseSummary : '';
		let cupOrientation = caseSummary.cupOrientation ? caseSummary.cupOrientation : null;
		let measurements = {
			acetabular: caseSummary.acetabular ? caseSummary.acetabular : null
		}
		
		const surgeonPreference = caseDetails.surgeonPreference && SurgeonPreferences.THAMeasurement.ALL.indexOf(caseDetails.surgeonPreference.toString().toUpperCase()) > -1 ?
			caseDetails.surgeonPreference.toString().toUpperCase()
			: SurgeonPreferences.THAMeasurement.BOTH;

		return (
			<>
				<div className="case-details-container">
					<div className="case-details-left">
						{/* Patient Details */}
						<div className="case-details-left-blocks">
							<PatientDetails caseDetails={caseDetails} getUserRoleType={getUserRoleType} />
						</div>

						{/* Case Report */}
						<div className="case-details-left-blocks">
							<CaseReport caseData={caseData} caseDetails={caseDetails} />
						</div>

						{/* Comments */}
						<div className="case-details-left-blocks">
							<Comments
								caseData={caseData}
								height={150}
								isReadOnlyNotes={isReadOnlyNotes}
								notesError={notesError}
								notesSaved={notesSaved}
								handleNotesInputChange={handleNotesInputChange}
								handleNoteSubmit={handleNoteSubmit}
								getUserRoleType={getUserRoleType}
								caseId={caseId}
							/>
						</div>
						{isLoading ? <div className="isLoading-overlay"><span>Saving...</span></div> : null}
					</div>
					{/* Case details left part ends */}
					{/* Case details rights part start */}
					<div className="case-details-right">
						{/* Cup orientation block starts */}
						<div className="case-details-right-blocks">
							<div className="card-body">
								<h2 className="card-title">CUP ORIENTATION</h2>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group row">
											<label className="col-md-5 col-form-label">Inclination APP</label>
											<div className="col-md-6">
												<span className="form-control">{cupOrientation && !isNaN(parseFloat(cupOrientation.inclinationApp)) ? `${wholeNumberRoundOff(cupOrientation.inclinationApp)}°` : 'N/A'}</span>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-md-5 col-form-label">Inclination FPP</label>
											<div className="col-md-6">
												<span className="form-control">{cupOrientation && !isNaN(parseFloat(cupOrientation.functionalInclination)) ? `${wholeNumberRoundOff(cupOrientation.functionalInclination)}°` : 'N/A'}</span>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-md-5 col-form-label">Anteversion APP</label>
											<div className="col-md-6">
												<span className="form-control">{cupOrientation && !isNaN(parseFloat(cupOrientation.anteversionApp)) ? `${wholeNumberRoundOff(cupOrientation.anteversionApp)}°` : 'N/A'}</span>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-md-5 col-form-label">Anteversion FPP</label>
											<div className="col-md-6">
												<span className="form-control">{cupOrientation && !isNaN(parseFloat(cupOrientation.functionalAnteversion)) ? `${wholeNumberRoundOff(cupOrientation.functionalAnteversion)}°` : 'N/A'}</span>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-md-5 col-form-label">Pelvic Tilt</label>
											<div className="col-md-6">
												<span className="form-control">{caseSummary && !isNaN(parseFloat(caseSummary.pelvicTilt)) ? `${roundOffTHAOrientation(caseSummary.pelvicTilt)}° ${getSignedUnit(caseSummary.pelvicTilt, 'orientation')}` : 'N/A'}</span>
											</div>
										</div>
									</div>
									<div className="col-md-6 text-center">
										<img className="hip-cup-img" src={getImgSrc('HIP_CupOrientation.png')} alt="HIP_CupOrientation" />
									</div>
								</div>
							</div>
						</div>
						{/* Cup orientation block ends */}
						{/* Case Measurements block starts */}
						<div className="case-details-right-blocks">
							<div className="measurements-wrap">
								<div className="card-body">
									<h2 className="card-title">Measurements</h2>
									<div className="row">
										<div className="col-md-12 d-flex align-items-center justify-content-center">
											<div className="d-flex align-items-center justify-content-center hip-measurements hip-measurements-top">
												<div className="d-flex flex-column justify-content-center align-items-center">
													<span className="measurement-title">ASIS Distance</span>
													<span className="measurement-value text-orange fw-600">
														{measurements && measurements.acetabular && !isNaN(parseFloat(measurements.acetabular.asisDistance)) ? `${customToFixed(measurements.acetabular.asisDistance, 2)} mm` : 'N/A'}
													</span>
												</div>
												<div className="d-flex flex-column justify-content-center align-items-center">
													{/* Image */}
													<img className="hip-img hip-img-top" src={getImgSrc('HIP_Measurements.png')} alt="HIP_Measurements" />

													{/* Bottom of image */}
													<div className="d-flex flex-column justify-content-center align-items-center">
														<span className="measurement-value text-orange fw-600">
															{measurements && measurements.acetabular && !isNaN(parseFloat(measurements.acetabular.acetabularRadius)) ? `${customToFixed((measurements.acetabular.acetabularRadius * 2), 2)} mm` : 'N/A'}
														</span>
														<span className="measurement-title">Acetabular Diameter</span>
													</div>
												</div>
												<div className="d-flex flex-column justify-content-center align-items-center">
													<span className="measurement-title">Acetabular Distance</span>
													<span className="measurement-value text-orange fw-600">
														{measurements && measurements.acetabular && !isNaN(parseFloat(measurements.acetabular.acetabularDistance)) ? `${customToFixed(measurements.acetabular.acetabularDistance, 2)} mm` : 'N/A'}
													</span>
												</div>
											</div>
										</div>
									</div>
									<div className="row mt-5">
										<div className="col-md-6 case-component-border d-flex align-items-center justify-content-center mid-section">
											<div className="d-flex align-items-center justify-content-center hip-measurements">
												{/* Left of image */}
												<div className="d-flex flex-column">
													<div className="d-flex justify-content-between align-items-center">
														<span className="measurement-title">Leg Length</span>
														<span className="measurement-value text-purple fw-600 ms-3">
															{measurements && caseSummary && caseSummary.leg && !isNaN(parseFloat(caseSummary.leg.legLength)) ? `${customToFixed(caseSummary.leg.legLength, 2)} mm` : 'N/A'}
														</span>
													</div>
													<div className="d-flex justify-content-between align-items-center mt-3">
														<span className="measurement-title">Offset</span>
														<span className="measurement-value text-purple fw-600 ms-3">
															{caseSummary && caseSummary.leg && !isNaN(parseFloat(caseSummary.leg.offset)) ? `${customToFixed(caseSummary.leg.offset, 2)} mm` : 'N/A'}
														</span>
													</div>
												</div>

												{/* Image */}
												<img className="hip-img hip-img-lower hip-img-left" src={getImgSrc('HIP_LegDisplacement.png')} alt="HIP_LegDisplacement" />
											</div>
										</div>
										<div className="col-md-6 d-flex align-items-center justify-content-center">
											<div className="d-flex align-items-center justify-content-center hip-measurements">
												{/* Image */}
												<img className="hip-img hip-img-lower hip-img-right" src={getImgSrc('HIP_PelvicTilt_Side.png')} alt="HIP_PelvicTilt_Side" />

												{/* Right of image */}
												<div className="d-flex flex-column">
													{/* Inclination */}
													<div className="d-flex justify-content-between align-items-center">
														<span className="measurement-value fw-600 me-3">
															{surgeonPreference === SurgeonPreferences.THAMeasurement.APP ?
																<span className="text-purple">
																	{cupOrientation && !isNaN(parseFloat(cupOrientation.inclinationApp)) ?
																		`${wholeNumberRoundOff(cupOrientation.inclinationApp)}°`
																		: 'N/A'}
																</span>
																: ''}
															{surgeonPreference === SurgeonPreferences.THAMeasurement.FPP ?
																<span className="text-green">
																	{cupOrientation && !isNaN(parseFloat(cupOrientation.functionalInclination)) ?
																		`${wholeNumberRoundOff(cupOrientation.functionalInclination)}°`
																		: 'N/A'}
																</span>
																: ''}
															{surgeonPreference === SurgeonPreferences.THAMeasurement.BOTH ?
																<>
																	<span className="text-purple">
																		{cupOrientation && !isNaN(parseFloat(cupOrientation.inclinationApp)) ?
																			`${wholeNumberRoundOff(cupOrientation.inclinationApp)}°`
																			: 'N/A'}
																	</span>
																	<span className="text-light-grey">&nbsp;/&nbsp;</span>
																	<span className="text-green">
																		{cupOrientation && !isNaN(parseFloat(cupOrientation.functionalInclination)) ?
																			`${wholeNumberRoundOff(cupOrientation.functionalInclination)}°`
																			: 'N/A'}
																	</span>
																</>
																: ''}
														</span>
														<span className="measurement-title">Inclination</span>
													</div>
													{/* Anterversion */}
													<div className="d-flex justify-content-between align-items-center mt-3">
														<span className="measurement-value fw-600 me-3">
															{surgeonPreference === SurgeonPreferences.THAMeasurement.APP ?
																<span className="text-purple">
																	{cupOrientation && !isNaN(parseFloat(cupOrientation.anteversionApp)) ?
																		`${wholeNumberRoundOff(cupOrientation.anteversionApp)}°`
																		: 'N/A'}
																</span>
																: ''}
															{surgeonPreference === SurgeonPreferences.THAMeasurement.FPP ?
																<span className="text-green">
																	{cupOrientation && !isNaN(parseFloat(cupOrientation.functionalAnteversion)) ?
																		`${wholeNumberRoundOff(cupOrientation.functionalAnteversion)}°`
																		: 'N/A'}
																</span>
																: ''}
															{surgeonPreference === SurgeonPreferences.THAMeasurement.BOTH ?
																<>
																	<span className="text-purple">
																		{cupOrientation && !isNaN(parseFloat(cupOrientation.anteversionApp)) ?
																			`${wholeNumberRoundOff(cupOrientation.anteversionApp)}°`
																			: 'N/A'}
																	</span>
																	<span className="text-light-grey">&nbsp;/&nbsp;</span>
																	<span className="text-green">
																		{cupOrientation && !isNaN(parseFloat(cupOrientation.functionalAnteversion)) ?
																			`${wholeNumberRoundOff(cupOrientation.functionalAnteversion)}°`
																			: 'N/A'}
																	</span>
																</>
																: ''}
														</span>
														<span className="measurement-title">Anteversion</span>
													</div>
													<div className="d-flex flex-column justify-content-center align-items-start mt-3">
														{[SurgeonPreferences.THAMeasurement.APP, SurgeonPreferences.THAMeasurement.BOTH].indexOf(surgeonPreference) > -1 ?
															<span className="tha-measurement-index"><span className="index-legend legend-app"></span>{SurgeonPreferences.THAMeasurement.APP}</span>
															: ''}
														{[SurgeonPreferences.THAMeasurement.FPP, SurgeonPreferences.THAMeasurement.BOTH].indexOf(surgeonPreference) > -1 ?
															<span className="tha-measurement-index"><span className="index-legend legend-fpp"></span>{SurgeonPreferences.THAMeasurement.FPP}</span>
															: ''}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* Case Measurements block ends */}
					</div>
					{/* Case details right part end */}
				</div>
			</>
		)
	}
}
