import React, { Component } from 'react'
import { caseTypes, operativeProcedureTypes } from '../../helpers/constants';
import { formatDate, toTitleCase, tensionerForceRange } from '../../helpers/GlobalFunctions';

export default class CaseReport extends Component {
	/**
     * @description function to get the operative procedure text to show
     * @param operativeProcedure Raw response text form API
	 * @returns Formatted text to be shown
     * @memberof CaseReport
     */
	getOperativeProcedureText = (operativeProcedure) => {
		if (!operativeProcedure) {
			return 'N/A';
		}
		switch (operativeProcedure) {
			case operativeProcedureTypes.TKA.inLowerCase:
				return operativeProcedureTypes.TKA.text;
			case operativeProcedureTypes.HIP.inLowerCase:
				return operativeProcedureTypes.HIP.text;

			default:
				return operativeProcedure.toUpperCase();
		}
	}

	/**
     * @description function to get the case type text to show
     * @param caseType Raw response text form API
	 * @returns Formatted text to be shown
     * @memberof CaseReport
     */
	getCaseTypeName = (caseType) => {
		if (!caseType) {
			return 'N/A';
		}
		
		if (caseTypes.imageBased.inLowerCase.indexOf(caseType.toLowerCase()) > -1) {
			return caseTypes.imageBased.text;
		}

		if (caseTypes.imageFree.inLowerCase.indexOf(caseType.toLowerCase()) > -1) {
			return caseTypes.imageFree.text;
		}
	}

	render() {
		const { caseData, caseDetails, showForceRange } = this.props;
		let cartDetails = caseData && caseData.cartDetails ? caseData.cartDetails : '';
		let softwareVer = cartDetails.softwareDetails ? cartDetails.softwareDetails.version : 'N/A';
		let operativeProcedure = caseDetails?.operativeProcedure ? caseDetails.operativeProcedure.toString().toLowerCase() : '';

		return (
			<div className="card-body">
				<h2 className="card-title">Case Report</h2>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label">{operativeProcedure === operativeProcedureTypes.HIP.inLowerCase && caseDetails.patientID ? 'Patient ID' : 'Case ID'}</label>
					<div className="col-sm-8">
						<span className="form-control">{operativeProcedure === operativeProcedureTypes.HIP.inLowerCase && caseDetails.patientID ? caseDetails.patientID : caseDetails?.caseID ? caseDetails.caseID : 'N/A'}</span>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label">Date Of Surgery</label>
					<div className="col-sm-8">
						<span className="form-control">{caseDetails?.dateOfSurgery ? formatDate(caseDetails.dateOfSurgery) : 'N/A'}</span>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label">Procedure</label>
					<div className="col-sm-8">
						<span className="form-control">{this.getOperativeProcedureText(operativeProcedure)}</span>
					</div>
				</div>
				<div className="form-group row">
					<label className="col-sm-4 col-form-label">Operative Side</label>
					<div className="col-sm-8">
						<span className="form-control">{caseDetails?.operativeSide ? toTitleCase(caseDetails.operativeSide) : 'N/A'}</span>
					</div>
				</div>
				{operativeProcedure === operativeProcedureTypes.TKA.inLowerCase ?
					<div className="form-group row">
						<label className="col-sm-4 col-form-label">Implant Design</label>
						<div className="col-sm-8">
							<span className="form-control">{caseDetails?.finalImplantDesign ? caseDetails.finalImplantDesign : 'N/A'}</span>
						</div>
					</div> : ''}
				{operativeProcedure === operativeProcedureTypes.HIP.inLowerCase ?
					<div className="form-group row">
						<label className="col-sm-4 col-form-label">Surgical Approach</label>
						<div className="col-sm-8">
							<span className="form-control">{caseDetails?.surgicalApproach ? caseDetails.surgicalApproach : 'N/A'}</span>
						</div>
					</div> : ''}
				{operativeProcedure === operativeProcedureTypes.HIP.inLowerCase ?
					<div className="form-group row">
						<label className="col-sm-4 col-form-label">Workflow Type</label>
						<div className="col-sm-8">
							<span className="form-control">{caseDetails?.workflowType ? caseDetails.workflowType : 'N/A'}</span>
						</div>
					</div> : ''}
				<div className="form-group row">
					<label className="col-sm-4 col-form-label">Software Version</label>
					<div className="col-sm-8">
						<span className="form-control">{softwareVer}</span>
					</div>
				</div>
				{operativeProcedure === operativeProcedureTypes.TKA.inLowerCase ?
					<>
						<div className="form-group row">
							<label className="col-sm-4 col-form-label">Femur Implant Size</label>
							<div className="col-sm-8">
								<span className="form-control"> {caseDetails?.finalFemurImplantSize ? caseDetails.finalFemurImplantSize : 'N/A'}</span>
							</div>
						</div>
						<div className="form-group row">
							<label className="col-sm-4 col-form-label">Tibia Implant Size</label>
							<div className="col-sm-8">
								<span className="form-control">{caseDetails?.finalTibiaImplantSize ? caseDetails.finalTibiaImplantSize : 'N/A'}</span>
							</div>
						</div>
						<div className="form-group row">
							<label className="col-sm-4 col-form-label">Tibia Insert Thickness</label>
							<div className="col-sm-8">
								<span className="form-control">{caseDetails?.finalTibiaThickness ? caseDetails.finalTibiaThickness : 'N/A'}</span>
							</div>
						</div>
						<div className="form-group row">
							<label className="col-sm-4 col-form-label">Gaps Collected</label>
							<div className="col-sm-8">
								<span className="form-control">{caseDetails?.gapsCollected ? caseDetails.gapsCollected : 'N/A'}</span>
							</div>
						</div>
						{caseDetails?.visionaire ?
							<>
								<div className="form-group row">
									<label className="col-sm-4 col-form-label">Workflow</label>
									<div className="col-sm-8">
										<span className="form-control">{this.getCaseTypeName(caseDetails.caseType)}</span>
									</div>
								</div>
								{caseDetails?.caseType && caseTypes.imageBased.inLowerCase.indexOf(caseDetails?.caseType.toLowerCase()) > -1  ?
									<div className="form-group row">
										<label className="col-sm-4 col-form-label">Plan ID</label>
										<div className="col-sm-8">
											<span className="form-control">{caseDetails?.visionaireID ? caseDetails.visionaireID : 'N/A'}</span>
										</div>
									</div>
									: ''}
							</> : ''}
						{showForceRange ?
							<div className="form-group row">
								<label className="col-sm-4 col-form-label">Force Range </label>
								<div className="col-sm-8">
									<span className="form-control">{caseDetails?.forceRange ? tensionerForceRange(caseDetails.forceRange) : 'N/A'}</span>
								</div>
							</div> : ''}
					</> : ''}
			</div>
		)
	}
}
