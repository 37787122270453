import AWS from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';
import Amplify, { Auth } from 'aws-amplify';
import environment from '../../config/environment';
import jwt_decode from 'jwt-decode';
import { cognitoConstants, Roles } from '../../helpers/constants';
import { clearLocalStorage, clearSessionStorage, getLocalStorage } from '../storage/storage-service';
/**
 * @description function to configure AWS amplify by passing all configure details
 * @param  {} environment
 */
Amplify.configure(environment);


/**
 * @description function to access s3 bucket by passing bucket name
 * @param  {} bucket
 */
/* istanbul ignore next  */
const newS3 = async (bucket) => {
	return setCognitoConfigObject().then(() => {
		const clientParams = {
			region: environment.region,
			params: { useAccelerateEndpoint: false, Bucket: bucket },
		};
		const s3 = new S3(clientParams);
		return s3;
	}).catch(err => {
		return { message: err.message, statusCode: err.statusCode, code: err.code };
	});
}

/**
 * @description Function for aws signin api call with  cognito user credentials
 * @param  {} userName
 * @param  {} password
 */
/* istanbul ignore next  */
const loginService = async (userName, password) => {
	return (await Auth.signIn(userName, password));
};

/**
 * @description Function for aws logout api call for cognito user
 * @param  {}
 */
/* istanbul ignore next  */
const authSignOut = async () => {
	return (await Auth.signOut());
};

/**
 * @description Function for change password api call with cognito user details
 * @param  {} oldPassword
 * @param  {} newPassword
 */
/* istanbul ignore next  */
const changePassword = async (oldPassword, newPassword) => {
	let user = await Auth.currentAuthenticatedUser();
	return (await Auth.changePassword(user, oldPassword, newPassword));
};

/**
 * @description Function for forgot password api call with cognito user details (Send confirmation code to user's email)
 * @param  {} username
 */
/* istanbul ignore next  */
const forgotPassword = async (username) => {
	return (await Auth.forgotPassword(username));
};

/**
 * @description Function for forgot password submit( Collect confirmation code and new password)
 * @param  {} username
 * @param  {} code
 * @param  {} new_password
 */
/* istanbul ignore next  */
const forgotPasswordSubmit = async (username, code, new_password) => {
	return (await Auth.forgotPasswordSubmit(username, code, new_password));
};

/**
 * @description Function for logout service
 * @param  {}
 */
/* istanbul ignore next  */
const logoutService = async (callback) => {
	authSignOut()
		.then(result => {
			clearSessionStorage();
			clearLocalStorage();
			window.location = '/login';
			callback(null, result)
		})
		.catch(error => {
			callback(error, null)
		})
};

/**
 * @description Function to set cognito object after login  
 * @param  {}
 */
/* istanbul ignore next  */
const setCognitoConfigObject = async () => {
	let userPoolConfig = environment.userPoolAwsLogin;
	let loginObj = {};
	loginObj[userPoolConfig] = await getCognitoIdTokenFromStorage();
	let awsCredentials = new AWS.CognitoIdentityCredentials({
		IdentityPoolId: environment.identityPoolId,
		Logins: loginObj
	});
	AWS.config.credentials = awsCredentials;
	AWS.config.region = environment.region;
	AWS.config.httpOptions.timeout = 0;
}

/**
 * @description Function to get cognito object after login
 * @param  {}
 */
/* istanbul ignore next  */
const getCognitoIdTokenFromStorage = () => {
	let cognitoIdToken = undefined;
	for (const key in window.localStorage) {
		if (key.includes('.idToken')) {
			cognitoIdToken = getLocalStorage(key);
		}
	}
	return cognitoIdToken;
}

/**
 * @description Function to get logged in username
 * @param  {}
 */
/* istanbul ignore next  */
const getUserName = () => {
	let userName = undefined;
	for (const key in window.localStorage) {
		if (key.includes('.LastAuthUser')) {
			userName = getLocalStorage(key);
		}
	}
	return userName;
}

/**
 * @description Function to get logged in user details after login
 * @param  {}
 */
/* istanbul ignore next  */
const getUserDetails = () => {
	const gerUserDetails = JSON.parse(getLocalStorage('cognito_data'));
	return gerUserDetails;
}
/**
 * @description Function to decode the JWT token
 * @param  {}
 */
/* istanbul ignore next  */
const decodeToken = (idToken) => {
	let decodedIdToken = undefined;
	if (idToken) {
		decodedIdToken = jwt_decode(idToken);
	}
	return decodedIdToken;
}
/**
 * @description Function to get logged in user role
 * @param  {}
 */
/* istanbul ignore next  */
const getUserRoleType = () => {
	const idToken = getCognitoIdTokenFromStorage();
	const decodedToken = idToken ? decodeToken(idToken) : '';
	var rolesArray;
	if (decodedToken && decodedToken['cognito:groups']?.length) {
		rolesArray = decodedToken['cognito:groups'].map(role => {
			return Roles.find(x => x.cognitoGroupName === role);
		})
	}
	return rolesArray ? rolesArray.map(x => x?.userRole?.toLowerCase()) : [];
}

/**
 * @description Function to download file from S3 bucket 
 * @param  {}
 */
/* istanbul ignore next  */
const downloadObject = async (filepath, callback) => {
	let bucketName = environment.s3BucketName;
	let s3 = await newS3(bucketName);
	let mediaKey = filepath;
	let param = {
		Key: mediaKey
	};
	await s3.getSignedUrl('getObject', param, (err, res) => {
		if (err) {
			callback(err, null)
		} else {
			callback(null, res)
		}
	});
}


/**
 * @description Function to update user details in cognito
 * @param  {}
 */
/* istanbul ignore next  */
const updateUserDetailsCognito = (username, userAttributes) => {
	return setCognitoConfigObject().then(() => {
		var cognitoClient = new AWS.CognitoIdentityServiceProvider();
		var params = {
			UserAttributes: userAttributes,
			UserPoolId: environment.userPoolId,
			Username: username
		};

		return new Promise((resolve, reject) => {
			cognitoClient.adminUpdateUserAttributes(params, (err, res) => {
				if (err) {
					return reject({ message: err.message, statusCode: err.statusCode, code: err.code });
				}
				if (res) {
					return resolve(res);
				}
			})
		})
	}).catch(err => {
		return Promise.reject({ message: err.message, statusCode: err.statusCode, code: err.code });
	});

}

/**
 * @description Function to sign up a user in cognito
 * @param  {}
 */
/* istanbul ignore next  */
const userSignUpCognito = (userName, password, userAttributes) => {
	return setCognitoConfigObject().then(() => {
		var cognitoClient = new AWS.CognitoIdentityServiceProvider();
		var params = {
			UserPoolId: environment.userPoolId,
			Username: userName,
			TemporaryPassword: password,
			UserAttributes: userAttributes,
			DesiredDeliveryMediums: [],
			MessageAction: cognitoConstants.messageActions.SUPPRESS
		};

		return new Promise((resolve, reject) => {
			cognitoClient.adminCreateUser(params, (err, res) => {
				if (err) {
					return reject({ message: err.message, statusCode: err.statusCode, code: err.code });
				}
				if (res) {
					return resolve(res);
				}
			})
		})
	}).catch(err => {
		return Promise.reject({ message: err.message, statusCode: err.statusCode, code: err.code });
	});
}

/**
 * @description Function to add a user to a group in cognito
 * @param  {}
 */
/* istanbul ignore next  */
const addUserToGroupCognito = (userName, groupName) => {
	return setCognitoConfigObject().then(() => {
		var cognitoClient = new AWS.CognitoIdentityServiceProvider();
		var params = {
			UserPoolId: environment.userPoolId,
			Username: userName,
			GroupName: groupName
		};

		return new Promise((resolve, reject) => {
			cognitoClient.adminAddUserToGroup(params, (err, res) => {
				if (err) {
					return reject({ message: err.message, statusCode: err.statusCode, code: err.code });
				}
				if (res) {
					return resolve(res);
				}
			})
		})
	}).catch(err => {
		return Promise.reject({ message: err.message, statusCode: err.statusCode, code: err.code });
	});
}

/**
 * @description Function to enable/disable in cognito
 * @param  {}
 */
/* istanbul ignore next  */
const changeUserStatusCognito = (user) => {
	return setCognitoConfigObject().then(() => {
		var cognitoClient = new AWS.CognitoIdentityServiceProvider();
		var params = {
			UserPoolId: environment.userPoolId,
			Username: user.userName
		};

		return new Promise((resolve, reject) => {
			if (user.enabled) {
				cognitoClient.adminEnableUser(params, (err, res) => {
					if (err) {
						return reject({ message: err.message, statusCode: err.statusCode, code: err.code });
					}
					if (res) {
						return resolve(res);
					}
				});
			} else {
				cognitoClient.adminDisableUser(params, (err, res) => {
					if (err) {
						return reject({ message: err.message, statusCode: err.statusCode, code: err.code });
					}
					if (res) {
						return resolve(res);
					}
				});
			}
		});
	}).catch(err => {
		return Promise.reject({ message: err.message, statusCode: err.statusCode, code: err.code });
	});
}

/**
 * @description Function to get a user
 * @param  {}
 */
/* istanbul ignore next  */
const getCognitoUser = (userName) => {
	return setCognitoConfigObject().then(() => {
		var cognitoClient = new AWS.CognitoIdentityServiceProvider();
		var params = {
			UserPoolId: environment.userPoolId,
			Username: userName
		};

		return new Promise((resolve, reject) => {
			cognitoClient.adminGetUser(params, (err, res) => {
				if (err) {
					return reject({ message: err.message, statusCode: err.statusCode, code: err.code });
				}
				if (res) {
					return resolve(res);
				}
			});
		});
	}).catch(err => {
		return Promise.reject({ message: err.message, statusCode: err.statusCode, code: err.code });
	});
}

/**
 * @description Function to send email via SES
 * @param  {}
 */
/* istanbul ignore next  */
const sendEmail = (params, callback) => {
	return setCognitoConfigObject().then(() => {
		var emailSendPromise = new AWS.SES().sendEmail(params).promise();
		emailSendPromise.then((data) => {
			callback(data);
		}).catch((err) => {
			callback(err);
		});
	}).catch(err => {
		return Promise.reject({ message: err.message, statusCode: err.statusCode, code: err.code });
	});
}

/**
 * @description Function to update user password in cognito
 * @param  {}
 */
/* istanbul ignore next  */
const updateUserPassword = (username, password) => {
	return setCognitoConfigObject().then(() => {
		var cognitoClient = new AWS.CognitoIdentityServiceProvider();
		var params = {
			UserPoolId: environment.userPoolId,
			Username: username,
			Password: password,
			Permanent: false
		};

		return new Promise((resolve, reject) => {
			cognitoClient.adminSetUserPassword(params, (err, res) => {
				if (err) {
					return reject({ message: err.message, statusCode: err.statusCode, code: err.code });
				}
				if (res) {
					return resolve(res);
				}
			})
		})
	}).catch(err => {
		return Promise.reject({ message: err.message, statusCode: err.statusCode, code: err.code });
	});
}

/**
 * @description Function to set a permanent password after first login in cognito
 * @param  {}
 */
/* istanbul ignore next  */
const resetTempPassword = async (user, password) => {
	return Auth.completeNewPassword(user, password);
}

/**
 * @description Function to get authenticated user
 * @param  {}
 */
/* istanbul ignore next  */
const getAuthUser = async () => {
	return Auth.currentAuthenticatedUser();
}

/**
 * @description Function to get param store values
 * @param  {}
 */
/* istanbul ignore next  */
const getSSMParamters = (paramNames) => {
	return setCognitoConfigObject().then(() => {
		var ssmClient = new AWS.SSM();
		var params = {
			Names: paramNames,
			WithDecryption: true
		};

		return new Promise((resolve, reject) => {
			ssmClient.getParameters(params, (err, res) => {
				if (err) {
					return reject({ message: err.message, statusCode: err.statusCode, code: err.code });
				}
				if (res) {
					return resolve(res);
				}
			})
		})
	}).catch(err => {
		return Promise.reject({ message: err.message, statusCode: err.statusCode, code: err.code });
	});
}

export {
	newS3, loginService, logoutService, getCognitoIdTokenFromStorage, getUserDetails, getUserName, downloadObject, getUserRoleType, changePassword,
	forgotPassword, forgotPasswordSubmit, updateUserDetailsCognito, updateUserPassword, sendEmail, userSignUpCognito, addUserToGroupCognito, changeUserStatusCognito,
	resetTempPassword, getAuthUser, getCognitoUser, getSSMParamters
};
