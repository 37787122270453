import React, { Component } from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import { contactForm } from "../../helpers/messages";
import { getCustomerFeedbackTemplate } from "../../helpers/Email";
import { getUserDetails } from "../../services/aws/aws-services";
import { sendContactUsEmail } from "../../services/java/java-services";

export default class ContactUsContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			subject: "",
			errors: [],
			isEditMode: false,
			isSubmitted: false,
			isError: "",
			editorData: props.data || "",
			wordCount: 0,
			maxWordCount: 3000,
			wordLeft: "",
			errorMessage: "",
			error: {
				maxWordCountError: false,
			},
			config: {
				resize_enabled: true,
				removePlugins: ["elementspath", "magicline", "htmlwriter"],
				extraPlugins: "editorplaceholder",
				editorplaceholder: "Start typing here...",
				fillEmptyBlocks: false,
				enterMode: 3,
				tabSpaces: 0,
				height: 300,
				toolbar: [
					["Format"],
					["Bold", "Italic", "BulletedList", "NumberedList", "BlockQuote"],
					["Indent", "Outdent"],
					["Undo", "Redo"],
				],
			},
		};
	}

	/** 
	 *  @description Function to convert the text content of the HTML output from the editor to a string
	 *  @param  str Text content of the HTML output from the editor
	 *  @memberof ContactUsContainer
	 */
	getText = (str) => {
		var tmp = document.createElement("div");
		tmp.innerHTML = str.toString().trim();
		return tmp.innerText ? tmp.innerText : str;
	};

	/** 
	 * 	@description function to handle every input change in the editor and calculate number of words typed
	 *  @param event instance bound to this component instance
	 *  @memberof ContactUsContainer
	 */
	handleInputChange = (event) => {
		const editor = event.editor;
		const editorData = editor ? editor.getData() : '';
		var { errors, maxWordCount } = this.state;
		var inputText = this.getText(editorData) || "";
		var wordCount = inputText ? inputText.split(" ").length : 0;
		var wordLeft = maxWordCount - wordCount;
		if (inputText) {
			errors.editorData = "";
		}
		this.setState({
			error: {
				maxWordCountError: wordCount > maxWordCount,
			},
			editorData: editorData,
			wordCount: wordCount,
			wordLeft: wordLeft,
			wordExceedBy: wordCount - maxWordCount,
			errors: errors,
		});
	};

	/** 
	 *  @description Function to handle the input field and value
	 *  @param  field  The input field
	 *  @param  value  The input value
	 *  @memberof ContactUsContainer
	 */
	handleChange = (field, value) => {
		var errors = {};
		if (field === "subject") {
			errors.subject = "";
			this.setState({ errors, subject: value });
		}
	};

	/** 
	 * 	@description function to handle submission of the feedback form content.
	 *  @param  e Click/keyup event
	 *  @memberof ContactUsContainer
	 */
	handleSubmit = (e) => {
		e.preventDefault();
		if (this.validateForm()) {
			this.sendEmailFeedback();
		}
		setTimeout(() => {
			this.setState({ isError: "" });
		}, 0);
	};

	/** 
	 *  @description function to validation of feedback form.
	 *  @memberof ContactUsContainer
	 */
	validateForm = () => {
		let { subject, editorData, maxWordCount } = this.state;
		let formIsValid = true;
		let errors = {};

		if (!subject.trim()) {
			formIsValid = false;
			errors["subject"] = contactForm.subject;
		}
		else if (!editorData || !editorData.length) {
			formIsValid = false;
			errors["editorData"] = contactForm.editorData;
		} else {
			var inputText = this.getText(editorData) || "";
			var wordCount = inputText ? inputText.split(" ").length : 0;
			formIsValid = maxWordCount >= wordCount;
		}
		this.setState({
			errors: errors,
		});

		return formIsValid;
	};

	/** 
	 * 	@description function to send the feedback email.
	 *  @memberof ContactUsContainer
	 */
	sendEmailFeedback = () => {
		this.props.toggleLoading(true);
		const getUserDetail = getUserDetails();
		const params = {
			subject: this.state.subject,
			body: getCustomerFeedbackTemplate(getUserDetail, this.state.editorData)
		};

		sendContactUsEmail(params, (err, result) => {
			this.props.toggleLoading(false);
			// If something goes wrong, print an error message.
			if (err) {
				this.setState({ errorMessage: err.message, isError: false });
			} else {
				this.setState({ isError: true, subject: '', editorData: '', isSubmitted: true });
			}
		});
	};

	/** 
	 * 	@description Function to handle submission flag. Used to clear editor data
	 *  @param isSubmitted Boolean value
	 *  @memberof ContactUsContainer
	 */
	toggleIsSubmitted = (isSubmitted) => {
		this.setState({ isSubmitted })
	}

	render() {
		return (
			<ContactForm
				config={this.state.config}
				isEditMode={this.state.isEditMode}
				editorData={this.state.editorData}
				handleInputChange={this.handleInputChange}
				handleChange={this.handleChange}
				subject={this.state.subject}
				handleSubmit={this.handleSubmit}
				isSubmitted={this.state.isSubmitted}
				toggleIsSubmitted={this.toggleIsSubmitted}
				errors={this.state.errors}
				maxWordCount={this.state.maxWordCount}
				wordLeft={this.state.wordLeft}
				maxWordCountError={this.state.error.maxWordCountError}
				errorMessage={this.state.errorMessage}
				isError={this.state.isError}
			/>
		);
	}
}