import React, { Component } from 'react'

export class SideMenuToggle extends Component {
  render() {
    const { showSideMenuOnMobile, isSideMenuOpen } = this.props
    return (
      <div className='toggle-btn'>
        {isSideMenuOpen ?
          <svg id="closeBtn" onClick={() => showSideMenuOnMobile()} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0,0,255.98959,255.98959"
            style={{ fill: "#000000" }}>
            <g transform=""><g fill="#73bc51" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: "normal" }}><g transform="scale(10.66667,10.66667)"><path d="M4.70703,3.29297l-1.41406,1.41406l7.29297,7.29297l-7.29297,7.29297l1.41406,1.41406l7.29297,-7.29297l7.29297,7.29297l1.41406,-1.41406l-7.29297,-7.29297l7.29297,-7.29297l-1.41406,-1.41406l-7.29297,7.29297z"></path></g></g></g>
          </svg>
          :
          <svg id="openBtn" onClick={() => showSideMenuOnMobile()} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0,0,255.98959,255.98959"
            style={{ fill: "#000000" }}>
            <g transform="translate(15.99935,15.99935) scale(0.875,0.875)"><g fill="#73bc51" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixBlendMode: "normal" }}><g transform="scale(16,16)"><rect x="0" y="7" width="16" height="2"></rect><rect x="0" y="2" width="16" height="2"></rect><rect x="0" y="12" width="16" height="2"></rect></g></g></g>
          </svg>
        }
      </div>
    )
  }
}

export default SideMenuToggle