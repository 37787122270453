import React from "react";
import EditPromsContainer from '../../containers/CaseDetails/EditProms';
import { eventSource, operativeProcedureTypes } from '../../helpers/constants';
import UserDetailsContainer from '../../containers/UserDetails';
import { promScoreMessages, actions, surgeonMapping, reviewCaseDetailsMessages, deviceMessages, userMessages, salesRepMapping, errorMessages, salesRepMessages } from '../../helpers/messages';
import { useTable, useSortBy, useFilters, useExpanded, usePagination } from "react-table";
import {
	procedureMetadataListService,
	caseListService,
	getPromScoreHistoryService,
	deletePromScoreService,
	saveDeviceInfo,
	dataAssociationReqService,
	dataAssociationReqListService,
	getReviewCases,
	customerListService,
	getFileDetailsService,
	loggerGetAPI,
	surgeonListService,
	getSurgeonCustomersService,
	getAllUsersList,
	getAllDevicesList,
	getExpiredLicenseList,
	getPreopCustomersService,
	getFilteredPreOpPlansService,
	getPendingCustomerAssociationService,
	deleteSalesRepCustomerService,
	getAllPreOpListService,
	getCustomerSupportPreOpListService,
	getCustomerSupportPreOpActiveListService,
	getCustSupportPlanHistoryService
} from "../../services/java/java-services";
import {
	downloadObject,
	newS3,
	logoutService,
	getUserRoleType,
	getUserName,
} from "../../services/aws/aws-services";
import environment from "../../config/environment";
import { Link } from "react-router-dom";
import CustomModal from "../../shared/CustomModal";
import { checkLocalStorage, getLocalStorage, setLocalStorage } from "../../services/storage/storage-service";
import { convertUTCToLocalDateTime, isAuthenticated, parseHTML } from '../../helpers/GlobalFunctions';
import AdminPasswordResetContainer from "../../containers/AdminPasswordReset";
import ErrorMsgBlockComponent from "../Error/ErrorMessageBlock";
import UserStatusContainer from "../../containers/UserList/UserStatus";
import SubRowsReviewPlans from "../ReviewPlanList/SubRowsReviewPlans";
import UpdateReviewContainer from "../../containers/ReviewPlanList/UpdateReviewContainer";
import UpdateCSContainer from "../../containers/UpdateCustomerSupport/UpdateCSContainer";


// Define a default UI for filtering
/* istanbul ignore next  */
function DefaultColumnFilter({
	column: { filterValue, preFilteredRows, setFilter },
}) {
	const count = preFilteredRows.length;
	return (
		<input
			value={filterValue || ""}
			onChange={(e) => {
				setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
			}}
			placeholder={`Search ${count} records...`}
		/>
	);
}

// This could be inlined into SubRowAsync, this this lets you reuse it across tables
/* istanbul ignore next  */
function SubRows({ row, rowProps, visibleColumns, data, subRowError, loading }) {
	if (loading) {
		return (
			<tr>
				<td colSpan={visibleColumns.length}>
					Loading...
				</td>
			</tr>
		);
	}
	// error handling here :)

	return (
		<>
			{data.length > 0 ?
				<tr className="file-details-tr-text">
					<td colSpan="1"></td>
					<td colSpan="4">
						<table className="file-details-table">
							<thead>
								{data.length > 0 ?
									<tr className="file-details-col-heading">
										<th>Case ID</th>
										<th>Load Date / Time</th>
										<th>Status</th>
										<th>Details</th>
										<th>Case Type</th>
										<th>Software Version</th>

									</tr> : <tr className="file-details-tr-text"><td colSpan="5"></td></tr>}
							</thead>
							<tbody>
								{data.map((x, i) => {
									let isDuplicate = 'New Case'
									if (data[i].content && data[i].content.CumulusResponse && data[i].content.CumulusResponse.error.errorCode === 2004) {
										isDuplicate = 'Duplicate';
									} else if (data[i].content && data[i].content.CumulusResponse && (data[i].content.CumulusResponse.error.errorCode === 2023 || data[i].content.CumulusResponse.error.errorCode === 2024)) {
										isDuplicate = 'Incompatible Version';
									} else if (data[i].content && data[i].content.CumulusResponse && (data[i].content.CumulusResponse.error.errorCode === 2026)) {
										isDuplicate = data[i].content.CumulusResponse.error.errorMessage;
									} else if (data[i].content && data[i].content.CumulusResponse && (data[i].content.CumulusResponse.error.errorCode === -1)) {
										isDuplicate = 'Unreadable Case File';
									}
									return (
										<tr className="file-details-tr-background " {...rowProps}
											key={`${rowProps.key}-expanded-${i}`}>
											<td>{data[i].content && data[i].content.CaseID ? data[i].content.CaseID : 'N/A'}</td>
											<td>{data[i].uplodedDateTime ? convertUTCToLocalDateTime(data[i].uplodedDateTime) : 'N/A'}</td>
											<td>{data[i].content && data[i].content.LoadStatus ? data[i].content.LoadStatus : 'N/A'}</td>
											<td>{isDuplicate}</td>
											<td>{data[i].content && data[i].content.operativeProcedure ? (data[i].content.operativeProcedure.toString().toLowerCase() === operativeProcedureTypes.TKA.inLowerCase) ? operativeProcedureTypes.TKA.text : data[i].content.operativeProcedure : 'N/A'}</td>
											<td>{data[i].content && data[i].content.CartDetails && data[i].content.CartDetails.SoftwareDetails && data[i].content.CartDetails.SoftwareDetails.Version ? data[i].content.CartDetails.SoftwareDetails.Version : 'N/A'}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</td>
				</tr> :
				<tr ><td colSpan='5' className="file-details-nodata">No case data found</td></tr>
			}
		</>
	);
}

// This could be used to get the sub rows
/* istanbul ignore next  */
function SubRowAsync({ row, rowProps, visibleColumns }) {
	const [loading, setLoading] = React.useState(true);
	const [data, setData] = React.useState([]);
	const [subRowError, setSubRowError] = React.useState(null);
	React.useEffect(() => {
		const timer = setTimeout(() => {
			setLoading(true);
			getFileDetailsService(row.original.filename, (err, result) => {
				/* istanbul ignore next  */
				if (err) {
					setSubRowError(err);
					setLoading(false);
				} else {
					setSubRowError(null);
					setData(result.data);
					setLoading(false);
				}
			})
		}, 0);

		return () => {
			clearTimeout(timer);
		};
	}, [row.original.filename]);

	return (
		<>
			{subRowError ? <tr className="file-details-tr-text"><td colSpan="5"><ErrorMsgBlockComponent errorObject={subRowError} /></td></tr> :
				<SubRows
					row={row}
					rowProps={rowProps}
					visibleColumns={visibleColumns}
					data={data}
					subRowError={subRowError}
					loading={loading}
				/>}
		</>
	);
}
/** @description function to create UI for Data list
 *  @param  {}
 */
function Table({
	columns,
	data,
	fetchData,
	actionErrorObj,
	loading,
	downloading,
	downloadErrorMsg,
	downloadS3File,
	pageCount: controlledPageCount,
	defaultPageSize,
	totalCount,
	listType,
	showPagination,
	disableColmSort,
	editProms,
	enableEditProms,
	promsEditObj,
	refreshProms,
	showConfirmModal,
	showHideConfirmModal,
	deletePromScore,
	updateDeviceStatus,
	showPromsSuccessMsg,
	showDeviceListSuccessMsg,
	successMsg,
	getHiddenColumns,
	reviewCountMessage,
	showReviewCountMessage,
	toggleUserDetails,
	toggleDeviceList,
	selectedUser,
	viewUserDetails,
	deviceStatusObj,
	changeDeviceStatus,
	toggleResetPassword,
	resetUserPassword,
	renderRowSubComponent,
	userUpdated,
	searchString,
	errorObj,
	customerid,
	filterObj,
	reviewUpdated,
	closeSalesRepCustomerModal,
	confirmModal,
	deleteCustomerAssociation,
	params
}) {
	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[]
	);

	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		pageOptions,
		pageCount,
		page,
		state: { pageIndex, pageSize, sortBy },
		gotoPage,
		previousPage,
		nextPage,
		setPageSize,
		canPreviousPage,
		canNextPage,
		visibleColumns
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			initialState: { pageIndex: 0, pageSize: defaultPageSize, sortBy: [], hiddenColumns: getHiddenColumns ? getHiddenColumns : [] },
			manualPagination: true,
			autoResetPage: false,
			manualSortBy: true,
			disableFilters: false,
			disableSortBy: disableColmSort ? disableColmSort : false,
			pageCount: controlledPageCount,
			totalCount: 0,
		},
		useFilters,
		useSortBy,
		useExpanded,
		usePagination
	);

	const showElementsArray = listType === 'audit-logs' ? [50, 100] : [10, 20, 30, 40, 50];
	// Listen for changes in pagination and use the state to fetch our new data
	React.useEffect(() => {
		fetchData({ pageIndex, pageSize, sortBy });
	}, [fetchData, downloadS3File, pageIndex, pageSize, sortBy]);

	React.useMemo(() => {
		return gotoPage(0);
		// eslint-disable-next-line
	}, [searchString, customerid, filterObj]);

	// message to show after update in the review pre plan table in salesrep panel
	/* istanbul ignore next  */
	let reviewSuccessMessage = ''
	if( reviewUpdated?.type === 'hospitalId') {
		reviewSuccessMessage = salesRepMessages.hospitalUpdateMessage(reviewUpdated?.id, reviewUpdated?.selectedOptionText)
	} else if (reviewUpdated?.type === 'salesRep') {
		reviewSuccessMessage = salesRepMessages.salesRepUpdateMessage(reviewUpdated?.id, reviewUpdated?.selectedOptionText);
	} else {
		reviewSuccessMessage = salesRepMessages.statusUpdateMessage(reviewUpdated?.id, reviewUpdated?.selectedOptionText);
	}

   /* istanbul ignore next  */
	if (reviewUpdated?.selectedOptionText === 'Select Any Customer') {
		reviewSuccessMessage = salesRepMessages.unAssignMessage(reviewUpdated?.id)
	}

	// Render the UI for your table
	/* istanbul ignore next  */
	return (
		<>
			{errorObj ? <ErrorMsgBlockComponent errorObject={errorObj} /> :
				<>
					{successMsg === 'reviewsUpdate' ? (
						<div className='proms-success-msg'>
							<p>Pre-op Plan {reviewSuccessMessage}</p>
						</div>
					) : null}
					{showReviewCountMessage && reviewCountMessage ? (
						<div className="proms-success-msg">
							<p>{reviewCountMessage}</p>
						</div>
					) : null}
					{(data && data.length) || loading ?
						<>
							{downloadErrorMsg ? (
								<div className="file-list-error-msg">
									<span>{downloadErrorMsg}</span>
								</div>
							) : null}
							{showPromsSuccessMsg === 'promsUpdate' ? (
								<div className="proms-success-msg">
									<p>{promScoreMessages.updateData}</p>
								</div>
							) : null}
							{showPromsSuccessMsg === 'promsDelete' ? (
								<div className="proms-success-msg">
									<p>{promScoreMessages.deleteData}</p>
								</div>
							) : null}
								{showPromsSuccessMsg === 'customerDelete' ? (
								<div className="proms-success-msg mb-0">
									<p>{salesRepMapping.removeCustomer}</p>
								</div>
							) : null}
							{showDeviceListSuccessMsg === 'deviceStatusUpdate' ? (
								<div className="proms-success-msg mt-3">
									<p>{deviceMessages.statusChanged}</p>
								</div>
							) : null}
							{downloading ? (
								<div className="loading-overlay-upload download-overlay">
									<span>{actions.download}</span>
								</div>
							) : null}
							{successMsg === 'rarRequestApprove' ? (
								<div className="proms-success-msg">
									<p>{surgeonMapping.approveSuccess}</p>
								</div>
							) : null}
							{successMsg === 'rarRequestRevoke' ? (
								<div className="proms-success-msg">
									<p>{surgeonMapping.revokeSuccess}</p>
								</div>
							) : null}
							{successMsg === 'userStatusInactive' ? (
								<div className="proms-success-msg">
									<p>{userMessages.statusChanged()}</p>
								</div>
							) : null}
							{actionErrorObj ? <ErrorMsgBlockComponent errorObject={actionErrorObj} /> : null}
							<div className="table-contents">
								<table
									{...getTableProps()}
									id={listType}
									className={
										listType
											? listType + " table-bordered table-hover "
											: "table-bordered table-hover"
									}
								>
									<thead className="rt-thead">
										{headerGroups.map((headerGroup) => (
											<tr {...headerGroup.getHeaderGroupProps()}>
												{headerGroup.headers.map((column) => (
													// Add the sorting props to control sorting. For this example
													// we can add them into the header props
													<th
														{...column.getHeaderProps(column.getSortByToggleProps())}
														title=""
													>
														{column.render("Header")}
														{/* Add a sort direction indicator */}
														<span>
															{column.isSorted ? (
																column.isSortedDesc ? (
																	<svg
																		className="bi bi-chevron-down"
																		width="1.2em"
																		height="1.2em"
																		viewBox="0 0 16 16"
																		fill="currentColor"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			fillRule="evenodd"
																			d="M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z"
																			clipRule="evenodd"
																		/>
																	</svg>
																) : (
																	<svg
																		className="bi bi-chevron-up"
																		width="1.2em"
																		height="1.2em"
																		viewBox="0 0 16 16"
																		fill="currentColor"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			fillRule="evenodd"
																			d="M7.646 4.646a.5.5 0 01.708 0l6 6a.5.5 0 01-.708.708L8 5.707l-5.646 5.647a.5.5 0 01-.708-.708l6-6z"
																			clipRule="evenodd"
																		/>
																	</svg>
																)
															) : (
																""
															)}
														</span>
														<div>
															{column.canFilter ? column.render("Filter") : null}
														</div>
													</th>
												))}
											</tr>
										))}
									</thead>
									{loading ? (
										<tbody className="rt-tbody tr-refresh">
											{[...Array(page && page.length && page.length < pageSize ? page.length : 10)].map((e, i) => (
												<tr key={i}>
													{[...Array(headerGroups.length ? headerGroups[0].headers.length : 1)].map((f, j) => (
														<td className="loading-cell" key={j}>
															<span></span>
														</td>
													))}
												</tr>
											))}
											<tr>
												<td colSpan="10000">{actions.load}</td>
											</tr>
										</tbody>
									) : (
										<tbody {...getTableBodyProps()} className="rt-tbody">
											{page.map((row, i) => {
												prepareRow(row);
												const rowProps = row.getRowProps();
												const newRarRequest = row.original.requeststatus === "Requested" ? "rar-new-request" : '';
												const fileWarning = row.original.status === "Warning" ? "file-warning" : '';
												return (
													<React.Fragment key={i}>
														<tr {...row.getRowProps()} className={newRarRequest + " " + fileWarning}>
															{row.cells.map((cell) => {
																return (
																	<td {...cell.getCellProps()}>{cell.render("Cell")}</td>
																);
															})}
														</tr>
														{row.isExpanded &&
															renderRowSubComponent({ row, rowProps, visibleColumns })}
													</React.Fragment>
												);
											})}
											<tr className="result-count">
												<td colSpan="10000">Showing {page.length} of {totalCount} results</td>
											</tr>
										</tbody>
									)}
								</table>

								{totalCount > 0 && showPagination === true ? (
									<div className="pagination">
										<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
											{"<<"}
										</button>{" "}
										<button onClick={() => previousPage()} disabled={!canPreviousPage}>
											{"<"}
										</button>{" "}
										<button onClick={() => nextPage()} disabled={!canNextPage}>
											{">"}
										</button>{" "}
										<button
											onClick={() => gotoPage(pageCount - 1)}
											disabled={!canNextPage}
										>
											{">>"}
										</button>{" "}
										&nbsp;
										<span className="page-display">
											Page{" "}
											<strong>
												{pageIndex >= pageOptions.length ? pageOptions.length : pageIndex + 1} of {pageOptions.length}
											</strong>{" "}
											&nbsp;
										</span>
										<select
											value={pageSize}
											onChange={(e) => {
												setPageSize(Number(e.target.value));
											}}
											className="form-select res-dropdown"
										>
											{showElementsArray.map((rowsPerPage) => (
												<option key={rowsPerPage} value={rowsPerPage}>
													Show {rowsPerPage}
												</option>
											))}
										</select>
									</div>
								) : null}
							</div>
						</>
						: <ErrorMsgBlockComponent noData={true} />}

					{/* Modal to edit PROM score */}
					<CustomModal
						id="edit-prom-modal"
						show={editProms}
						isComponent={true}
						title="Edit PROM Score Entry"
						closeAction={() => enableEditProms(editProms)}>
						<EditPromsContainer promsEditObj={promsEditObj} refreshProms={refreshProms} cancelProms={() => enableEditProms(editProms)} />
					</CustomModal>

					{/* Modal to delete PROM score */}
					<CustomModal
						id="delete-prom-modal"
						show={showConfirmModal}
						isConfirm={true}
						title="Alert"
						body={promScoreMessages.deleteAlert}
						closeAction={() => showHideConfirmModal(editProms)}
						rejectAction={() => showHideConfirmModal(editProms)}
						confirmAction={() => deletePromScore()}
					/>

					{/* Modal to delete customer association for sales rep */}
					<CustomModal 
					   id="delete-customer-modal"
					   show={confirmModal}
					   isConfirm={true}
					   title="Alert"
					   body={salesRepMapping.removeAlert}
					   closeAction={() => closeSalesRepCustomerModal(confirmModal)}
					   rejectAction={() => closeSalesRepCustomerModal(confirmModal)}
					   confirmAction={() => deleteCustomerAssociation(params)}
					/>

					{/* Modal to view user details */}
					<CustomModal
						id="user-details-modal"
						show={toggleUserDetails}
						title="User Details"
						isComponent={true}
						dialogClassName="user-details-dialog"
						closeAction={() => viewUserDetails()}>
						<UserDetailsContainer
							userName={selectedUser && selectedUser.userName ? selectedUser.userName : ''}
							userUpdated={status => userUpdated(status, { pageIndex, pageSize, sortBy })}
							handleCancel={() => viewUserDetails()}
						/>
					</CustomModal>

					{/* Modal to reset user password */}
					<CustomModal
						id="reset-password-modal"
						show={toggleResetPassword}
						title="Reset Password"
						isComponent={true}
						hideClose={selectedUser && selectedUser.isStatusChange}
						dialogClassName="reset-password-dialog"
						closeAction={() => resetUserPassword(selectedUser)}>
						<AdminPasswordResetContainer user={selectedUser || ''} handleCancel={() => resetUserPassword()} />
					</CustomModal>

					{/* Modal to change device status */}
					<CustomModal
						id="device-status-modal"
						show={toggleDeviceList}
						title={deviceStatusObj && deviceStatusObj.serialno ? `Device Status: ${deviceStatusObj.serialno}` : 'Device Status'}
						isConfirm={true}
						body={parseHTML(deviceMessages.statusConfirmation(deviceStatusObj ? deviceStatusObj.status : {}))}
						dialogClassName="device-status-dialog"
						closeAction={() => changeDeviceStatus()}
						rejectAction={() => changeDeviceStatus()}
						confirmAction={() => updateDeviceStatus({ pageIndex, pageSize, sortBy })}
					/>

				</>}
		</>
	);
}

/**
 * @description Function to download file from S3 bucket
 * @param  {}
 */
/* istanbul ignore next  */
const downloadFile = (filepath) => {
	downloadObject(filepath, function (err, res) {
		if (err) {
			if (err.message && err.message === "Missing credentials in config") {
				logoutService((error, result) => { });
			}
		} else {
			let element = document.createElement("iframe");
			element.src = res;
			document.body.appendChild(element);
			element.click();
			element.style.display = "none";
		}
	});
};
/**
 * @description Function to verify file from S3 bucket
 * @param  {}
 */
/* istanbul ignore next  */
const verifyFileNameInS3 = async (filepath, callback) => {
	let bucketName = environment.s3BucketName;
	let s3 = await newS3(bucketName);
	let mediaKey = filepath;
	let param = {
		Key: mediaKey,
	};
	await s3.headObject(param, (err, res) => {
		if (err) {
			callback(err, null);
		} else {
			callback(null, res);
		}
	});
};

function DataTable(props) {
	let getUniqueId = props.getId ? props.getId : "";
	let getSurgeonUserName = props.surgeonUserName ? props.surgeonUserName : "";
	let getColumnsFromProps = props.columns ? props.columns : ""; // get columns and datalist type as props
	// eslint-disable-next-line
	let columnsList = [];
	// add the button based on the columns input
	getColumnsFromProps &&
		getColumnsFromProps.forEach((element) => {
			/* istanbul ignore next  */

			if (element.download) {
				// add download button
				let download = {
					Header: element.Header,
					Cell: ({ row }) => (
						<button
							className="btn btn-primary"
							id="download"
							onClick={() => downloadS3File(row.original)}
						>
							<svg
								className="bi bi-cloud-download"
								width="1.3em"
								height="1.3em"
								viewBox="0 0 16 16"
								fill="currentColor"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M4.887 5.2l-.964-.165A2.5 2.5 0 103.5 10H6v1H3.5a3.5 3.5 0 11.59-6.95 5.002 5.002 0 119.804 1.98A2.501 2.501 0 0113.5 11H10v-1h3.5a1.5 1.5 0 00.237-2.981L12.7 6.854l.216-1.028a4 4 0 10-7.843-1.587l-.185.96z" />
								<path
									fillRule="evenodd"
									d="M5 12.5a.5.5 0 01.707 0L8 14.793l2.293-2.293a.5.5 0 11.707.707l-2.646 2.646a.5.5 0 01-.708 0L5 13.207a.5.5 0 010-.707z"
									clipRule="evenodd"
								/>
								<path
									fillRule="evenodd"
									d="M8 6a.5.5 0 01.5.5v8a.5.5 0 01-1 0v-8A.5.5 0 018 6z"
									clipRule="evenodd"
								/>
							</svg>
						</button>
					),
				};
				columnsList.push(download);
			} else if (element.CaseAction) {
				let caseActions = {
					Header: element.Header,
					Cell: ({ row }) => (
						<div className="case-actions">
							<Link
								className="btn btn-primary case-action-btn"
								id="view-case"
								to={{
									pathname: `/casedetails/${row.original.caseDetails.caseID}`,
									state: {
										id: row.original.caseDetails.caseID,
										surgeonName: row.original.caseDetails.surgeonUserName,
										activeTab: 'caseList'
									}
								}}>
								{" "}
								View
							</Link>
						</div>
					),
				};
				columnsList.push(caseActions);
			} else if (element.CustomerAction) {
				let customerAction = {
					Header: element.Header,
					Cell: ({ row }) => (
						<div>
							<Link
								className="btn btn-primary proms-btn-color case-action-btn"
								id="view-customer"
								to={{ pathname: '/customer/' + row.original.customerid }}>
								Details
							</Link>
						</div>
					),
				};
				columnsList.push(customerAction);
			} else if (element.ExpirationAction) {
				let expirationAction = {
					Header: element.Header,
					Cell: ({ row }) => (
						<div>
							<Link
								id="view-expiration"
								to={{ pathname: '/customer/' + row.original.customerId }}>
								<svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-eye cursor-pointer" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z" />
									<path fillRule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
								</svg>
							</Link>
						</div>
					),
				};
				columnsList.push(expirationAction);
			} else if (element.surgeonAction) {
				let surgeonAction = {
					Header: element.Header,
					Cell: ({ row }) => (
						<div>
							<Link
								className="btn btn-primary proms-btn-color case-action-btn"
								id="view-surgeon"
								to={{ pathname: '/surgeondetails/' + row.original.userName }}>
								Details
							</Link>
						</div>
					),
				};
				columnsList.push(surgeonAction);
			} else if (element.PromsAction) {
				let promsAction = {
					Header: element.Header,
					Cell: ({ row }) => (
						<div >
							<button className="btn btn-primary proms-btn-color case-action-btn" disabled={getUserRoleType()?.includes('rar') || row.original.source === 'Aria'} onClick={() => enableEditProms(row.original)} >Edit</button>
							<button className="btn btn-danger case-action-btn" disabled={getUserRoleType()?.includes('rar')} onClick={() => showHideConfirmModal(row.original)}>Delete</button>
						</div>
					),
				};
				columnsList.push(promsAction);
			} else if (element.switchToggle) {
				let rarAssociationAction = {
					Header: element.Header,
					Cell: ({ row }) => (
						<div>
							<label className="switch">
								<input type="checkbox" id={row.original.id} onChange={(e) => rarAssociationReqChange(e, row.original)} defaultChecked={row.original.requeststatus === 'Approved'} />
								<div className="slider round">
									<span className="on">ON</span>
									<span className="off">OFF</span>
								</div>
							</label>
						</div>
					),
				};
				columnsList.push(rarAssociationAction);
			} else if (element.ReviewCaseAction) {
				let reviewCaseActions = {
					Header: element.Header,
					Cell: ({ row }) => (
						<div className="review-case-actions">
							<Link
								className="btn btn-primary case-action-btn"
								id={`view-case-${row.original.caseID}`}
								to={{ pathname: `/reviewcasedetails/${row.original.caseID}` }}>
								{" "}
								Review
							</Link>
						</div>
					),
				};
				columnsList.push(reviewCaseActions);
			} else if (element.fileDetails) {
				let fileDetailsAction = {
					Header: element.Header,
					Cell: ({ row }) => (
						<div className="files-action">
							<button type="button" className="btn btn-primary case-action-btn">Details</button>
						</div>
					),
				};
				columnsList.push(fileDetailsAction);
			} else if (element.Filter) {
				// add column filter
				let filter = {
					Header: element.Header,
					accessor: element.accessor,
					Filter: DefaultColumnFilter,
				};
				columnsList.push(filter);
			} else if (element.deviceAction) {
				let deviceAction = {
					Header: element.Header,
					Cell: ({ row }) => (
						<div className="device-status-toggle">
							<label className="switch">
								<input onClick={() => changeDeviceStatus(row.original)} type="checkbox" id="tfa-setting" defaultChecked={row.original.status && row.original.status.toLowerCase() === 'active'} />
								<div className="slider round">
									<span className="on">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16">
											<path d="M7.5 1v7h1V1h-1z" />
											<path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
										</svg>
									</span>
									<span className="off">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ccc" className="bi bi-power" viewBox="0 0 16 16">
											<path d="M7.5 1v7h1V1h-1z" />
											<path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
										</svg>
									</span>
								</div>
							</label>
						</div>
					),
				};
				columnsList.push(deviceAction);

			} else if (element.userStatus) {
				let userStatus = {
					Header: element.Header,
					Cell: ({ row }) => (
						<UserStatusContainer user={row.original} toggleLoader={props.toggleLoader} updateError={userStatusUpdateFailed} userStatusUpdated={userStatusUpdated} />
					),
				};
				columnsList.push(userStatus);
			} else if (element.userAction) {
				let userAction = {
					Header: element.Header,
					Cell: ({ row }) => (
						<div >
							<svg onClick={() => viewUserDetails(row.original)} width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-eye cursor-pointer" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z" />
								<path fillRule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
							</svg>
							<svg onClick={() => resetUserPassword(row.original)} width="1.5em" height="1.5em" className="bi bi-unlock delete-icon cursor-pointer" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
								<path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z" />
							</svg>
						</div>
					),
				};
				columnsList.push(userAction);
			} else if (element.deleteAction) {
                let deleteAction = {
					Header : element.Header,
					Cell: ({row}) => (
					<div>
						<svg onClick={() => enableSalesRepCustomerModal(row.original)} id="deleteIcon" width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-trash delete-icon cursor-pointer" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
							<path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
						</svg>
					</div>
					)
				}
				columnsList.push(deleteAction)
			} else if (element.commentHistoryAction) {
				let commentHistoryAction = {
					Header: element.Header,
					Cell: ({ row }) => (
						<div >
							<svg onClick={() => props.toggleCommentDetails(true, row.original)} width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-eye cursor-pointer" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z" />
								<path fillRule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
							</svg>
						</div>
					),
				};
				columnsList.push(commentHistoryAction);
			} else if (element.changeAssociationDropDown) {
                let hospitalAssociation = {
					Header: element.Header,
					Cell: ({row}) => (
						<UpdateCSContainer salesRepList={props.salesRepUsersList} type={element.accessor} row={row.original} updateLoader={setLoading} updateError={reviewUpdateFailed} reviewDataUpdated={reviewDataUpdated} isUpdated={props.isUpdated}/>
					)
				}
				columnsList.push(hospitalAssociation)
			} else if (element.reviewDropDown) {
				let userStatus = {
					Header: <div className={`${element.accessor === 'procedureStatus' && 'hide-column-width'} `}>{element.Header}</div>,
					id: Date.now().toString() + Math.random().toString(),
					Cell: ({ row }) => (
						<div className={`${element.accessor === 'procedureStatus' && 'hide-column-width'} `}><UpdateReviewContainer type={element.accessor} row={row.original} updateLoader={setLoading} updateError={reviewUpdateFailed} reviewDataUpdated={reviewDataUpdated} isUpdated={props.isUpdated} /></div>
					),
				};
				columnsList.push(userStatus);
			    // reviewDropDown are for the table of salesrep panel's review table
			}
			 else {
				columnsList.push(element);
			}
		});

	const columns = React.useMemo(() => columnsList, [columnsList]);
	// We'll start our table without any data
	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [promsCaseId, setPromsCaseId] = React.useState('');
	const [editProms, setEditProms] = React.useState(false);
	const [confirmModal, setConfirmModal] = React.useState(false);
	const [params, setParams] = React.useState(null);
 	const [promsEditObj, setPromsEditObj] = React.useState([]);
	const [deviceStatusObj, setDeviceStatusChangeObj] = React.useState([]);

	const [showPromsSuccessMsg, setPromsSuccessMsg] = React.useState('');
	const [showDeviceListSuccessMsg, setDeviceListSuccessMsg] = React.useState('');

	const [successMsg, setSuccessMsg] = React.useState('');
	const [showConfirmModal, setShowConfirmModal] = React.useState(false);
	const [downloading, setDownloading] = React.useState(false);
	const [downloadErrorMsg, setDownloadErrorMsg] = React.useState("");
	const [pageCount, setPageCount] = React.useState(0);
	const [totalCount, setTotalCount] = React.useState(0);
	const [toggleUserDetails, setToggleUserDetails] = React.useState(false);

	const [toggleDeviceList, setToggleDeviceList] = React.useState(false);

	const [toggleResetPassword, setToggleResetPassword] = React.useState(false);
	const [selectedUser, setSelectedUser] = React.useState([]);
	const fetchIdRef = React.useRef(0);
	const [showReviewCountMessage, setShowReviewCountMessage] = React.useState(false);
	const [reviewCountMessage, setReviewCountMessage] = React.useState('');
	const [errorObj, setErrorObj] = React.useState(null);
	const [actionErrorObj, setActionErrorObj] = React.useState(null);
	const [reviewUpdated, setReviewUpdated] = React.useState(null);
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const downloadS3File = React.useCallback(({ filepath, filename }) => {
		setDownloading(true);
		setDownloadErrorMsg("");
		verifyFileNameInS3(filepath, function (err, res) {
			if (err && err.code === "NotFound") {
				setDownloading(false);
				setDownloadErrorMsg(
					'Requested file "' +
					filename +
					'" is not found, please contact administrator.'
				);
			} else if (err && err.code === "Forbidden") {
				setDownloading(false);
				setDownloadErrorMsg(
					'User do not have download permission to requested file "' +
					filename +
					'", please contact administrator.'
				);
			} else {
				setDownloading(false);
				setDownloadErrorMsg(false);
				downloadFile(filepath);
			}
		});
	});

	/**
	 * @description Function to get proms score from API
	 * @param  {}
	 */
	/* istanbul ignore next  */
	const getPromsScoreList = () => {
		setLoading(true);
		const params = {
			'surgeonname': getSurgeonUserName
		}
		getPromScoreHistoryService(
			getUniqueId, params,
			(err, result) => {
				/* istanbul ignore next  */
				if (err) {
					setErrorObj(err);
					setLoading(false);
				} else {
					setErrorObj(null);
					let getPromsdata = result.data && result.data.case.length > 0 && result.data.case[0].scoreDetails ? result.data.case[0].scoreDetails : [];
					let getPromsCaseId = result.data && result.data.case.length > 0 && result.data.case[0].scoreDetails ? result.data.case[0].caseId : '';
					let getTotalCount = result.data && result.data.case.length > 0 && result.data.case[0].scoreDetails ? result.data.response_metadata.total : 0;
					setData(getPromsdata);
					setPromsCaseId(getPromsCaseId);
					setPageCount(Math.ceil(getTotalCount / 10));
					setTotalCount(getTotalCount);
					setLoading(false);
				}
			}
		);
	}

	/**
	 * @description Function to delete proms score from DB
	 * @param  {}
	 */
	/* istanbul ignore next  */
	const deleteProms = (caseId, promsDetails) => {
		setLoading(true);
		deletePromScoreService(caseId, promsDetails, (err, result) => {
			/* istanbul ignore next  */
			if (err) {
				setActionErrorObj(err);
				setLoading(false);
			} else {
				setActionErrorObj(null);
				setPromsSuccessMsg('promsDelete');
				getPromsScoreList();
				props.refreshOutcomeScore();
			}
		})
		setTimeout(() => {
			setPromsSuccessMsg('');
		}, 10000);
	}
	
	/**
	 * @description Function to delete sales rep customer association from DB
	 * @param  {}
	 */
	/* istanbul ignore next  */
	const deleteSalesRepCustomer = (delData) => {
		setLoading(true);
		deleteSalesRepCustomerService(delData, (err, result) => {
			/* istanbul ignore next  */
			if (err) {
				setActionErrorObj(err);
				setLoading(false);
			} else {
				setActionErrorObj(null);
				setPromsSuccessMsg('customerDelete');
				setConfirmModal(false)
				setLoading(false)
				getPreopCustomerList()
			}
		})
		setTimeout(() => {
			setPromsSuccessMsg('');
		}, 10000);
	}

	/**
	 * @description Function to delete sales rep customer 
	 * @param  {}
	 */
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const deleteCustomerAssociation = React.useCallback((param) => {
		setShowConfirmModal(false);
		setLoading(true);
		deleteSalesRepCustomer(param);
	});

	/**
	 * @description Function to refresh proms data table after update
	 * @param  {}
	 */
	/* istanbul ignore next  */
	const refreshProms = (refreshStatus) => {
		if (refreshStatus === false || refreshStatus === '') {
			return true;
		}
		setLoading(true);
		setPromsSuccessMsg('promsUpdate');
		setEditProms(false);
		getPromsScoreList();
		props.refreshOutcomeScore();
		setTimeout(() => {
			setPromsSuccessMsg('');
		}, 10000);
	}
	
	/**
	 * @description Function to show proms in modal popup
	 * @param  {}
	 */
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const enableEditProms = React.useCallback(({ scoreId, scoreType, timeIntervals, score, subScoreType }) => {
		setPromsSuccessMsg('');
		let getPromsEditObj = {
			operativeProcedure: props.operativeProcedure,
			caseId: promsCaseId,
			score: score,
			scoreType: scoreType,
			timeInterval: timeIntervals,
			subScoreType: subScoreType ? subScoreType : '',
			scoreId: scoreId
		}
		setPromsEditObj(getPromsEditObj)
		editProms ? setEditProms(false) : setEditProms(true);
	});

	/**
	 * @description Function to change modal state
	 * @param  {}
	 */
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const closeSalesRepCustomerModal = () => {
		confirmModal ? setConfirmModal(false) : setConfirmModal(true)
	}
	
	/**
	 * @description Function to show proms in modal popup
	 * @param  {}
	 */
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const enableSalesRepCustomerModal = React.useCallback ((rowData) => {
		setPromsSuccessMsg('');
		let getParams = {
			customerId : rowData.customerId,
			salesRepName: getUserName()
		}
		setParams(getParams)
		closeSalesRepCustomerModal();
	});
	/**
	 * @description Function to confirm delete proms
	 * @param  {}
	 */
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const showHideConfirmModal = React.useCallback(({ scoreId, scoreType, timeIntervals, score }) => {
		setPromsSuccessMsg('');
		let getPromsEditObj = {
			caseId: promsCaseId,
			score: score,
			scoreType: scoreType,
			timeInterval: timeIntervals,
			scoreId: scoreId,
		}
		setPromsEditObj(getPromsEditObj)
		showConfirmModal ? setShowConfirmModal(false) : setShowConfirmModal(true);
	});
	/**
	 * @description Function to delete proms score
	 * @param  {}
	 */
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const deletePromScore = React.useCallback(() => {
		setShowConfirmModal(false);
		setLoading(true);
		deleteProms(promsCaseId, promsEditObj);
	});

	/**
	 * @description Function to toggle confirmation modal fdor device update
	 * @param  {}
	 */
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const changeDeviceStatus = React.useCallback((device = null) => {
		if (device) {
			let deviceDetails = {
				serialno: device.serialno ? device.serialno : '',
				status: device.status ? device.status : '',
				customerid: device.customerid ? device.customerid : '',

			}
			setDeviceStatusChangeObj(deviceDetails);
		}

		toggleDeviceList ? setToggleDeviceList(false) : setToggleDeviceList(true);
	});

	/**
	 * @description Function to update decice status
	 * @param  {}
	 */
	/* istanbul ignore next  */
	const updateDeviceStatus = (fetchParams) => {
		setLoading(true);
		var params = {
			serialno: deviceStatusObj.serialno,
			status: deviceStatusObj.status.toString().toLowerCase() === 'active' ? 'INACTIVE' : 'ACTIVE',
			customerid: deviceStatusObj.customerid
		}

		setToggleDeviceList(false);
		saveDeviceInfo(params, (err, result) => {
			/* istanbul ignore next  */
			setLoading(false);
			if (err) {
				setActionErrorObj(err);
			} else {
				setActionErrorObj(null);
				setDeviceListSuccessMsg('deviceStatusUpdate');
				getAllDevices(fetchParams);
			}
		})
		setTimeout(() => {
			setDeviceListSuccessMsg('');
		}, 10000);
	}

	/**
	 * @description Function to get RAR association requests from API
	 * @param  {}
	 */
	/* istanbul ignore next  */
	const rarAssociationReqList = () => {
		setLoading(true);
		dataAssociationReqListService((err, result) => {
			/* istanbul ignore next  */
			if (err) {
				setErrorObj(err);
				setLoading(false);
			} else {
				let getRequestdata = result.data && result.data.user.length > 0 && result.data.user[0].requestList ? result.data.user[0].requestList : [];
				let getTotalCount = result.data && result.data.user.length > 0 && result.data.user[0].requestList ? result.data.response_metadata.total : [];
				setData(getRequestdata);
				setPageCount(Math.ceil(getTotalCount / 10));
				setTotalCount(getTotalCount);
				setErrorObj(null);
				setLoading(false);
			}
		}
		);
	}

	/**
	 * @description Function to approve or revoke RAR association request
	 * @param  {}
	 */
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const rarAssociationReqChange = React.useCallback((e, userDetails) => {
		setLoading(true);
		const getRequestStatus = e.target.checked ? "Approved" : "Revoked";
		const requestObj = {
			"id": userDetails.id,
			"requester": userDetails.username,
			"requeststatus": getRequestStatus
		}
		dataAssociationReqService(requestObj, (err, result) => {
			/* istanbul ignore next  */
			if (err) {
				setActionErrorObj(err);
				setLoading(false);
			} else {
				setActionErrorObj(null);
				rarAssociationReqList();
				setSuccessMsg(getRequestStatus === "Approved" ? "rarRequestApprove" : "rarRequestRevoke");
				setLoading(false);
			}
		})
		setTimeout(() => {
			setSuccessMsg('');
		}, 10000);
	});

	/**
	 * @description Function to get case list from API
	 * @param params Object with page number, sort order, sort by column and page size
	*/
	/* istanbul ignore next  */
	const getCaseList = (caseParams) => {
		var urlParams = {
			pageno: caseParams.pageIndex + 1,
			sortorder: caseParams.sortOrder,
			column: caseParams.columnSortBy,
			limit: caseParams.pageSize
		}

		for (const key in props.filterObj) {
			if (props.filterObj.hasOwnProperty(key) && (props.filterObj[key] || props.filterObj[key] === 0)) {
				if (!(key === 'proceduretype' && props.filterObj[key] === 'all')) {
					urlParams[key] = props.filterObj[key];
				}
			}
		}

		setLoading(true);
		caseListService(urlParams,
			(err, result) => {
				/* istanbul ignore next  */
				if (err) {
					setErrorObj(err);
					setLoading(false);
				} else {
					let getMetadata = result.data.case;
					let getTotalCount = result.data.response_metadata.total;
					setData(getMetadata);
					setPageCount(Math.ceil(getTotalCount / caseParams.pageSize));
					setTotalCount(getTotalCount);
					setErrorObj(null);
					setLoading(false);
				}
			}
		);
	}

	/**
	 * @description Function to get customer list from API
	 * @param params Object with page number, sort order, sort by column and page size
	*/
	/* istanbul ignore next  */
	const getCustomerList = (custParams) => {
		var urlParams = {
			pageno: custParams.pageIndex + 1,
			column: custParams.columnSortBy || 'customername',
			sortorder: custParams.sortOrder || 'asc',
			limit: custParams.pageSize || 10
		}
		if (props.searchString) {
			urlParams['searchString'] = props.searchString;
		}
		setLoading(true);
		customerListService(urlParams,
			(err, result) => {
				/* istanbul ignore next  */
				if (err) {
					setErrorObj(err);
					setLoading(false);
				} else {
					let getMetadata = result.data.user; // result.data.customerList
					let getTotalCount = result.data.response_metadata.total;
					setData(getMetadata);
					setPageCount(Math.ceil(getTotalCount / custParams.pageSize));
					setTotalCount(getTotalCount);
					setErrorObj(null);
					setLoading(false);
				}
			}
		);
	}

	/**
	 * @description Function to get surgeon list from API for admin pages
	 * @param params Object with page number, sort order, sort by column and page size
	*/
	/* istanbul ignore next  */
	const getSurgeonList = (params) => {
		var urlParams = {
			pageno: params.pageIndex + 1,
			column: params.columnSortBy || 'name',
			sortorder: params.sortOrder || 'asc',
			limit: params.pageSize || 10
		}
		if (props.searchString) {
			urlParams['searchString'] = props.searchString;
		}

		setLoading(true);
		surgeonListService(urlParams,
			(err, result) => {
				/* istanbul ignore next  */
				if (err) {
					setErrorObj(err);
					setLoading(false);
				} else {
					let getMetadata = result.data.user ? result.data.user[0].surgeonList : [];
					let getTotalCount = result.data.response_metadata.total;
					setData(getMetadata);
					setPageCount(Math.ceil(getTotalCount / params.pageSize));
					setTotalCount(getTotalCount);
					setErrorObj(null);
					setLoading(false);
				}
			}
		);
	}
	/**
 * @description Function to get audit logs from API
 * @param params Object with page number, sort order, sort by column and page size
*/
	/* istanbul ignore next  */
	const getAuditLogs = (audiParams) => {
		var urlParams = {
			pageno: audiParams.pageIndex + 1,
			sortorder: audiParams.sortOrder || 'desc',
			column: audiParams.columnSortBy || 'EventDate',
			limit: audiParams.pageSize || 100,
			eventSource: audiParams.eventSource || eventSource.riInsights.value
		}

		for (const key in props.filterObj) {
			if (props.filterObj.hasOwnProperty(key) && (props.filterObj[key] || props.filterObj[key] === 0)) {
				urlParams[key] = props.filterObj[key];
			}
		}

		setLoading(true);
		loggerGetAPI(urlParams,
			(err, result) => {
				/* istanbul ignore next  */
				if (err) {
					setErrorObj(err);
					setLoading(false);
				} else {
					let getMetadata = result.data.logger;
					let getTotalCount = result.data.response_metadata.total;
					setData(getMetadata);
					setPageCount(Math.ceil(getTotalCount / audiParams.pageSize));
					setTotalCount(getTotalCount);
					setErrorObj(null);
					setLoading(false);
					props.totalCount(getTotalCount);
				}
			}
		);
	}

	/**
	 * @description Function to get review case list from API
	 * @param params Object with page number, sort order, sort by column and page size
	*/
	/* istanbul ignore next  */
	const getReviewCaseList = (caseParams) => {
		var urlParams = {
			pageno: caseParams.pageIndex + 1,
			sortorder: caseParams.sortOrder || 'desc',
			column: caseParams.columnSortBy || 'dateOfSurgery',
			limit: caseParams.pageSize || 10
		}

		for (const key in props.filterObj) {
			if (props.filterObj.hasOwnProperty(key) && (props.filterObj[key] || props.filterObj[key] === 0)) {
				if (!(key === 'proceduretype' && props.filterObj[key] === 'all')) {
					urlParams[key] = props.filterObj[key];
				}
			}
		}

		setLoading(true);
		getReviewCases(urlParams,
			(err, result) => {
				/* istanbul ignore next  */
				if (err) {
					setErrorObj(err);
					setLoading(false);
				} else {
					let getMetadata = result.data.case;
					let getTotalCount = result.data.response_metadata.total;
					setData(getMetadata);
					setPageCount(Math.ceil(getTotalCount / caseParams.pageSize));
					setTotalCount(getTotalCount);
					setErrorObj(null);
					if (checkLocalStorage('CaseCount') && parseInt(getLocalStorage('CaseCount')) > getTotalCount && (checkLocalStorage('ReviewCustomer') || checkLocalStorage('ReviewSurgeon'))) {
						const caseDiff = parseInt(getLocalStorage('CaseCount')) - parseInt(getTotalCount);
						var namesList = [];
						if (checkLocalStorage('ReviewCustomer')) {
							const customer = JSON.parse(getLocalStorage('ReviewCustomer'));
							namesList.push(customer.customername);
						}
						if (checkLocalStorage('ReviewSurgeon')) {
							const surgeon = JSON.parse(getLocalStorage('ReviewSurgeon'));
							namesList.push(surgeon.surgeonname);
						}
						setShowReviewCountMessage(true);
						setReviewCountMessage(reviewCaseDetailsMessages.caseDiff(caseDiff, namesList));
						setTimeout(() => {
							setShowReviewCountMessage(false);
							setReviewCountMessage('');
						}, 10000);
					} else {
						setShowReviewCountMessage(false);
						setReviewCountMessage('');
					}

					setLocalStorage('CaseCount', getTotalCount);
					setLoading(false);
				}
			}
		);
	}

	/**
	 * @description Function to get customers associated with a surgeon
	 * @param params Object with page number, sort order, sort by column and page size
	*/
	/* istanbul ignore next  */
	const getSurgeonCustomers = (params) => {
		var urlParams = {
			sortorder: params.sortOrder || 'desc',
			column: params.columnSortBy || 'customername'
		}

		setLoading(true);
		getSurgeonCustomersService(props.surgeonUsername, urlParams,
			(err, result) => {
				/* istanbul ignore next  */
				if (err) {
					setErrorObj(err);
					setLoading(false);
				} else {
					let getMetadata = result.data.customerList;
					let getTotalCount = result.data.response_metadata.total;
					setData(getMetadata);
					setPageCount(Math.ceil(getTotalCount / params.pageSize));
					setTotalCount(getTotalCount);
					setErrorObj(null);
					setLoading(false);
				}
			}
		);
	}
	

  

	/**
	 * @description Handle the success response by populating data and related states
	 * @param data Array of data
	 * @param totalCount Total count of items
	 * @param pageSize Page size
	 */
	/* istanbul ignore next */
	const handleSuccess = (resData, totalResCount, pageSize) => {
		const getMetadata = resData ? resData : [];
		const pageResCount = Math.ceil(totalResCount / pageSize);
		populateData(getMetadata, pageResCount, totalResCount, null);
	};

	/**
	 * @description Handle the error response by setting the error state and loading state
	 * @param error Error object
	 */
	/* istanbul ignore next */
	const handleError = (error) => {
		setErrorObj(error);
		setLoading(false);
	};

	/**
	 * @description Populate the data and related states
	 * @param newData Array of data
	 * @param newPageCount Total number of pages
	 * @param newTotalCount Total count of items
	 * @param newError Error object
	 */
	/* istanbul ignore next */
	const populateData = (newData, newPageCount, newTotalCount, newError) => {
		setData(newData);
		setPageCount(newPageCount);
		setTotalCount(newTotalCount);
		setErrorObj(newError);
		setLoading(false);
	};

	/**
	 * @description Function to get ReviewPrePlan list from API for Review pre-op plans page in salesrep panel
	 * @param params Object with page number and page size
	 */
	/* istanbul ignore next */

	const getReviewPrePlanList = (salesrepParams) => {
		let urlParams = {
			page: salesrepParams.pageIndex + 1,
			limit: salesrepParams.pageSize || 10,
			sortOrder: salesrepParams.sortOrder || 'desc',
			columnName: salesrepParams.columnSortBy || 'plan_creation_date,surgery_date',
			showallstatus: salesrepParams?.props?.status || false,
		};

		// Only for testing the table
		if (salesrepParams?.props?.data) {
			setData(salesrepParams.props.data);
			setLoading(false);
			setPageCount(salesrepParams.props.pageCount);
			setTotalCount(salesrepParams.props.totalCount);
			setErrorObj(null);
			setLoading(false);
		} else if (salesrepParams.props.isPending) {
			// Only for Pending customer association
			setLoading(true);
			isAuthenticated() && getPendingCustomerAssociationService(urlParams, (err, result) => {
				handleApiResponse(err, result, salesrepParams);
			});
		} else {
		    if (salesrepParams.props.showFilterBlock) {
				urlParams['procedureName'] = props.filterObj.procedureType;
				urlParams['customerid'] = props.filterObj.customer;
				urlParams['procedureStatus'] = props.filterObj.status;
				urlParams['searchString'] = props.filterObj.searchString;

				if (urlParams['procedureStatus'] === 'all') {
					urlParams['showallstatus'] = true
				}

				for (let key in urlParams) {
					if (urlParams[key] === 'all' || urlParams[key] === '') {
						delete urlParams[key];
					}
				}

			}
			setLoading(true);
			isAuthenticated() && getFilteredPreOpPlansService(urlParams, (err, result) => {
				handleApiResponse(err, result, salesrepParams);
			});
		}
	}



	/**
	 * @description Function to get ReviewPrePlan list from API for Review pre-op plans page in admin panel
	 * @param params Object with page number and page size
	 */
	/* istanbul ignore next */
	const getReviewAllPlanList = (planParams) => {
		let urlParams = {
			page: planParams.pageIndex + 1,
			limit: planParams.pageSize || 10,
			sortOrder: planParams.sortOrder || 'desc',
			columnName: planParams.columnSortBy || 'plan_creation_date,surgery_date',
			showallstatus: planParams?.props?.status || false,
		};

		if (planParams?.props?.searchString) {
			urlParams['searchString'] = props.searchString;
		}
		if (planParams.props.showFilterBlock) {
			urlParams['procedureName'] = props.filterObj.procedureType;
			urlParams['customerid'] = props.filterObj.customer;
			urlParams['procedureStatus'] = props.filterObj.status;
			urlParams['salesrepName'] = props.filterObj.salesRep;

			if (urlParams['procedureStatus'] === 'all') {
				urlParams['showallstatus'] = true
			}

			for (let key in urlParams) {
				if (urlParams[key] === 'all') {
					delete urlParams[key];
				}
			}

		}
		setLoading(true);
		getAllPreOpListService(urlParams, (err, result) => {
			handleApiResponse(err, result, planParams);
		});
	};
	/**
	 * @description Function to get customer support PrePlan list from API for Customer Support history plans modal
	 * @param params
	 */
	/* istanbul ignore next */
	const getCustomerSupporHistoryDetails = (custParams) => {
	 const visionaerId =`${custParams.props.customerHistoryData}` 
	const payload = {
		preopPlanId: visionaerId,
		sortOrder: custParams.sortOrder || 'desc',
		columnName: custParams.columnSortBy || 'updatedDate',
	}
    setLoading(true);
		getCustSupportPlanHistoryService(payload, (err, result) => {
			/* istanbul ignore next  */
	
			if (!Array.isArray(result.data.preopPlanId)) {
				setErrorObj(new Error(errorMessages.customerSupport.noPlanHistory));
				setLoading(false);
			} else {
				if (err) {
					setErrorObj(err);
					setLoading(false);
				} else {
					setData(result.data.preopPlanId[0].comments);
					setErrorObj(null);
					setLoading(false);
				}
			}
		});
	}

	/**
	 * @description Function set customer support PrePlan details for Customer Support history plans deails modal
	 * @param params
	 */
	/* istanbul ignore next */
	const getCustomerSupporHistoryCommentsHistory = (historyParams) => {
		if (historyParams.props.viewHistoryData) {
			setData(historyParams.props.viewHistoryData);
			setErrorObj(null);
			setLoading(false);
		} else {
			setErrorObj("No history data available.");
			setLoading(false);
		}
	}


	/**
	 * @description Function to get customer support PrePlan list from API for Customer Support pre-op plans page in customer support dashboard
	 * @param params Object with page number and page size
	 */
	/* istanbul ignore next */
	const getCustomerSupportPlanList = (planParams) => {

		let urlParams = {
			page: planParams.pageIndex + 1,
			limit: planParams.pageSize || 10,
			sortOrder: planParams.sortOrder || 'desc',
			columnName: planParams.columnSortBy || 'plan_creation_date,surgery_date',
			procedureStatus: props.filterObj.status || 1  // only available plans to show default load
		};
		

		if (planParams.props.showFilterBlock) {
			urlParams['procedureName'] = props.filterObj.procedureType;
			urlParams['customerid'] = props.filterObj.customer;
			urlParams['procedureStatus'] = props.filterObj.status;
			urlParams['salesrepName'] = props.filterObj.salesRep;
			urlParams['searchString'] = props.filterObj.searchString;

			if (urlParams['procedureStatus'] === 'all') {
				urlParams['showallstatus'] = true
			}

			for (let key in urlParams) {
				if (urlParams[key] === 'all' || urlParams[key] === '') {
					delete urlParams[key];
				}
			}

		}
		setLoading(true);
		// only get the data for active plans
		if(planParams.props.isActiveListOnly) {
			// authentication checked here we are getting error on logout with these API call
			isAuthenticated() && getCustomerSupportPreOpActiveListService(urlParams, (err, result) => {
				handleApiResponse(err, result, planParams);
			});
		} else {
			isAuthenticated() && getCustomerSupportPreOpListService(urlParams, (err, result) => {
				handleApiResponse(err, result, planParams);
			});
		}
	
	}

	/* istanbul ignore next */
	/**
	 * @description reuseable function for the handling response
	 * @param {*} err : Error obj
	 * @param {*} result :Obj containing result
	 * @param {*} params : params to get user's data like pagination
	 */
	const handleApiResponse = (err, result, resParams) => {
		/* istanbul ignore next */
		if (err) {
			if (err.errorCode === 5022) {
				handleError(new Error(errorMessages.salesrep.noResult));
			} else {
				handleError(err);
			}
		} else {
			const {
				PreopPlanCaseResponse,
				response_metadata: { total },
			} = Array.isArray(result.data) ? result.data[0] : result.data;

			
			
			handleSuccess(PreopPlanCaseResponse, total, resParams.pageSize);
		}
	};


	/**
	 * @description Function to get customers associated with a sales rep
	*/
	/* istanbul ignore next  */
	const getPreopCustomerList = (param) => {
		let salesRepUsername = getUserName();

		// this if condition is for testing only
		if (param?.props?.data) {
			setData(param.props.data);
			setLoading(false);
			setPageCount(1);
			setTotalCount(1);
			setErrorObj(null);
		} else {
			setLoading(true);
			getPreopCustomersService(salesRepUsername,
				(err, result) => {
					/* istanbul ignore next  */
					if (err) {
						setErrorObj(err);
						setLoading(false);
					} else {
						let getMetadata = result.data.customerList;
						let getTotalCount = result.data.total;
						setData(getMetadata);
						setPageCount(Math.ceil(getTotalCount));
						setTotalCount(getTotalCount);
						setErrorObj(null);
						setLoading(false);
					}
				}
			);
		}
	}

	/**
	 * @description Function to show user details in modal popup
	 * @param  {}
	 */
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const viewUserDetails = React.useCallback((user = null) => {
		if (user) {
			let userDetails = {
				email: user.email ? user.email : '',
				name: user.name ? user.name : '',
				userName: user.userName ? user.userName : '',
				group: user.group ? user.group : ''
			}
			setSelectedUser(userDetails);
		}

		toggleUserDetails ? setToggleUserDetails(false) : setToggleUserDetails(true);
	});

	/**
	 * @description Function to show user status change failed
	 * @param  {}
	 */
	/* istanbul ignore next  */
	const userStatusUpdateFailed = (error) => {
		setSuccessMsg('');
		setActionErrorObj(error);
	}


	/**
 * @description Function to show salesrep review pre plan update change failed
 * @param  {}
 */
	/* istanbul ignore next  */
	const reviewUpdateFailed = (error) => {
		setSuccessMsg('');
		setActionErrorObj(error);
	}


/**
 * @description Function to show salesrep review pre plan update change succeeded
 * @param  row: infomation about the row that is being updated
 */
	/* istanbul ignore next  */
	const reviewDataUpdated = (row) => {
		setActionErrorObj(null);
		setSuccessMsg('reviewsUpdate');
		setReviewUpdated(row)
		setTimeout(() => {
			setSuccessMsg('');
		}, 10000);
		if(row.type ==='salesRep') {
			getCustomerSupportPlanList({
				pageIndex:0,
				props:{}
			});
		}
	}

	/**
	 * @description Function to show user status change succeeded
	 * @param  {}
	 */
	/* istanbul ignore next  */
	const userStatusUpdated = (user) => {
		setActionErrorObj(null);
		props.userStatusUpdated(true);
		if (user.enabled) {
			setSelectedUser(user);
			setToggleResetPassword(true);
		} else {
			setSuccessMsg('userStatusInactive');
		}
		setTimeout(() => {
			setSuccessMsg('');
		}, 10000);
	}

	/**
	 * @description Function to show user details in modal popup
	 * @param  {}
	 */
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const userUpdated = React.useCallback((status, params) => {
		if (status) {
			getAllUsers(params);
		}
	});

	/**
	 * @description Function to show user details in modal popup
	 * @param  {}
	 */
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const resetUserPassword = React.useCallback((user = null) => {
		if (user) {
			let userDetails = {
				email: user.email ? user.email : '',
				name: user.name ? user.name : '',
				userName: user.userName ? user.userName : '',
				group: user.group ? user.group : ''
			}
			setSelectedUser(userDetails);
		}
		toggleResetPassword ? setToggleResetPassword(false) : setToggleResetPassword(true);
	});

	/**
	 * @description Function to get customers associated with a surgeon
	 * @param params Object with page number, sort order, sort by column and page size
	*/
	/* istanbul ignore next  */
	const getAllUsers = (params) => {
		var urlParams = {
			sortorder: params.sortOrder || 'asc',
			column: params.columnSortBy || 'name',
			pageno: params.pageIndex + 1,
			limit: params.pageSize || 10
		}
		if (props.searchString) {
			urlParams['searchString'] = props.searchString;
		}

		setLoading(true);
		getAllUsersList(urlParams,
			(err, result) => {
				/* istanbul ignore next  */
				if (err) {
					setErrorObj(err);
					setLoading(false);
				} else {
					let getMetadata = result.data.userList;
					let getTotalCount = result.data.response_metadata.total;
					setData(getMetadata);
					setPageCount(Math.ceil(getTotalCount / params.pageSize));
					setTotalCount(getTotalCount);
					setErrorObj(null);
					setActionErrorObj(null);
					setLoading(false);
				}
			}
		);
	}

	/**
	 * @description Function to get the devices in the system
	 * @param params Object with page number, sort order, sort by column and page size
	 */
	/* istanbul ignore next  */
	const getAllDevices = (params) => {
		var urlParams = {};

		if (!props.disableColmSort) {
			urlParams['sortorder'] = params.sortOrder || 'asc';
			urlParams['column'] = params.columnSortBy || 'customername';
		}

		if (props.showPagination) {
			urlParams['pageno'] = params.pageIndex + 1;
			urlParams['limit'] = params.pageSize || 10;
		}

		if (props.searchString) {
			urlParams['searchstring'] = props.searchString;
		}

		if (props.customerId) {
			urlParams['customerId'] = props.customerId;
		}

		getAllDevicesList(urlParams,
			(err, result) => {
				/* istanbul ignore next  */
				if (err) {
					setErrorObj(err);
					setLoading(false);
				} else {
					let getMetadata = result.data.user;
					let getTotalCount = result.data.response_metadata.total;
					setData(getMetadata);
					setPageCount(Math.ceil(getTotalCount / params.pageSize));
					setTotalCount(getTotalCount);
					setErrorObj(null);
					setLoading(false);
				}
			}
		);
	}

	/**
	 * @description Function to get the Customer license expiry details
	 * @param params Object with page number, sort order, sort by column and page size
	 */
	/* istanbul ignore next  */
	const getLicenseExpiryList = (exParams) => {
		var urlParams = {
			sortorder: exParams.sortOrder || 'asc',
			column: exParams.columnSortBy || 'customername'
		}
		getExpiredLicenseList(urlParams, (err, result) => {
			/* istanbul ignore next  */
			if (err) {
				setErrorObj(err);
				setLoading(false);
			} else {
				const licenseList = result.data.user ? result.data.user[0].licenseexpirationlist : [];
				const resCount = result.data.response_metadata ? result.data.response_metadata.total : 0;
				setData(licenseList);
				setTotalCount(resCount);
				setErrorObj(null);
				setActionErrorObj(null);
				setLoading(false);
			}
		})
	}

	const fetchData = React.useCallback(
		({ pageSize, pageIndex, sortBy }) => {
			let columnSortBy = ""; // default
			let sortOrder = ""; // default
			let keyMapping = {};
			/* istanbul ignore next  */
			if (props.listType === "file-list") {
				columnSortBy = "procedureId"; // default
				sortOrder = "desc"; // default
				keyMapping = {
					id: "procedureId",
					createdDate: "createddate",
					createdBy: "userid",
					filename: "filename",
					facilityName: "facilityname",
					Owner: "ownername",
					caseType: "casetype",
					source:  "source"
				};
			} else if (props.listType === "case-list") {
				columnSortBy = "dateOfSurgery"; // default
				sortOrder = "desc"; // default
				keyMapping = {
					"caseDetails.caseID": "caseID",
					"caseDetails.dateOfSurgery": "dateOfSurgery",
					"caseDetails.operativeProcedure": "operativeProcedure",
					"caseDetails.finalImplantDesign": "finalImplantDesign",
					"caseDetails.sex": "sex",
					"caseDetails.patientName": "patientName",
					"caseDetails.patientage": "patientage",
					"caseDetails.surgeonName": "surgeonName",
					"caseDetails.operativeSide": "operativeSide",
				};
			} else if (props.listType === "customer-list") {
				columnSortBy = "customername"; // default
				sortOrder = "asc"; // default
				keyMapping = {
					customername: "customername",
					combineAddressLines: "addressline1",
					phonenumber: "phonenumber",
					customerid: "customerid",
				};
			} else if (props.listType === "surgeon-list") {
				columnSortBy = "name"; // default
				sortOrder = "asc"; // default
				keyMapping = {
					userName: "username",
					name: "name",
					email: "email",
					surgeonId: "surgeonId"
				};
			} else if (props.listType === "audit-logs") {
				columnSortBy = "EventDate"; // default
				sortOrder = "desc"; // default
			} else if (props.listType === "surgeon-customers" || props.listType === "license-expiry-list") {
				columnSortBy = "customername"; // default
				sortOrder = "asc"; // default
			} else if (props.listType === "user-list") {
				columnSortBy = "name"; // default
				sortOrder = "asc"; // default
				keyMapping = {
					userName: "username",
					name: "name",
					email: "email",
					group: "group"
				};
			} else if (props.listType === "device-list") {
				columnSortBy = "customername"; // default
				sortOrder = "asc"; // default
				keyMapping = {
					serialno: "serialno",
					customername: "customername",
				};
			}
			/* istanbul ignore next  */
			if (props.listType === "review-plan-list") {
				columnSortBy = "plan_creation_date,surgery_date"; // default
				sortOrder = "desc"; // default
			}
			/* istanbul ignore next  */
			if (sortBy[0]) {
				columnSortBy = Object.keys(keyMapping).length ? keyMapping[sortBy[0].id] : sortBy[0].id;
				sortOrder = sortBy[0].desc ? "desc" : "asc";
			}

			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Set the loading state
			/* istanbul ignore next  */

			if (props.refreshDataList === false) {
				setLoading(false);
				return true;
			}
			setLoading(true);
			setDownloadErrorMsg("");

			// Only update the data if this is the latest fetch
			/* istanbul ignore next  */
			if (fetchId === fetchIdRef.current) {
				if (props.listType === "file-list") {
					procedureMetadataListService(
						pageIndex + 1,
						columnSortBy,
						sortOrder,
						pageSize,
						(err, result) => {
							/* istanbul ignore next  */
							if (err) {
								setErrorObj(err);
								setLoading(false);
							} else {
								let getMetadata = result.data && result.data.procedure_metadata ? result.data.procedure_metadata : [];
								let getTotalCount = result.data && result.data.procedure_metadata && result.data.response_metadata.total ? result.data.response_metadata.total : 0;
								setData(getMetadata);
								setPageCount(Math.ceil(getTotalCount / pageSize));
								setTotalCount(getTotalCount);
								setErrorObj(null);
								setLoading(false);
							}
						}
					);
				} else if (props.listType === "proms-list" && (props.refreshDataList === '' || props.refreshDataList === true)) {
					setPromsSuccessMsg('');
					getPromsScoreList();
				} else if (props.listType === "case-list") {
					getCaseList({ pageIndex, columnSortBy, sortOrder, pageSize });
				} else if (props.listType === "rar-association") {
					rarAssociationReqList();
				} else if (props.listType === "review-case-list") {
					getReviewCaseList({ pageIndex, columnSortBy, sortOrder, pageSize });
				} else if (props.listType === "customer-list" && (props.refreshDataList === '' || props.refreshDataList === true)) {
					getCustomerList({ pageIndex, columnSortBy, sortOrder, pageSize });
				} else if (props.listType === "surgeon-list" && (props.refreshDataList === '' || props.refreshDataList === true)) {
					getSurgeonList({ pageIndex, columnSortBy, sortOrder, pageSize });
				} else if (props.listType === "audit-logs") {
					getAuditLogs({ pageIndex, columnSortBy, sortOrder, pageSize,eventSource:props.filterObj.eventSource });
				} else if (props.listType === "device-list" && (props.refreshDataList === '' || props.refreshDataList === true)) {
					getAllDevices({ pageIndex, pageSize, columnSortBy, sortOrder });
				} else if (props.listType === "surgeon-customers") {
					getSurgeonCustomers({ pageIndex, columnSortBy, sortOrder, pageSize });
				} else if (props.listType === "preop-customer-list") {
                    getPreopCustomerList({props,columnSortBy, sortOrder,});
				} else if (props.listType === "user-list" && (props.refreshDataList === '' || props.refreshDataList === true)) {
					getAllUsers({ pageIndex, columnSortBy, sortOrder, pageSize });
				} else if (props.listType === "license-expiry-list") {
					getLicenseExpiryList({ columnSortBy, sortOrder });
				} else if (['comment-history', 'bill-of-materials'].indexOf(props.listType) > -1 ) {
					setData(props.dataSet && props.dataSet.length ? props.dataSet : []);
					setTotalCount(props.dataSet && props.dataSet.length ? props.dataSet.length : 0);
					setLoading(false);
				} else if (props.listType === "review-plan-list") {
					 getReviewPrePlanList({ pageIndex, pageSize, columnSortBy, sortOrder,props });
					
				}  else if (props.listType === "review-viewall-list") {
					getReviewAllPlanList({ pageIndex, pageSize, columnSortBy, sortOrder,props });
					
				}else if (props.listType === "customer-support-preoplist") {
                    getCustomerSupportPlanList({pageIndex, pageSize, columnSortBy, sortOrder, props});
				}
				else if (props.listType === "customer-support-historylist") {
					getCustomerSupporHistoryDetails({ pageIndex, pageSize, columnSortBy, sortOrder, props });
				}
				else if (props.listType === "customer-support-history-details") {
					getCustomerSupporHistoryCommentsHistory({props });
				}
				else {
					setLoading(false);
				}
			}
		},
		// eslint-disable-next-line
		[props]
	);
	// Create a function that will render our row sub components
	/* istanbul ignore next  */
	// eslint-disable-next-line
	const renderRowSubComponent = React.useCallback(
		({ row, rowProps, visibleColumns }) => (
			<>
				{row?.original?.patientDetails ? (
					<SubRowsReviewPlans row={row} rowProps={rowProps} visibleColumns={visibleColumns} type={props?.listType} />
				) : (
					<SubRowAsync row={row} rowProps={rowProps} visibleColumns={visibleColumns} />
				)}
			</>
		),
		[props]
	);
	return (
		<Table
			columns={columns}
			data={data}
			fetchData={fetchData}
			actionErrorObj={actionErrorObj}
			loading={loading}
			downloading={downloading}
			downloadErrorMsg={downloadErrorMsg}
			pageCount={pageCount}
			totalCount={totalCount}
			listType={props.listType}
			defaultPageSize={props.listType === 'audit-logs' ? 100 : 10}
			showPagination={props.showPagination}
			disableColmSort={props.disableColmSort}
			filterObj={props.filterObj}
			editProms={editProms}
			promsEditObj={promsEditObj}
			deviceStatusObj={deviceStatusObj}
			refreshProms={refreshProms}
			enableEditProms={enableEditProms}
			showConfirmModal={showConfirmModal}
			showHideConfirmModal={showHideConfirmModal}
			deletePromScore={deletePromScore}
			updateDeviceStatus={updateDeviceStatus}
			showPromsSuccessMsg={showPromsSuccessMsg}
			showDeviceListSuccessMsg={showDeviceListSuccessMsg}
			successMsg={successMsg}
			getHiddenColumns={props.hiddenColumns}
			showReviewCountMessage={showReviewCountMessage}
			reviewCountMessage={reviewCountMessage}
			toggleUserDetails={toggleUserDetails}
			toggleDeviceList={toggleDeviceList}
			selectedUser={selectedUser}
			viewUserDetails={viewUserDetails}
			changeDeviceStatus={changeDeviceStatus}
			userUpdated={userUpdated}
			searchString={props.searchString ? props.searchString : ''}
			toggleResetPassword={toggleResetPassword}
			resetUserPassword={resetUserPassword}
			renderRowSubComponent={renderRowSubComponent}
			errorObj={errorObj}
			reviewUpdated={reviewUpdated}
			deleteCustomerAssociation={deleteCustomerAssociation}
			closeSalesRepCustomerModal={closeSalesRepCustomerModal}
			confirmModal={confirmModal}
			params={params}
		/>
	);
}
export { DataTable, Table };
