import React, { Component } from 'react';
import { customRoundOff, getSignedUnit, getImgSrc } from '../../helpers/GlobalFunctions';
import PatientDetails from './PatientDetails';
import CaseReport from './CaseReport';
import Comments from './Comments';
import GapsContainer from '../../containers/CaseDetails/GapsContainer';
export default class TKASummary extends Component {
	render() {
		const { caseData, isLoading, gapLoading, gapData, gapError, handleNoteSubmit, notesError, notesSaved, isReadOnlyNotes, handleNotesInputChange, getUserRoleType,caseId } = this.props;
		let caseDetails = caseData && caseData.caseDetails ? caseData.caseDetails : '';
		let caseIntraOp = caseData && caseData.caseIntraOp ? caseData.caseIntraOp : '';
		let angles = caseIntraOp.angles ? caseIntraOp.angles : '';
		let distances = caseIntraOp.distances ? caseIntraOp.distances : '';
		let isTensioner = gapData && gapData.tensioner && gapData.tensioner.length && gapData.tensioner.length > 0;
		let showForceRange = caseDetails.gapsCollected && caseDetails.gapsCollected.toLowerCase() === 'tensioner';
		
        return (
			<>
				<div className="case-details-container">
					{/* Case details left part start */}
					<div className="case-details-left">
						{/* Patient block starts */}
						<div className="case-details-left-blocks">
							<PatientDetails caseDetails={caseDetails} getUserRoleType={getUserRoleType} />
						</div>

						{/* Case Report */}
						<div className="case-details-left-blocks">
							<CaseReport caseData={caseData} caseDetails={caseDetails} isTensioner={isTensioner} showForceRange={showForceRange} />
						</div>
					</div>

					<div className="case-details-right">
						{/* Alignment */}
						<div className="case-details-right-blocks">
							<div className="card-body alignment-block">
								<h2 className="card-title">Alignment</h2>
								<div className="row">
									<div className="col-md-8">
										<div className="form-group row">
											<label className="col-md-5 col-form-label">Pre-Operative Alignment</label>
											<div className="col-md-4">
												{angles && angles.nonDerived && !isNaN(parseFloat(angles.nonDerived.PreOperativeAlignment)) ?
													<span className="form-control">
														{customRoundOff(angles && angles.nonDerived && angles.nonDerived.PreOperativeAlignment ? angles.nonDerived.PreOperativeAlignment : null)}°
														&nbsp;{getSignedUnit(angles && angles.nonDerived && angles.nonDerived.PreOperativeAlignment ? angles.nonDerived.PreOperativeAlignment : null, 'alignment')}
													</span> : <span className="form-control">N/A</span>}

											</div>
										</div>
										<div className="form-group row">
											<label className="col-md-5 col-form-label">Planned Alignments</label>
											<div className="col-md-4">
												{angles && angles.nonDerived && !isNaN(parseFloat(angles.nonDerived.PlannedAlignment)) ?
													<span className="form-control">
														{customRoundOff(angles.nonDerived.PlannedAlignment)}°
														&nbsp;{getSignedUnit(angles.nonDerived.PlannedAlignment, 'alignment')}
													</span> : <span className="form-control">N/A</span>}
											</div>
										</div>
										<div className="form-group row">
											<label className="col-md-5 col-form-label">Achieved Alignment</label>
											<div className="col-md-4">
												{angles && angles.nonDerived && !isNaN(parseFloat(angles.nonDerived.AchievedAlignment)) ?
													<span className="form-control">
														{customRoundOff(angles.nonDerived.AchievedAlignment)}°
														&nbsp;{getSignedUnit(angles.nonDerived.AchievedAlignment, 'alignment')}
													</span> : <span className="form-control">N/A</span>}
											</div>
										</div>
									</div>
									<div className="col-md-3 img-alignment">
										{caseDetails.operativeSide && caseDetails.operativeSide.toString().toLowerCase() === "left" ?
											<img className="img-bg-color-preop" src={getImgSrc('LeftCoronalView1.png')} alt="PreOp Alignment Left" />
											: <img className="img-bg-color-preop" src={getImgSrc('RightCoronalView2.png')} alt="PreOp Alignment Right" />}
									</div>
								</div>
							</div>
						</div>
						
						{/* Components */}
						<div className="case-details-right-blocks">
							<div className={`components-body ${showForceRange ? 'tensioner' : 'manual'}`}>
								<div className={`card-body ${caseDetails?.visionaire && caseDetails?.caseType ? 'card-f-body' :caseDetails?.visionaire || caseDetails?.caseType ? 'card-s-body':`card-body`}`}>
									<h2 className="card-title">Components</h2>
									<div className="row">
										<div className="col-md-5 case-component-div case-component-border">
											<label className="case-label-align-top">Femur Varus/Valgus</label>
											<div className="case-text-align-below">
												{angles && angles.derived && !isNaN(parseFloat(angles.derived.FemurVVComponent)) ? `${customRoundOff(angles.derived.FemurVVComponent)}°
																						${getSignedUnit(angles.derived.FemurVVComponent, 'alignment')}` : 'N/A'}
											</div>
											{caseDetails.operativeSide && caseDetails.operativeSide.toString().toLowerCase() === "left" ?
												<div className="align-image-text ">
													<div className="align-text-middle">
														<span className="color-orange-left">{distances && distances.nonDerived && !isNaN(parseFloat(distances.nonDerived.FemurDistalMedialResectionDepth)) ? `${customRoundOff(distances.nonDerived.FemurDistalMedialResectionDepth)} mm` : 'N/A'}</span>
														<br />
														<span className="color-orange-left">{distances && distances.nonDerived && !isNaN(parseFloat(distances.nonDerived.TibiaMedialResectionDepth)) ? `${customRoundOff(distances.nonDerived.TibiaMedialResectionDepth)} mm` : 'N/A'}</span>
													</div>
													<img className="img-bg-color" src={getImgSrc('LeftCoronalView.png')} alt="Resection Frontal Left" />
													<div className="align-text-middle">
														<span className="color-purple-right">{distances && distances.nonDerived && !isNaN(parseFloat(distances.nonDerived.FemurDistalLateralResectionDepth)) ? `${customRoundOff(distances.nonDerived.FemurDistalLateralResectionDepth)} mm` : 'N/A'}</span>
														<br />
														<span className="color-purple-right">{distances && distances.nonDerived && !isNaN(parseFloat(distances.nonDerived.TibiaLateralResectionDepth)) ? `${customRoundOff(distances.nonDerived.TibiaLateralResectionDepth)} mm` : 'N/A'}</span>
													</div>
												</div> : <div className="align-image-text">
													<div className="align-text-middle">
														<span className="color-purple-left">{distances && distances.nonDerived && !isNaN(parseFloat(distances.nonDerived.FemurDistalLateralResectionDepth)) ? `${customRoundOff(distances.nonDerived.FemurDistalLateralResectionDepth)} mm` : 'N/A'}</span>
														<br />
														<span className="color-purple-left">{distances && distances.nonDerived && !isNaN(parseFloat(distances.nonDerived.TibiaLateralResectionDepth)) ? `${customRoundOff(distances.nonDerived.TibiaLateralResectionDepth)} mm` : 'N/A'}</span>
													</div>
													<img className="img-bg-color" src={getImgSrc('RightCoronalView.png')} alt="Resection Frontal Right" />
													<div className="align-text-middle">
														<span className="color-orange-right">{distances && distances.nonDerived && !isNaN(parseFloat(distances.nonDerived.FemurDistalMedialResectionDepth)) ? `${customRoundOff(distances.nonDerived.FemurDistalMedialResectionDepth)} mm` : 'N/A'}</span>
														<br />
														<span className="color-orange-right">{distances && distances.nonDerived && !isNaN(parseFloat(distances.nonDerived.TibiaMedialResectionDepth)) ? `${customRoundOff(distances.nonDerived.TibiaMedialResectionDepth)} mm` : 'N/A'}</span>
													</div>
												</div>
											}
											<div className="case-text-align-below case-text-lower-row">
												{angles && angles.derived && !isNaN(parseFloat(angles.derived.TibiaVVComponent)) ? `${customRoundOff(angles.derived.TibiaVVComponent)}°
																						${getSignedUnit(angles.derived.TibiaVVComponent, 'alignment')}` : 'N/A'}
											</div>
											<label className="case-label-align-top"><br />Tibia Varus/Valgus</label>
										</div>
										<div className="col-md-4 case-component-div case-component-border">
											<label className="case-label-align-top">Femur Rotation</label>
											<div className="case-text-align-below">
												{angles && angles.derived && !isNaN(parseFloat(angles.derived.FemurRotationComponent)) ? `${customRoundOff(angles.derived.FemurRotationComponent)}°
																						${getSignedUnit(angles.derived.FemurRotationComponent, 'rotation')}` : 'N/A'}
											</div>
											{caseDetails.operativeSide && caseDetails.operativeSide.toString().toLowerCase() === "left" ?
												<div className="align-image-text">
													<div className="align-text-middle-single-point">
														<span className="color-orange-left">{distances && distances.nonDerived && !isNaN(parseFloat(distances.nonDerived.FemurPosteriorMedialResectionDepth)) ? `${customRoundOff(distances.nonDerived.FemurPosteriorMedialResectionDepth)} mm` : 'N/A'}</span>
													</div>
													<img className="img-bg-color" src={getImgSrc('LeftAxialView.png')} alt="Resection Transverse Left" />
													<div className="align-text-middle-single-point">
														<span className="color-purple-right">{distances && distances.nonDerived && !isNaN(parseFloat(distances.nonDerived.FemurPosteriorLateralResectionDepth)) ? `${customRoundOff(distances.nonDerived.FemurPosteriorLateralResectionDepth)} mm` : 'N/A'}</span>
													</div>
												</div> : <div className="align-image-text">
													<div className="align-text-middle-single-point">
														<span className="color-purple-left">{distances && distances.nonDerived && !isNaN(parseFloat(distances.nonDerived.FemurPosteriorLateralResectionDepth)) ? `${customRoundOff(distances.nonDerived.FemurPosteriorLateralResectionDepth)} mm` : 'N/A'}</span>
													</div>
													<img className="img-bg-color" src={getImgSrc('RightAxialView.png')} alt="Resection Transverse Right" />
													<div className="align-text-middle-single-point">
														<span className="color-orange-right">{distances && distances.nonDerived && !isNaN(parseFloat(distances.nonDerived.FemurPosteriorMedialResectionDepth)) ? `${customRoundOff(distances.nonDerived.FemurPosteriorMedialResectionDepth)} mm` : 'N/A'}</span>
													</div>
												</div>}
											{/* <div className="case-text-align-below case-text-lower-row">
												{angles && angles.derived && !isNaN(parseFloat(angles.derived.TibiaRotationComponent)) ? `${customRoundOff(angles.derived.TibiaRotationComponent)}°
																						${getSignedUnit(angles.derived.TibiaRotationComponent, 'rotation')}` : 'N/A'}
											</div>
											<label className="case-label-align-top"><br />Tibia Rotation</label> */}
										</div>
										<div className="col-md-3 case-component-div femur-component">
											<label className="case-label-align-top">Femur Flexion</label>
											<div className="case-text-align-below">
												{angles && angles.derived && !isNaN(parseFloat(angles.derived.FemurFlexionComponent)) ? `${customRoundOff(angles.derived.FemurFlexionComponent)}°
																						${getSignedUnit(angles.derived.FemurFlexionComponent, 'flexion')}` : 'N/A'}
											</div>
											<img className="img-bg-color" src={getImgSrc('SagittalView.png')} alt="ResectionSag" />
											<div className="case-text-align-below case-text-lower-row">
												{angles && angles.derived && !isNaN(parseFloat(angles.derived.TibiaSlopeComponent)) ? `${customRoundOff(angles.derived.TibiaSlopeComponent)}°
																						${getSignedUnit(angles.derived.TibiaSlopeComponent, 'slope')}` : 'N/A'}
											</div>
											<label className="case-label-align-top"><br />Tibia Slope</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* Gap data graph */}
				{gapLoading ? '' : <GapsContainer caseDetails={caseDetails} gapData={gapData} isTensioner={isTensioner} gapError={gapError} />}

				{/* Comments */}
				<div className="case-details-container mt-0">
					<div className="case-details-center-blocks w-100">
						<Comments
							caseData={caseData}
							height={200}
							isReadOnlyNotes={isReadOnlyNotes}
							notesError={notesError}
							notesSaved={notesSaved}
							handleNotesInputChange={handleNotesInputChange}
							handleNoteSubmit={handleNoteSubmit}
							getUserRoleType={getUserRoleType}
							caseId={caseId}

						/>
					</div>
					{isLoading ? <div className="isLoading-overlay"><span>Saving...</span></div> : null}
				</div>
			</>
		)
	}
}
