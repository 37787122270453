import { operativeProcedureTypes } from "../../helpers/constants";

export const PROMScoreTimeIntervals = [
    { value: '6 weeks', label: '6 Weeks' },
    { value: '12 weeks', label: '12 Weeks' },
    { value: '6 months', label: '6 Months' },
    { value: '12 months', label: '12 Months' },
    { value: '2 years', label: '2 Years' },
]
export const PROMScoreTypes = {
    [operativeProcedureTypes.TKA.inLowerCase]: [
        { value: 'KOOS', label: 'KOOS', subScores: true },
        { value: 'KOOS JR.', label: 'KOOS JR.', subScores: false },
        { value: 'PROMIS-Global', label: 'PROMIS-Global', subScores: false },
        { value: 'KSS', label: 'KSS', subScores: false },
        { value: 'Oxford Knee Score', label: 'Oxford Knee Score', subScores: false },
        { value: 'Forgotten Joint Score', label: 'Forgotten Joint Score', subScores: false },
        { value: 'EQ5D', label: 'EQ5D', subScores: true },
    ],
    [operativeProcedureTypes.HIP.inLowerCase]: [
        { value: 'HOOS', label: 'HOOS', subScores: true },
        { value: 'HOOS JR.', label: 'HOOS JR.', subScores: false },
        { value: 'PROMIS-Global', label: 'PROMIS-Global', subScores: false }
    ]
}

export const scoreTypesForManualScore = ['KOOS', 'EQ5D', 'HOOS'];

export const manualPromScoreFields = {
    'KOOS': [
        { label: 'Pain', fieldName: 'KOOSPain', type: 'KOOS Pain', color: '#59BE78' },
        { label: 'Symptom', fieldName: 'KOOSSymptom', type: 'KOOS Symptom', color: '#B07CD9' },
        { label: 'ADL', fieldName: 'KOOSADL', type: 'KOOS ADL', color: '#EDA542' },
        { label: 'Sports', fieldName: 'KOOSSports', type: 'KOOS Sports', color: '#E8545A' },
        { label: 'QOL', fieldName: 'KOOSQOL', type: 'KOOS QOL', color: '#2DA0E0' }
    ],
    'EQ5D': [
        { label: 'Mobility', fieldName: 'EQ5DMobility', type: 'EQ5D Mobility', color: '#59BE78' },
        { label: 'Care', fieldName: 'EQ5DCare', type: 'EQ5D Care', color: '#B07CD9' },
        { label: 'Activity', fieldName: 'EQ5DActivity', type: 'EQ5D Activity', color: '#EDA542' },
        { label: 'Pain', fieldName: 'EQ5DPain', type: 'EQ5D Pain', color: '#E8545A' },
        { label: 'Anxiety', fieldName: 'EQ5DAnxiety', type: 'EQ5D Anxiety', color: '#2DA0E0' },
    ],
    'HOOS': [
        { label: 'Pain', fieldName: 'HOOSPain', type: 'HOOS Pain', color: '#59BE78' },
        { label: 'Symptom', fieldName: 'HOOSSymptom', type: 'HOOS Symptom', color: '#B07CD9' },
        { label: 'ADL', fieldName: 'HOOSADL', type: 'HOOS ADL', color: '#EDA542' },
        { label: 'Sport/Rec', fieldName: 'HOOSSports', type: 'HOOS Sports', color: '#E8545A' },
        { label: 'QOL', fieldName: 'HOOSQOL', type: 'HOOS QOL', color: '#2DA0E0' }
    ]
}

export const CSVExportHeaders = {
    [operativeProcedureTypes.TKA.inLowerCase]: [
        { label: "CORI case ID", key: "caseID" },
        { label: "Case Date", key: "CaseDate" },
        { label: "Sex", key: "Sex" },
        { label: "Patient Age", key: "PatientAge" },
        { label: "BMI", key: "BMI" },
        { label: "Femur Size", key: "FemurSize" },
        { label: "Tibia Size", key: "TibiaSize" },
        { label: "Poly Thickness", key: "PolyThickness" },
        { label: "Implant Type", key: "ImplantType" },
        { label: "Gaps Collected", key: "GapsCollected" },
        { label: "Force Range", key: "ForceRange" },
        { label: "SW Version", key: "SWVersion" },
        { label: "Workflow", key: "CaseType" },
        { label: "Pre-op Plan ID", key: "VisionaireID" },
        { label: "Distal Femur Medial Resection (mm)", key: "DistalFemurMedialResection" },
        { label: "Distal Femur Lateral Resection (mm)", key: "DistalFemurLateralResection" },
        { label: "Posterior Femur Medial Resection (mm)", key: "PosteriorFemurMedialResection" },
        { label: "Posterior Femur Lateral Resection (mm)", key: "PosteriorFemurLateralResection" },
        { label: "Femur Rotation (°)", key: "FemurRotation" },
        { label: "Femur Varus/Valgus (°)", key: "FemurVarusValgus" },
        { label: "Femur Flexion (°)", key: "FemurFlexion" },
        { label: "Tibia Medial Resection (mm)", key: "MedialTibiaResection" },
        { label: "Tibia Lateral Resection (mm)", key: "LateralTibiaResection" },
        // { label: "Tibia Rotation (°)", key: "TibiaRotation" },
        { label: "Tibia Varus/Valgus (°)", key: "TibiaVarusValgus" },
        { label: "Tibia Slope (°)", key: "TibiaSlope" },
        { label: "Preop Leg Alignment (°)", key: "PreopLegAlignment" },
        { label: "Planned Leg Alignment (°)", key: "PlannedLegAlignment" },
        { label: "Postop Leg Alignment (°)", key: "PostopLegAlignment" },
        // { label: "Preop Max Extension (°)", key: "PreopMaxExtension" },
        // { label: "Preop Max Flexion (°)", key: "PreopMaxFlexion" },
        // { label: "Postop Max Extension (°)", key: "PostopMaxExtension" },
        // { label: "Postop Max Flexion (°)", key: "PostopMaxFlexion" },
        { label: "Setup Time (s)", key: "SetupTime" },
        { label: "Registration Time (s)", key: "RegistrationTime" },
        { label: "Planning Time (s)", key: "PlanningTime" },
        { label: "Bone Removal Time (s)", key: "BoneRemovalTime" },
        { label: "Postop Assessment Time (s)", key: "PostopAssessmentTime" },
        { label: "Total Procedure Time (s)", key: "TotalProcedureTime" },
        { label: "Comments", key: "Comments" },
        { label: "Comments Last Updated", key: "CommentsUpdatedDate" },
        { label: "Comments Updated By", key: "CommentsUpdatedBy" }


    ],
    [operativeProcedureTypes.HIP.inLowerCase]: [
        { label: "Patient ID", key: "PatientId" },
        { label: "Surgery Date", key: "CaseDate" },
        { label: "Sex", key: "Sex" },
        { label: "Patient Age", key: "PatientAge" },
        { label: "BMI", key: "BMI" },
        { label: "Operative Side", key: "OperativeSide" },
        { label: "Surgical Approach", key: "SurgicalApproach" },
        { label: "SW Version", key: "SWVersion" },
        { label: "APP Anteversion (°)", key: "AnteversionApp" },
        { label: "APP Inclination (°)", key: "InclinationApp" },
        { label: "FPP Anteversion (°)", key: "AnteversionXray" },
        { label: "FPP Inclination (°)", key: "InclinationXray" },
        { label: "Pelvic Tilt (°)", key: "PelvicTilt" },
        { label: "ASIS Distance (mm)", key: "AsisDistance" },
        { label: "Acetabular Distance (mm)", key: "AcetabularDistance" },
        { label: "Acetabular Diameter (mm)", key: "AcetabularDiameter" },
        { label: "Leg Length (mm)", key: "LegLength" },
        { label: "Offset (mm)", key: "Offset" },
        { label: "Registration Time (s)", key: "RegistrationTime" },
        { label: "Navigation Time (s)", key: "NavigationTime" },
        { label: "Total Time (s)", key: "TotalProcedureTime" },
        { label: "Comments", key: "Comments" },
        { label: "Comments Last Updated", key: "CommentsUpdatedDate" },
        { label: "Comments Updated By", key: "CommentsUpdatedBy" }



    ]
}

export const commentsEditorConfig = (customConfig = null) => {
    return {
        placeholder: (customConfig && customConfig.placeholder) || 'Write something...',
        resize_enabled: false,
        removePlugins: ['elementspath', 'magicline', 'htmlwriter'],
        extraPlugins: 'indentblock',
        fillEmptyBlocks: false,
        enterMode: 3,
        tabSpaces: 0,
        height: (customConfig && customConfig.height) || 100,
        toolbar: [
            ['Format'],
            ['Bold', 'Italic', 'BulletedList', 'NumberedList', 'BlockQuote'],
            ['Indent', 'Outdent'], ['Undo', 'Redo']
        ]
    }
}
/**
 * ===============================
 * ======= PROM DUMMY DATA =======
 * ===============================
 */
export const dummyResPROMKOOS = {
    "lastCalculationTime": "2023-08-02T05:38:33.981+00:00",
    "scoreType": "KOOS",
    "myCases": [        
        {
            "scoreType": "KOOS",
            "subScoreType": "",
            "timeInterval": "12 weeks",
            "avg": "",
            "min": "",
            "max": "",
            "totalCount": 5,
            "subScoreTypeList": [
                {
                    "type": "KOOS Symptom",
                    "avg": "54",
                    "min": "54.0",
                    "max": "54.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Sports",
                    "avg": "54.0",
                    "min": "24.0",
                    "max": "24.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Pain",
                    "avg": "52.0",
                    "min": "12.0",
                    "max": "12.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS QOL",
                    "avg": "54.0",
                    "min": "34.0",
                    "max": "34.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS ADL",
                    "avg": "55.0",
                    "min": "65.0",
                    "max": "65.0",
                    "totalCount": 1,
                    "scoreType": ""
                }
            ],
            "source": "",
            "caseId": "",
            "lastCalculationTime": null
        },
        {
            "scoreType": "KOOS",
            "subScoreType": "",
            "timeInterval": "2 years",
            "avg": "",
            "min": "",
            "max": "",
            "totalCount": 5,
            "subScoreTypeList": [
                {
                    "type": "KOOS Symptom",
                    "avg": "",
                    "min": "54.0",
                    "max": "54.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Sports",
                    "avg": "24.0",
                    "min": "24.0",
                    "max": "24.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Pain",
                    "avg": "12.0",
                    "min": "12.0",
                    "max": "12.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS QOL",
                    "avg": "34.0",
                    "min": "34.0",
                    "max": "34.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS ADL",
                    "avg": "65.0",
                    "min": "65.0",
                    "max": "65.0",
                    "totalCount": 1,
                    "scoreType": ""
                }
            ],
            "source": "",
            "caseId": "",
            "lastCalculationTime": null
        },
        {
            "scoreType": "KOOS",
            "subScoreType": "",
            "timeInterval": "6 weeks",
            "avg": "",
            "min": "",
            "max": "",
            "totalCount": 5,
            "subScoreTypeList": [
                {
                    "type": "KOOS Symptom",
                    "avg": "44.0",
                    "min": "54.0",
                    "max": "54.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Sports",
                    "avg": "45.0",
                    "min": "24.0",
                    "max": "24.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Pain",
                    "avg": "44.0",
                    "min": "12.0",
                    "max": "12.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS QOL",
                    "avg": "44.0",
                    "min": "34.0",
                    "max": "34.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS ADL",
                    "avg": "43.0",
                    "min": "65.0",
                    "max": "65.0",
                    "totalCount": 1,
                    "scoreType": ""
                }
            ],
            "source": "",
            "caseId": "",
            "lastCalculationTime": null
        },
        {
            "scoreType": "KOOS",
            "subScoreType": "",
            "timeInterval": "6 months",
            "avg": "",
            "min": "",
            "max": "",
            "totalCount": 5,
            "subScoreTypeList": [
                {
                    "type": "KOOS Symptom",
                    "avg": "34",
                    "min": "54.0",
                    "max": "54.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Sports",
                    "avg": "24.0",
                    "min": "24.0",
                    "max": "24.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Pain",
                    "avg": "12.0",
                    "min": "12.0",
                    "max": "12.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS QOL",
                    "avg": "34.0",
                    "min": "34.0",
                    "max": "34.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS ADL",
                    "avg": "65.0",
                    "min": "65.0",
                    "max": "65.0",
                    "totalCount": 1,
                    "scoreType": ""
                }
            ],
            "source": "",
            "caseId": "",
            "lastCalculationTime": null
        },
        {
            "scoreType": "KOOS",
            "subScoreType": "",
            "timeInterval": "12 months",
            "avg": "",
            "min": "",
            "max": "",
            "totalCount": 5,
            "subScoreTypeList": [
                {
                    "type": "KOOS Symptom",
                    "avg": "45",
                    "min": "54.0",
                    "max": "54.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Sports",
                    "avg": "24.0",
                    "min": "24.0",
                    "max": "24.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Pain",
                    "avg": "12.0",
                    "min": "12.0",
                    "max": "12.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS QOL",
                    "avg": "34.0",
                    "min": "34.0",
                    "max": "34.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS ADL",
                    "avg": "65.0",
                    "min": "65.0",
                    "max": "65.0",
                    "totalCount": 1,
                    "scoreType": ""
                }
            ],
            "source": "",
            "caseId": "",
            "lastCalculationTime": null
        },
       
    ],
    "globalCases": [
        {
            "scoreType": "KOOS",
            "subScoreType": "",
            "timeInterval": "6 weeks",
            "avg": "",
            "min": "",
            "max": "",
            "totalCount": 5,
            "subScoreTypeList": [
                {
                    "type": "KOOS Symptom",
                    "avg": "54.0",
                    "min": "54.0",
                    "max": "54.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Sports",
                    "avg": "24.0",
                    "min": "24.0",
                    "max": "24.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Pain",
                    "avg": "12.0",
                    "min": "12.0",
                    "max": "12.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS QOL",
                    "avg": "34.0",
                    "min": "34.0",
                    "max": "34.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS ADL",
                    "avg": "65.0",
                    "min": "65.0",
                    "max": "65.0",
                    "totalCount": 1,
                    "scoreType": ""
                }
            ],
            "source": "",
            "caseId": "",
            "lastCalculationTime": null
        },        
        {
            "scoreType": "KOOS",
            "subScoreType": "",
            "timeInterval": "12 months",
            "avg": "",
            "min": "",
            "max": "",
            "totalCount": 5,
            "subScoreTypeList": [
                {
                    "type": "KOOS Symptom",
                    "avg": "45",
                    "min": "54.0",
                    "max": "54.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Sports",
                    "avg": "24.0",
                    "min": "24.0",
                    "max": "24.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Pain",
                    "avg": "12.0",
                    "min": "12.0",
                    "max": "12.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS QOL",
                    "avg": "34.0",
                    "min": "34.0",
                    "max": "34.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS ADL",
                    "avg": "65.0",
                    "min": "65.0",
                    "max": "65.0",
                    "totalCount": 1,
                    "scoreType": ""
                }
            ],
            "source": "",
            "caseId": "",
            "lastCalculationTime": null
        },
        {
            "scoreType": "KOOS",
            "subScoreType": "",
            "timeInterval": "2 years",
            "avg": "",
            "min": "",
            "max": "",
            "totalCount": 5,
            "subScoreTypeList": [
                {
                    "type": "KOOS Symptom",
                    "avg": "47.0",
                    "min": "54.0",
                    "max": "54.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Sports",
                    "avg": "24.0",
                    "min": "24.0",
                    "max": "24.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Pain",
                    "avg": "12.0",
                    "min": "12.0",
                    "max": "12.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS QOL",
                    "avg": "34.0",
                    "min": "34.0",
                    "max": "34.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS ADL",
                    "avg": "65.0",
                    "min": "65.0",
                    "max": "65.0",
                    "totalCount": 1,
                    "scoreType": ""
                }
            ],
            "source": "",
            "caseId": "",
            "lastCalculationTime": null
        },
        {
            "scoreType": "KOOS",
            "subScoreType": "",
            "timeInterval": "6 months",
            "avg": "",
            "min": "",
            "max": "",
            "totalCount": 5,
            "subScoreTypeList": [
                {
                    "type": "KOOS Symptom",
                    "avg": "54",
                    "min": "54.0",
                    "max": "54.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Sports",
                    "avg": "24.0",
                    "min": "24.0",
                    "max": "24.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Pain",
                    "avg": "12.0",
                    "min": "12.0",
                    "max": "12.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS QOL",
                    "avg": "34.0",
                    "min": "34.0",
                    "max": "34.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS ADL",
                    "avg": "65.0",
                    "min": "65.0",
                    "max": "65.0",
                    "totalCount": 1,
                    "scoreType": ""
                }
            ],
            "source": "",
            "caseId": "",
            "lastCalculationTime": null
        },
        {
            "scoreType": "KOOS",
            "subScoreType": "",
            "timeInterval": "12 weeks",
            "avg": "",
            "min": "",
            "max": "",
            "totalCount": 5,
            "subScoreTypeList": [
                {
                    "type": "KOOS Symptom",
                    "avg": "45",
                    "min": "54.0",
                    "max": "54.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Sports",
                    "avg": "24.0",
                    "min": "24.0",
                    "max": "24.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS Pain",
                    "avg": "12.0",
                    "min": "12.0",
                    "max": "12.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS QOL",
                    "avg": "34.0",
                    "min": "34.0",
                    "max": "34.0",
                    "totalCount": 1,
                    "scoreType": ""
                },
                {
                    "type": "KOOS ADL",
                    "avg": "65.0",
                    "min": "65.0",
                    "max": "65.0",
                    "totalCount": 1,
                    "scoreType": ""
                }
            ],
            "source": "",
            "caseId": "",
            "lastCalculationTime": null
        },
    ]
}