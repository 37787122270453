import React, { Component } from 'react';
import { getImgSrc } from '../../../helpers/GlobalFunctions';
import { SurgeonList } from '../RAR/SurgeonList';
import { salesRepMapping } from '../../../helpers/messages';
import { searchSalesRepCustomerService } from '../../../services/java/java-services';
import SalesRepInput from './SalesRepInput';

class AddSalesRepCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            refreshSalesRepCustomerList: '',
            searchResults: '',
            errors: {
                searchKey: '',
            },
            searchKey: '',
            searchResultsError: null,
        };
    }

    /** @description function to find sales rep customers
     *  @param  {}
     *  @memberof AddSalesRepCustomer
     */
    findSalesRepCustomers = async (searchKey) => {
        var errors = { ...this.state.errors };
        if (searchKey?.trim() === '') {
            errors.searchKey = salesRepMapping.emptySearchBox;
            this.setState({ errors });
            return errors;
        }
        await this.getSearchResults(searchKey);
    };

    /**
     * @description function to handle input changes..
     * @param  {} inputValue
     * @memberof AddSalesRepCustomer
     */
    handleInputChange = async (inputValue) => {
        var errors = { ...this.state.errors };
        errors.searchKey = '';
        this.setState({ errors });
        this.setState({ searchKey: inputValue.trim() });
    };

    /**
     *  @description function to search sales rep customer by clicking on enter
     *  @param  {}
     *  @memberof AddSalesRepCustomer
     */
    onEnterPress = (e) => {
        if (e.which === 13) {
            this.findSalesRepCustomers(this.state.searchKey);
        }
    };

    /**
     * @description function to get sales rep customer list based on search inputs
     * @param {*}
     * @memberof AddSalesRepCustomer
     */
    getSearchResults = async (searchKey) => {
        this.setState({ loading: true, searchResults: '', searchResultsError: null });
        searchSalesRepCustomerService(searchKey, (err, result) => {
            if (err) {
                this.setState({ loading: false, searchResultsError: err });
            } else {
              /* istanbul ignore next  */
                this.setState({ loading: false, searchResults: result.data.customerList ? result.data.customerList : [], searchResultsError: null });
            }
        });
    };

    render() {
        const { refreshDataList, action } = this.props;
        const { loading, searchResultsError, searchResults, errors, searchKey } = this.state;
        /* istanbul ignore next  */
        const columns = [
            {
                Header: 'Customer Name',
                accessor: 'customerName',
                Cell: ({ row }) => <div className='text-capitalize'>{row.original.customerName}</div>,
                Filter: '',
            },
            {
                Header: 'Address',
                accessor: 'address1',
                Filter: '',
                Cell: ({ row }) => `${row?.original?.address1}, ${row?.original?.address2}, ${row?.original?.postal}, ${row?.original?.state}, ${row?.original?.country}`,
            },
            {
                Header: 'Customer ID',
                accessor: 'customerId',
                Cell: ({ row }) => `${row.original.customerId}`,
                Filter: '',
            },
            {
                Header: ' ',
                searchSalesRepCustomerAction: true,
            },
        ];
        return (
            <div>
                <div className='surgeon-search-container'>
                    <SalesRepInput
                        onEnterPress={this.onEnterPress}
                        handleInputChange={this.handleInputChange}
                        formErrors={errors}
                        findSalesRepCustomers={this.findSalesRepCustomers}
                        searchKey={searchKey}
                    />
                </div>
                <div className='surgeon-search-results'>
                    {searchResults && loading === false ? (
                        <SurgeonList
                            listType='customer-search'
                            error={searchResultsError}
                            columns={columns}
                            showPagination={false}
                            disableColmSort={false}
                            searchData={searchResults}
                            refreshDataList={refreshDataList}
                            action={action}
                        />
                    ) : null}
                    {loading ? (
                        <div className='loading-img'>
                            <img src={getImgSrc('loading.gif')} alt='loading' />{' '}
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}
export default AddSalesRepCustomer;
