import React, { Component } from 'react';
import SideMenu from '../SideMenu/SideMenu';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import RarAssociation from '../../containers/RarAssociationList';
import UserAccount from '../../containers/UserAccount';
import { getUserRoleType } from '../../services/aws/aws-services';
import { logger } from '../../services/logger/logger-service';
import { loggerEventOutcome, loggerEventTypes, loggerEventName } from '../../helpers/messages';
import { Col, Nav, Row } from 'react-bootstrap';
import SurgeonPreferenceContainer from '../../containers/Preferences';
import DowntimeAdminSettings from '../../containers/DowntimeAdminSettings';

let startDate;
export default class Settings extends Component {
    /* istanbul ignore next  */
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 'passwordReset',
            activeMainTab: props.location && props.location.state && props.location.state.activeMainTab ? props.location.state.activeMainTab : 'account',
            loggerObj: {
                "EventOutcome": loggerEventOutcome.success,
                "EventType": loggerEventTypes.read,
                "EventName": loggerEventName.userSettings,
                "StartDate": new Date()
            }
        }
    }

    componentDidMount() {
        // initialize the start date on page load
        startDate = new Date();
        window.addEventListener('beforeunload', this.applicationLogger);
    }

    /* istanbul ignore next  */
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.applicationLogger);
        this.applicationLogger();
    }

    /**
     * @description function to handle application logs
     * @param {*}
     * @memberof Settings
     */
    /* istanbul ignore next  */
    applicationLogger(key) {
        // calculate the time since we loaded this page
        const timeSinceLoad = (new Date().getTime() - startDate.getTime()) / 1000;
        const loggerObj = {
            "EventOutcome": loggerEventOutcome.success,
            "EventType": loggerEventTypes.read,
            "EventName": loggerEventName.userSettings,
            "Content": {
                "TimeSpent": timeSinceLoad
            }
        }
        logger(loggerObj);
    }

    /**
     * @description function to vertical tab changes
     * @param event Click event
     * @param tab Eventkey of the tab clicked
     * @memberof Settings
     */
    /* istanbul ignore next  */
    handleTabChange = (event, tab) => {
        event.preventDefault();
        this.setState({ selectedTab: tab });
    }

    render() {
        const loggedInUserType = getUserRoleType();
        const { activeMainTab, selectedTab } = this.state;
        return (<div>
            <div className="bgGray">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-1 sm-side-width">
                            <SideMenu loggerObj={this.state.loggerObj} />
                        </div>
                        <div className="col-md-11 sm-col-width" >
                            <div className="data-list-tabs planning-tool-wrap">
                                <Tabs defaultActiveKey={activeMainTab}>
                                    <Tab eventKey="account" title="Account">
                                        <div className="account-tab-contents">
                                            <Tab.Container id="account-tabs" defaultActiveKey={selectedTab}>
                                                <Row>
                                                    <Col sm={3} className="account-tabs-list">
                                                        <Nav variant="pills" className="flex-column">
                                                            <Nav.Item id="passwordReset" onClick={(e) => this.handleTabChange(e, 'passwordReset')}>
                                                                <Nav.Link eventKey="passwordReset">Password Reset</Nav.Link>
                                                            </Nav.Item>
                                                            {loggedInUserType.includes('surgeon') ?
                                                                <Nav.Item id="THAPreferences" onClick={(e) => this.handleTabChange(e, 'THAPreferences')}>
                                                                    <Nav.Link eventKey="THAPreferences">Preferences </Nav.Link>
                                                                </Nav.Item>
                                                                : null}
                                                        </Nav>
                                                    </Col>
                                                    <Col sm={9}>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="passwordReset">
                                                                <UserAccount />
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="THAPreferences">
                                                                {selectedTab === 'THAPreferences' ? <SurgeonPreferenceContainer /> : ''}
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </Col>
                                                </Row>
                                            </Tab.Container>
                                        </div>
                                    </Tab>
                                    {loggedInUserType.includes('surgeon') ?
                                        <Tab eventKey="dataSharing" title="Data Sharing">
                                            <RarAssociation />
                                        </Tab> : null}
                                    {loggedInUserType.includes('admin') ?
                                        <Tab eventKey="config" title="Config">
                                            <div className="account-tab-contents">
                                                <Tab.Container id="account-tabs" defaultActiveKey='downtime'>
                                                    <Row>
                                                        <Col sm={3} className="account-tabs-list">
                                                            <Nav variant="pills" className="flex-column">
                                                                <Nav.Item id="downtime" onClick={(e) => this.handleTabChange(e, 'downtime')}>
                                                                    <Nav.Link eventKey="downtime">Downtime</Nav.Link>
                                                                </Nav.Item>

                                                            </Nav>
                                                        </Col>
                                                        <Col sm={9}>
                                                            <Tab.Content>
                                                                <Tab.Pane eventKey="downtime">
                                                                    <DowntimeAdminSettings />
                                                                </Tab.Pane>
                                                            </Tab.Content>
                                                        </Col>
                                                    </Row>
                                                </Tab.Container>
                                            </div>
                                        </Tab> 
                                        : null}
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}
