import { RegularExpressions } from "../../helpers/constants";
import { validateWhiteSpace } from "../../helpers/GlobalFunctions";
import { userMessages } from "../../helpers/messages";

/**
 * @description function for validate each input field
 * @param stateObj Current state object
 * @returns Object with error messages
 */
export const validateUserFields = (stateObj, fields = ['firstName', 'lastName', 'email']) => {
    var { errors, editObj } = stateObj;
    var errorFlag = false;
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const reWhiteSpace = new RegExp(/^\s+$/);
        if (!editObj[field] || reWhiteSpace.test(editObj[field]) === true) {
            errorFlag = true;
            errors[field] = userMessages[field];
        }
    }

    return { errorFlag, errors };
}

/**
 * @description function for validate Alphanumeric
 * @param value Alphanumeric fields
 * @returns Object with error messages
 */
export const validateAlphanumeric = (value) => {
    if (validateWhiteSpace(value)) {
        return { errorFlag: true, error: userMessages.whiteSpace }
    }
    
    var errorFlag = !RegularExpressions.alphanumeric.test(value);
    return { errorFlag: errorFlag ? true : false, error: errorFlag ? userMessages.alphaNumericRegex : '' }
}

/**
 * @description function for validate Alphanumeric username
 * @param value Username field
 * @returns Object with error messages
 */
export const validateUsername = (value) => {
    var errorFlag = !RegularExpressions.alphanumericNoSpace.test(value);
    return { errorFlag: errorFlag ? true : false, error: errorFlag ? userMessages.userNameRegex : '' }
}

/**
 * @description function for validate email
 * @param value Email field value
 * @returns Object with error messages
 */
export const validateEmail = (value) => {
    if (validateWhiteSpace(value)) {
        return { errorFlag: true, error: userMessages.whiteSpace }
    }
    var errorFlag = !RegularExpressions.email.test(value);
    return { errorFlag: errorFlag ? true : false, error: errorFlag ? userMessages.emailRegex : '' }
}

