import React, { Component } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { PasswordCriteria, PasswordHints } from '../../shared/PasswordCriteria';

export default class AdminPasswordResetComponent extends Component {
    constructor(props) {
        super(props);
        this.resetFormRef = React.createRef();
    }

    /**
     * @description Function to handle form reset
     * @memberof AdminPasswordResetComponent
     */
    /* istanbul ignore next  */
    resetForm = () => {
        this.resetFormRef.reset();
    }

    componentDidUpdate() {
        /* istanbul ignore next  */
        if (this.props.successMessage || this.props.formErrors.cognitoError) {
            this.resetForm();
        }
    }

    render() {
        const { handleResetPassword, handleChange, handleBlur, formErrors, loading, successMessage, onEnterPress, user, handleCancel, showStatusChangeSuccess,
            pwdStrengthLabel, pwdStrengthProgress, pwdStrengthVarient, pwdMatchObj, resetPwdErrMsg, newPassword, confirmPassword } = this.props;
        return (
            <div>
                <form autoComplete="off" ref={(el) => this.resetFormRef = el}>
                    {showStatusChangeSuccess && successMessage ?
                        <div className="reset-password p-0">
                            <div className="row">
                                <div className="col-12 text-center pwd-success">
                                    <p>{successMessage}</p>
                                </div>
                                <div className="col-12 text-center">
                                    <button type="button" className="btn btn-primary ms-2" id="cancel" onClick={handleCancel}>Okay</button>
                                </div>
                            </div>
                        </div>
                        : <>
                            <input type="hidden" value="autoCompleteOff" />
                            <div className="reset-password p-0">
                                {successMessage ?
                                    <div className="reset-pwd-success-msg mt-0">
                                        <p>{successMessage}</p>
                                    </div>
                                    : ''}
                                {formErrors && formErrors.cognitoError ?
                                    <div className="reset-pwd-error-msg mt-0">
                                        <p>{formErrors.cognitoError}</p>
                                    </div>
                                    : ''}
                                <div className="row">
                                    <label className="col-sm-3 col-form-label">Name</label>
                                    <div className="col-sm-9">
                                        <div className="reset-password-input-wrap name">
                                            <input
                                                type="text"
                                                id="name"
                                                autoComplete="off"
                                                className="form-control"
                                                disabled
                                                value={user && user.name ? user.name : 'N/A'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-sm-3 col-form-label">Username</label>
                                    <div className="col-sm-9">
                                        <div className="reset-password-input-wrap username">
                                            <input
                                                type="text"
                                                id="userName"
                                                autoComplete="off"
                                                className="form-control"
                                                disabled
                                                value={user && user.userName ? user.userName : 'N/A'}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* New Password */}
                                <div className="row reset-password-input-wrap">
                                    <label className="col-sm-3 col-form-label">New Password</label>
                                    <div className="col-sm-9">
                                        <div className="new-password">
                                            <input placeholder="Enter new password"
                                                type="password"
                                                className="form-control"
                                                name="newPassword"
                                                autoComplete="new-password"
                                                value={newPassword}
                                                disabled={loading}
                                                onChange={(e) => handleChange(e.target.value, 'newPassword')}
                                                onBlur={(e) => handleBlur(e.target.value, 'newPassword')}
                                                onKeyPress={onEnterPress}
                                                id="newPassword" />
                                            {/* Password Hint */}
                                            <PasswordHints containerClass="admin-reset-password-hint" />
                                            {formErrors && formErrors.newPassword ?
                                                <div className="error-block">
                                                    <p className="m-0 reset-password-error-msg">{formErrors.newPassword}</p>
                                                </div>
                                                : ''}
                                            {pwdStrengthLabel ?
                                                <div className="new-password-errors mt-4">
                                                    <ProgressBar variant={pwdStrengthVarient} now={pwdStrengthProgress} label={pwdStrengthLabel} />
                                                </div>
                                                : null}
                                            {formErrors && formErrors.newPasswordMatch ?
                                                <div className="reset-password-error-msg">
                                                    <PasswordCriteria pwdMatchObj={pwdMatchObj} errorMessages={resetPwdErrMsg} />
                                                </div>
                                                : ''}
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-12">
                                        <PasswordHints />
                                    </div> */}
                                </div>

                                {/* Confirm password */}
                                <div className="row">
                                    <label className="col-sm-3 col-form-label pe-0">Confirm Password</label>
                                    <div className="col-sm-9">
                                        <div className="reset-password-input-wrap confirm-password">
                                            <input placeholder="Confirm new password"
                                                type="password"
                                                className="form-control"
                                                name="confirmPassword"
                                                autoComplete="confirm-password"
                                                value={confirmPassword}
                                                disabled={loading}
                                                onChange={(e) => handleChange(e.target.value, 'confirmPassword')}
                                                onKeyPress={onEnterPress}
                                                id="confirmPassword" />
                                            {formErrors && formErrors.confirmPassword ?
                                                <div className="error-block">
                                                    <p className="m-0 reset-password-error-msg">{formErrors.confirmPassword}</p>
                                                </div>
                                                : ''}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 text-end">
                                        {loading ?
                                            <button type="button" className="btn btn-primary disabled">
                                                <span className="spinner-border spinner-border-sm"></span>
                                                &nbsp;Updating Password...
                                            </button>
                                            : <button type="button" className="btn btn-primary proms-btn-color" id="saveChanges" onClick={handleResetPassword} >Save and Update</button>}
                                        {user && user.isStatusChange ? '' : <button type="button" className="btn btn-secondary ms-2" id="cancel" onClick={handleCancel}>Close</button>}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </form>
            </div>
        )
    }
}
