import React, { Component } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { signedRoundOff } from '../../../helpers/GlobalFunctions';
import { noDataFound } from '../../../helpers/messages'
class AlignmentDetails extends Component {
    render() {
        const { alignmentData } = this.props;
        let alignmentForAll = [];
        let alignmentForVarus = [];
        let alignmentForValgus = [];
        /* istanbul ignore next  */
        alignmentData && alignmentData.forEach(element => {
            if (element.all) {
                alignmentForAll = element.all;
            }
            if (element.varus) {
                alignmentForVarus = element.varus;
            }
            if (element.valgus) {
                alignmentForValgus = element.valgus;
            }
        });
        return (<div>
            <div className="dashboard-blocks">
                <div className="dashboard-blocks-body">
                    <div className="dashboard-block-title">Alignment</div>
                    <div className="quadrant-tabs">
                        <Tab.Container defaultActiveKey="all">
                            <Tab.Content className="tab-content-height">
                                <Tab.Pane eventKey="all">
                                    <div className="row quadrant-alignment-row-heading">
                                        <div className="col-md-3">Pre-Op</div>
                                        <div className="col-md-3"># Cases</div>
                                        <div className="col-md-3">Planned</div>
                                        <div className="col-md-3">Achieved</div>
                                    </div>
                                    {alignmentForAll.map(function (item, i) {
                                        return <div key={i} className="row quadrant-alignment-row-values">
                                            <div className="col-md-3">{item.timeRange}° V/v</div>
                                            <div className="col-md-3">{item.noOfCases}</div>
                                            <div className="col-md-3">{item.noOfCases > 0 && !isNaN(parseFloat(item.plannedAverage)) ? signedRoundOff(item.plannedAverage) + "°" : "-"}</div>
                                            <div className="col-md-3">{item.noOfCases > 0 && !isNaN(parseFloat(item.achivedAverage)) ? signedRoundOff(item.achivedAverage) + "°" : "-"}</div>
                                        </div>
                                    })}
                                    {alignmentForAll.length === 0 ?
                                        <div className="quadrant-alignment-row-values alignment-no-data">
                                            {noDataFound.noData}
                                        </div> : null}
                                    <br />
                                </Tab.Pane>
                                <Tab.Pane eventKey="varus">
                                    <div className="row quadrant-alignment-row-heading">
                                        <div className="col-md-3">Pre-Op</div>
                                        <div className="col-md-3"># Cases</div>
                                        <div className="col-md-3">Planned</div>
                                        <div className="col-md-3">Achieved</div>
                                    </div>
                                    {alignmentForVarus.map(function (item, i) {
                                        return <div key={i} className="row quadrant-alignment-row-values">
                                            <div className="col-md-3">{item.timeRange}° varus</div>
                                            <div className="col-md-3">{item.noOfCases}</div>
                                            <div className="col-md-3">{item.noOfCases > 0 && !isNaN(parseFloat(item.plannedAverage)) ? signedRoundOff(item.plannedAverage) + "°" : "-"}</div>
                                            <div className="col-md-3">{item.noOfCases > 0 && !isNaN(parseFloat(item.achivedAverage)) ? signedRoundOff(item.achivedAverage) + "°" : "-"}</div>
                                        </div>
                                    })}
                                    {alignmentForVarus.length === 0 ?
                                        <div className="quadrant-alignment-row-values alignment-no-data">
                                            {noDataFound.noData}
                                        </div> : null}
                                    <br />
                                </Tab.Pane>
                                <Tab.Pane eventKey="valgus">
                                    <div className="row quadrant-alignment-row-heading">
                                        <div className="col-md-3">Pre-Op</div>
                                        <div className="col-md-3"># Cases</div>
                                        <div className="col-md-3">Planned</div>
                                        <div className="col-md-3">Achieved</div>
                                    </div>
                                    {alignmentForValgus.map(function (item, i) {
                                        return <div key={i} className="row quadrant-alignment-row-values">
                                            <div className="col-md-3">{item.timeRange}° valgus</div>
                                            <div className="col-md-3">{item.noOfCases}</div>
                                            <div className="col-md-3">{item.noOfCases > 0 && !isNaN(parseFloat(item.plannedAverage)) ? signedRoundOff(item.plannedAverage) + "°" : "-"}</div>
                                            <div className="col-md-3">{item.noOfCases > 0 && !isNaN(parseFloat(item.achivedAverage)) ? signedRoundOff(item.achivedAverage) + "°" : "-"}</div>
                                        </div>
                                    })}
                                    {alignmentForValgus.length === 0 ?
                                        <div className="quadrant-alignment-row-values alignment-no-data">
                                            {noDataFound.noData}
                                        </div>
                                        : null}
                                    <br />
                                </Tab.Pane>
                            </Tab.Content>
                            <Nav variant="pills" className="flex-row">
                                <Nav.Item>
                                    <Nav.Link eventKey="all">All</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="varus">Varus</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="valgus">Valgus (-)</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </div>);
    }
}
export default AlignmentDetails;
