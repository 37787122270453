import { validateWhiteSpace } from "../../helpers/GlobalFunctions";
import { customerMessages, licenseMessages } from "../../helpers/messages";

/**
 * @description function for validate each input field
 * @param stateObj Current state object
 * @returns Object with error messages
 */
export const validateCustomerFields = (stateObj) => {
    const fields = ['customername', 'addressline1', 'postalcode', 'country', 'state', 'countrydialcode', 'phonenumber'];
    var { errors } = stateObj;
    var errorFlag = false;
    
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const reWhiteSpace = new RegExp(/^\s+$/);
        if (!stateObj[field]  || reWhiteSpace.test(stateObj[field]) === true) {
            errorFlag = true;
            errors[field] = customerMessages[field];
            break;
        } else if (field === 'phonenumber' && stateObj[field].length !== 10) {
            errorFlag = true;
            errors[field] = customerMessages.phoneRegex;
            break;
        } else {
            errors[field] = '';
        }
    }

    return {errorFlag, errors};
}

/**
 * @description function for validate each input field
 * @param stateObj License state object
 * @returns Object with error messages
 */
 export const validateLicenseFields = (stateObj) => {
    const fields = ['licenseStartDate', 'duration','licenseType'];
    var { errors } = stateObj;
    var errorFlag = false;
    
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const reWhiteSpace = new RegExp(/^\s+$/);
        if (!stateObj[field]  || reWhiteSpace.test(stateObj[field]) === true) {
            errorFlag = true;
            errors[field] = licenseMessages[field];
            break;
        }
    }

    return {errorFlag, errors};
}

/**
 * @description function for validate phone number
 * @param value Phone number
 * @returns Object with error messages
 */
export const validatePhoneNumber = (value) => {
    if (validateWhiteSpace(value)) {
        return { errorFlag: true, error: customerMessages.whiteSpace }
    }
    const phoneRegex = new RegExp("^[0-9]{0,10}$");
    var errorFlag = !phoneRegex.test(value);
    return { errorFlag, error: errorFlag ? customerMessages.phoneRegex : '' }
}

/**
 * @description Function to check if the input value is alphanumeric
 * @param value Input value
 * @returns Object with error messages
 */
export const validateAlphanumeric = (value) => {
    if (validateWhiteSpace(value)) {
        return { errorFlag: true, error: customerMessages.whiteSpace }
    }
    const alphaNumericRegex = new RegExp("^[a-zA-Z0-9 ]+$");
    var errorFlag = !alphaNumericRegex.test(value);
    return { errorFlag: errorFlag ? true : false, error: errorFlag ? customerMessages.alphaNumericRegex : '' }
}
