import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { logoutService, getUserDetails, getUserRoleType, getUserName } from "../../services/aws/aws-services";
import IdleTimer from "react-idle-timer";
import { getNotificationCount } from '../../services/java/java-services';
import { checkLocalStorage, checkSessionStorage, getLocalStorage, removeSessionStorage, setLocalStorage } from '../../services/storage/storage-service';
import { logger } from '../../services/logger/logger-service';
import { loggerEventOutcome, loggerEventTypes, loggerEventName, loggerEventMessage } from '../../helpers/messages';
import { arrayIncludesKey, getImgSrc } from '../../helpers/GlobalFunctions';
import CustomModal from '../../shared/CustomModal';
import BillOfMaterialsContainer from '../../containers/BillOfMaterials';
import environment from '../../config/environment';
import SideMenuToggle from '../SideMenuToggle/SideMenuToggle';
import { Roles } from '../../helpers/constants';
import { allowedResponsive } from '../../constants/allowedResponsive/allowedResponsive';

class Sidemenu extends Component {
	constructor(props) {
		super(props);
		const { pathname } = this.props.location;
		const getUserDetail = getUserDetails();
		const userName = getUserName();
		const loggedInUserType = getUserRoleType();
		this.state = {
			firstName: getUserDetail ? getUserDetail.given_name : "",
			lastName: getUserDetail ? getUserDetail.family_name : "",
			userName: userName,
			loggedInUserType: loggedInUserType,
			remaining: this.timeout,
			isIdle: false,
			lastActive: new Date(),
			elapsed: 0,
			navSelected: pathname,
			notificationCount: null,
			notifError: null,
			loggingOut: false,
			showBOMModal: false,
			isSideMenuOpen: false,
			isMobile: window.innerWidth <= 1024, // to check device is mobile or not
		};

		this.idleTimer = null;
		this.timeout = 1000 * 60 * 10; // 10 minute timeout

		this.handleOnAction = this.handleOnAction.bind(this);
		this.handleOnActive = this.handleOnActive.bind(this);
		this.handleOnIdle = this.handleOnIdle.bind(this);
		this.handleResize = this.handleResize.bind(this);
	}

	handleResize() {
		this.setState({ isMobile: window.innerWidth <= 1024 });
	}

	// clear session on page reopen using ctrl+shfit+t
	/* istanbul ignore next */
	resetSessionOnTabReopen() {
		if (this.state.firstName && performance && performance.getEntriesByType) {
			var perfEntries = performance.getEntriesByType("navigation");
			for (var i = 0; i < perfEntries.length; i++) {
				var p = perfEntries[i];
				if (p.type === "back_forward") {
					removeSessionStorage('useractivestatus');
				}
			}
		}
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
		this.resetSessionOnTabReopen();
		/* istanbul ignore next */
		if (!checkSessionStorage("useractivestatus") && !this.state.loggingOut) {
			this.logout();
		}

		/* istanbul ignore next */
		this.setState({
			remaining: this.idleTimer.getRemainingTime(),
			lastActive: this.idleTimer.getLastActiveTime(),
			elapsed: this.idleTimer.getElapsedTime(),
		});

		/* istanbul ignore next  */
		this.timer = setInterval(() => {
			this.setState({
				remaining: this.idleTimer.getRemainingTime(),
				lastActive: this.idleTimer.getLastActiveTime(),
				elapsed: this.idleTimer.getElapsedTime(),
			});
		}, 1000);

		// Set Notification count
		if (!this.props.notificationCount && !this.props.hideMenu) {
			// Allowing time for the state to be set before it is altered again
			/* istanbul ignore next */
			setTimeout(() => {
				/* istanbul ignore next */
				this.getAllNotificationsCount();
			}, 0);
		}
	}

	componentDidUpdate() {
		/* istanbul ignore next */
		if (this.props.uploadProgress && !isNaN(parseInt(this.props.uploadProgress)) && this.props.uploadProgress < 100) {
			this.idleTimer.pause();
		} else {
			this.idleTimer.resume();
		}
	}

	async applicationLogger(key) {
		const loggerObj = {
			"EventOutcome": loggerEventOutcome.success,
			"EventType": loggerEventTypes.logout,
			"EventName": loggerEventName.logout,
			"Content": {
				"Data": loggerEventMessage.logout
			}
		}
		await logger(loggerObj);
	}

	// logs are tracked in logout if logs not tracked in component will unmount
	/* istanbul ignore next  */
	async currentPageLogger() {
		if (this.props.loggerObj) {
			const timeSinceLoad = (new Date().getTime() - this.props.loggerObj.StartDate.getTime()) / 1000;
			const loggerObj = {
				"EventOutcome": this.props.loggerObj.EventOutcome,
				"EventType": this.props.loggerObj.EventType,
				"EventName": this.props.loggerObj.EventName,
				"Content": {
					"TimeSpent": timeSinceLoad
				}
			}
			await logger(loggerObj);
		}
	}

	/* istanbul ignore next  */
	getAllNotificationsCount() {
		getNotificationCount((err, result) => {
			if (err) {
				this.setState({ notifError: err });
			} else {
				const count = result.data.notificationDeatails && result.data.notificationDeatails.length && result.data.notificationDeatails[0].notificationcount ? result.data.notificationDeatails[0].notificationcount : 0;
				setLocalStorage('notif_count', count);
				this.setState({
					notifError: null,
					notificationCount: count
				})
			}
		})
	}

	// What to do when there is user interaction
	/* istanbul ignore next */
	handleOnAction(event) {
		if (!localStorage.getItem('cognito_data') && !this.state.loggingOut) {
			this.logout();
		} else {
			/* istanbul ignore next */
			const userName = getUserName();
			if (this.state.userName !== userName) {
				const getUserDetail = getUserDetails();
				this.setState({
					firstName: getUserDetail ? getUserDetail.given_name : "",
					lastName: getUserDetail ? getUserDetail.family_name : "",
					userName: userName
				})
			}
		}
	}

	// What to do when the user hasn't done anything in 10 min
	/* istanbul ignore next */
	handleOnIdle(event) {
		if (!this.state.loggingOut) {
			this.logout();
		}
	}

	// What to do when the user go from idle to active; doesn't apply here
	/* istanbul ignore next */
	handleOnActive(event) { }

	/* istanbul ignore next  */
	componentWillUnmount() {
		clearInterval(this.timer);
		window.removeEventListener('resize', this.handleResize);
	}
	/* istanbul ignore next  */
	toggleBOMModal = () => {
		this.state.showBOMModal ? this.setState({ showBOMModal: false })
			: this.setState({ showBOMModal: true });
	}

	/**
	 * @description function to handle logout functionality
	 * @param {*}
	 * @memberof Sidemenu
	 */
	/* istanbul ignore next  */
	logout = async (e) => {
		this.setState({ loggingOut: true });
		if (e) e.preventDefault();

		Promise.all([
			this.currentPageLogger(),
			this.applicationLogger()
		]).then((res) => {
			setTimeout(() => {
				logoutService(function (err, res) { });
			}, 500);
		});
	};

	/* istanbul ignore next */
	showSideMenuOnMobile = () => {
		this.state.isSideMenuOpen ? this.setState({ isSideMenuOpen: false }) : this.setState({ isSideMenuOpen: true })
	}

	/**
	 * @description function to handle user's role display
	 * @param {*}
	 * @memberof Sidemenu
	 */
	getRoleNames = () => {
		const { loggedInUserType } = this.state;
		if (!loggedInUserType || !loggedInUserType.length) {
			return '';
		}
		var rolesText = '';
		loggedInUserType.forEach((userRole, i) => {
			let role = Roles.find(x => x.userRole === userRole);
			rolesText += role ? `${i > 0 ? ', ' : ''}${role.label}` : '';
		});

		return rolesText;
	}

	/**
	 * @description function to handle user's name display
	 * @param {*}
	 * @memberof Sidemenu
	 */
	/* istanbul ignore next  */
	getUserName = (name) => {
		return name?.length > 20 ? `${name.substring(0, 20)}...` : name;
	}

	render() {
		const { isMobile, loggedInUserType, firstName, lastName } = this.state;
		const { hideMenu } = this.props;
		/* istanbul ignore next */
		const notifCount = !isNaN(this.props.notificationCount) ? this.props.notificationCount :
			(this.state.notificationCount ? this.state.notificationCount :
				(checkLocalStorage('notif_count') ? parseInt(getLocalStorage('notif_count')) : null));

		return (
			<>

				{arrayIncludesKey(allowedResponsive,loggedInUserType) && isMobile && (loggedInUserType.includes('salesrep') || loggedInUserType.includes('')) && <SideMenuToggle showSideMenuOnMobile={this.showSideMenuOnMobile} isSideMenuOpen={this.state.isSideMenuOpen} />}
				<div className={`sidemenu-wrap ${this.state.isSideMenuOpen ? "show-sidebar" : "hide-sidebar"}`}>
					<IdleTimer
						ref={(ref) => {
							this.idleTimer = ref;
						}}
						timeout={this.timeout}
						onActive={this.handleOnActive}
						onIdle={this.handleOnIdle}
						onAction={this.handleOnAction}
					/>
					<div className="sidenav-profile">
						<div className="row application-logo">
							<div className="col-md-4">
								<img src={getImgSrc('Logo.png')} alt="Logo" />
							</div>
							<div className="col-md-6 application-text">RI.Insights</div>
						</div>
						<div className="surgeon-info text-center">
							<div className="profile mb-3">
								<img src={getImgSrc('no-profile-image.png')} alt="Profile" />
							</div>
							<span className="d-block user-name" title={`${firstName} ${lastName}`}>{this.getUserName(`${firstName} ${lastName}`)}</span>
							<span className="d-block role-info fs-8-rem">{this.getRoleNames()}</span>
						</div>
						<div className="logout">
							<a href="/login" id="logout" onClick={this.logout} title="Log out">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
									<path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
									<path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
								</svg>
								<span>Logout</span>
							</a>
						</div>
					</div>
					<div id="mySidenav" className="sidenav">
						{hideMenu ? '' :
							<nav>
								<ul>
									{loggedInUserType.includes('surgeon') ?
										<li className={this.state.navSelected === '/notifications' ? 'slctd' : ''}>
											{this.state.navSelected === '/notifications' ?
												<svg width="24" height="24" viewBox="0 0 24 24" className="notif-icon" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M19.8174 17.5368C19.2997 17.0242 18.8464 16.4365 18.4698 15.7896C18.0586 14.8967 17.8122 13.9215 17.745 12.9214V9.97561C17.7485 8.40471 17.2354 6.88643 16.3021 5.70603C15.3688 4.52564 14.0795 3.76439 12.6765 3.56531V2.79607C12.6765 2.58494 12.601 2.38246 12.4666 2.23316C12.3321 2.08387 12.1498 2 11.9597 2C11.7696 2 11.5873 2.08387 11.4529 2.23316C11.3185 2.38246 11.243 2.58494 11.243 2.79607V3.57723C9.85249 3.79067 8.57878 4.55652 7.65773 5.73295C6.73669 6.90937 6.23073 8.41665 6.23356 9.97561V12.9214C6.16631 13.9215 5.91988 14.8967 5.50872 15.7896C5.13873 16.435 4.69272 17.0226 4.18255 17.5368C4.12528 17.5927 4.07938 17.6615 4.0479 17.7386C4.01643 17.8157 4.0001 17.8994 4 17.984V18.795C4 18.9531 4.05657 19.1048 4.15726 19.2167C4.25795 19.3285 4.39451 19.3913 4.53691 19.3913H19.4631C19.6055 19.3913 19.7421 19.3285 19.8427 19.2167C19.9434 19.1048 20 18.9531 20 18.795V17.984C19.9999 17.8994 19.9836 17.8157 19.9521 17.7386C19.9206 17.6615 19.8747 17.5927 19.8174 17.5368ZM5.11678 18.1987C5.61633 17.6627 6.05616 17.0621 6.42685 16.4098C6.94476 15.3313 7.24694 14.1414 7.31275 12.9214V9.97561C7.29146 9.27676 7.39701 8.5803 7.62311 7.92769C7.84922 7.27509 8.19125 6.67969 8.62886 6.17696C9.06646 5.67422 9.59068 5.27442 10.1703 5.00138C10.7499 4.72833 11.3731 4.58762 12.0027 4.58762C12.6323 4.58762 13.2555 4.72833 13.8351 5.00138C14.4147 5.27442 14.9389 5.67422 15.3765 6.17696C15.8141 6.67969 16.1562 7.27509 16.3823 7.92769C16.6084 8.5803 16.7139 9.27676 16.6926 9.97561V12.9214C16.7584 14.1414 17.0606 15.3313 17.5785 16.4098C17.9492 17.0621 18.389 17.6627 18.8886 18.1987H5.11678Z" fill="#ffffff" />
													<path d="M12.0362 21.9998C12.4924 21.99 12.9302 21.8302 13.2722 21.5487C13.6141 21.2672 13.8382 20.8821 13.9047 20.4615H10.0952C10.1636 20.8935 10.3982 21.2877 10.7552 21.5707C11.1123 21.8536 11.5675 22.0061 12.0362 21.9998Z" fill="#ffffff" />
												</svg>
												: <svg width="24" height="24" viewBox="0 0 24 24" fill="white" className="notif-icon" xmlns="http://www.w3.org/2000/svg">
													<path d="M19.8174 17.5368C19.2997 17.0242 18.8464 16.4365 18.4698 15.7896C18.0586 14.8967 17.8122 13.9215 17.745 12.9214V9.97561C17.7485 8.40471 17.2354 6.88643 16.3021 5.70603C15.3688 4.52564 14.0795 3.76439 12.6765 3.56531V2.79607C12.6765 2.58494 12.601 2.38246 12.4666 2.23316C12.3321 2.08387 12.1498 2 11.9597 2C11.7696 2 11.5873 2.08387 11.4529 2.23316C11.3185 2.38246 11.243 2.58494 11.243 2.79607V3.57723C9.85249 3.79067 8.57878 4.55652 7.65773 5.73295C6.73669 6.90937 6.23073 8.41665 6.23356 9.97561V12.9214C6.16631 13.9215 5.91988 14.8967 5.50872 15.7896C5.13873 16.435 4.69272 17.0226 4.18255 17.5368C4.12528 17.5927 4.07938 17.6615 4.0479 17.7386C4.01643 17.8157 4.0001 17.8994 4 17.984V18.795C4 18.9531 4.05657 19.1048 4.15726 19.2167C4.25795 19.3285 4.39451 19.3913 4.53691 19.3913H19.4631C19.6055 19.3913 19.7421 19.3285 19.8427 19.2167C19.9434 19.1048 20 18.9531 20 18.795V17.984C19.9999 17.8994 19.9836 17.8157 19.9521 17.7386C19.9206 17.6615 19.8747 17.5927 19.8174 17.5368ZM5.11678 18.1987C5.61633 17.6627 6.05616 17.0621 6.42685 16.4098C6.94476 15.3313 7.24694 14.1414 7.31275 12.9214V9.97561C7.29146 9.27676 7.39701 8.5803 7.62311 7.92769C7.84922 7.27509 8.19125 6.67969 8.62886 6.17696C9.06646 5.67422 9.59068 5.27442 10.1703 5.00138C10.7499 4.72833 11.3731 4.58762 12.0027 4.58762C12.6323 4.58762 13.2555 4.72833 13.8351 5.00138C14.4147 5.27442 14.9389 5.67422 15.3765 6.17696C15.8141 6.67969 16.1562 7.27509 16.3823 7.92769C16.6084 8.5803 16.7139 9.27676 16.6926 9.97561V12.9214C16.7584 14.1414 17.0606 15.3313 17.5785 16.4098C17.9492 17.0621 18.389 17.6627 18.8886 18.1987H5.11678Z" fill="#8a8a8a" />
													<path d="M12.0362 21.9998C12.4924 21.99 12.9302 21.8302 13.2722 21.5487C13.6141 21.2672 13.8382 20.8821 13.9047 20.4615H10.0952C10.1636 20.8935 10.3982 21.2877 10.7552 21.5707C11.1123 21.8536 11.5675 22.0061 12.0362 21.9998Z" fill="#8a8a8a" />
												</svg>}
											{notifCount ? <span className="notification-count">{notifCount}</span> : ''}
											<Link to="/notifications" style={{ margin: 'auto', height: 60 }}>
												Notifications
											</Link>
										</li> : ''}

									{/* logout button for iPhone(landscape) and ipads */}
									<li className="show-on-mobile show-ipad">
										<a href="/login" id="logout" onClick={this.logout} title="">
											<img
												src={getImgSrc('Logout.png')}
												alt="logout"
												className="logout-img-style"
												width={64}
												height={64}
											/></a>
									</li>

									<li className={this.state.navSelected === '/dashboard' ? 'slctd' : ''}>
									<Link to="/dashboard"  >
										<img  className="img-style" src={this.state.navSelected === '/dashboard' ? getImgSrc('dashboard-white.png') : getImgSrc('dashboard-black.png')} alt="Dashboard" />
										</Link>
										<Link to="/dashboard" className="sidebar-text" style={{ margin: 'auto', height: 60 }}>
											Dashboard
										</Link>
									</li>
									
									{/* Surgeon Navigation Links */}
									{loggedInUserType.includes('surgeon') ?
										<div>

											<li className={this.state.navSelected === '/caselist' ? 'slctd' : ''}>
												<img src={this.state.navSelected === '/caselist' ? getImgSrc('caselist-white.png') : getImgSrc('caselist-black.png')} alt="CaseList" />
												<Link to="/caselist" className='sidebar-text' >
													Case List
												</Link>
											</li>
											<li className={this.state.navSelected === '/caseplanningtool' ? 'slctd' : ''}>
												<img src={this.state.navSelected === '/caseplanningtool' ? getImgSrc('plannig-tool-white.png') : getImgSrc('plannig-tool-black.png')} alt="calculator" />
												<Link to="/caseplanningtool" className='sidebar-text'>
													Planning Tool
												</Link>
											</li>
										</div> : null}

									{/* SalesRep Navigation Links */}
									{loggedInUserType.includes('salesrep') ?
										<>
											<li className={this.state.navSelected === '/planlist' ? 'slctd' : ''}>
												<Link to="/planlist" >
													<img src={this.state.navSelected === '/planlist' ? getImgSrc('preop-plan-white.png') : getImgSrc('preop-plan-black.png')} alt="PlanList" className='preop-sidebar-icon  img-style' />
												</Link>
												<Link to="/planlist" style={{ margin: 'auto', height: 60 }} className="sidebar-text">
													Pre-op Plans
												</Link>
											</li>
										</> : null}


									{/* RAR/CareTeam Navigation Links */}
									{loggedInUserType.includes('rar') || loggedInUserType.includes('careteam') ?
										<div>
											<li className={this.state.navSelected === '/upload' ? 'slctd' : ''}>
											<Link to="/upload" >
												<img src={this.state.navSelected === '/upload' ? getImgSrc('file-upload-white.png') : getImgSrc('file-upload-black.png')} alt="FileUpload"  className=' img-style' />
												</Link>
												<Link to="/upload" className='sidebar-text' >
													Upload
												</Link>
											</li>
											<li className={this.state.navSelected === '/fileslist' ? 'slctd' : ''}>
											<Link to="/fileslist" >
												<img src={this.state.navSelected === '/fileslist' ? getImgSrc('files-list-white.png') : getImgSrc('files-list-black.png')} alt="FileList" className=' img-style' />
												</Link>
												<Link to="/fileslist" className='sidebar-text'>
													Files List
												</Link>
											</li>
											<li className={this.state.navSelected === '/caselist' ? 'slctd' : ''}>
											<Link to="/caselist" >
												<img src={this.state.navSelected === '/caselist' ? getImgSrc('caselist-white.png') : getImgSrc('caselist-black.png')} alt="CaseList" className=' img-style' />
												</Link>
												<Link to="/caselist" className='sidebar-text'>
													Case List
												</Link>
											</li>
										</div> : null}

									{/* Device list link */}
									{loggedInUserType.includes('admin') ?
										<li className={this.state.navSelected === '/deviceList' ? 'slctd' : ''}>
											<img src={this.state.navSelected === '/deviceList' ? getImgSrc('plannig-tool-white.png') : getImgSrc('plannig-tool-black.png')} alt="calculator" />
											<Link to="/deviceList" className='sidebar-text'>
												Device List
											</Link>
										</li>
										: ''}

									{/* Customer Support Navigation Links */}
									{loggedInUserType.includes('customersupport') ?
										<>
											<li className={this.state.navSelected === '/preOpPlans' ? 'slctd' : ''}>
												<img src={this.state.navSelected === '/preOpPlans' ? getImgSrc('preop-plan-white.png') : getImgSrc('preop-plan-black.png')} alt="PlanList" className='preop-sidebar-icon' />
												<Link to="/preOpPlans" style={{ margin: 'auto', height: 60 }}>
													Pre-op Plans
												</Link>
											</li>
										</> : null}

									{/* Admin Navigation Links */}
									{loggedInUserType.includes('admin') ?
										<>
											<li className={this.state.navSelected === '/users' ? 'slctd' : ''}>
												<img src={this.state.navSelected === '/users' ? getImgSrc('caselist-white.png') : getImgSrc('caselist-black.png')} alt="UsersList" />
												<Link to="/users" style={{ margin: 'auto', height: 60 }}>
													Users
												</Link>
											</li>
											<li className={this.state.navSelected === '/customerlist' ? 'slctd' : ''}>
												<img src={this.state.navSelected === '/customerlist' ? getImgSrc('caselist-white.png') : getImgSrc('caselist-black.png')} alt="CustomerList" />
												<Link to="/customerlist" style={{ margin: 'auto', height: 60 }}>
													Customers
												</Link>
											</li>
											<li className={this.state.navSelected === '/surgeonlist' ? 'slctd' : ''}>
												<img src={this.state.navSelected === '/surgeonlist' ? getImgSrc('caselist-white.png') : getImgSrc('caselist-black.png')} alt="SurgeonList" />
												<Link to="/surgeonlist" style={{ margin: 'auto', height: 60 }}>
													Surgeons
												</Link>
											</li>
											<li className={this.state.navSelected === '/logs' ? 'slctd' : ''}>
												<img src={this.state.navSelected === '/logs' ? getImgSrc('files-list-white.png') : getImgSrc('files-list-black.png')} alt="AuditLogs" />
												<Link to="/logs" style={{ margin: 'auto', height: 60 }}>
													Audit Logs
												</Link>
											</li>
										</> : ''}



									<li className={this.state.navSelected === '/settings' ? 'slctd' : ''}>
										<Link to="/settings" >
											<img className="img-style" src={this.state.navSelected === '/settings' ? getImgSrc('setting-white.png') : getImgSrc('setting-black.png')} alt="Settings" />
										</Link>
										<Link to="/settings" className="sidebar-text">
											Settings
										</Link>
									</li>
									<li className={this.state.navSelected === '/help' ? 'slctd help-menu' : 'help-menu'}>
										{this.state.navSelected === '/help' ?
											<Link to="/help" >
												<svg width="39" height="39" viewBox="0 0 24 24" fill="none" className="help-icon" xmlns="http://www.w3.org/2000/svg">
													<path fillRule="evenodd" clipRule="evenodd" d="M12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12ZM12 6C9.79 6 8 7.79 8 10H10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 10.8792 13.4202 11.3236 12.7704 11.8217C11.9421 12.4566 11 13.1787 11 15H13C13 13.9046 13.711 13.2833 14.4408 12.6455C15.21 11.9733 16 11.2829 16 10C16 7.79 14.21 6 12 6ZM13 18V16H11V18H13Z" fill="#fff" />
												</svg>
											</Link>
											: <Link to="/help">
												<svg width="39" height="39" viewBox="0 0 24 24" fill="none" className="help-icon" xmlns="http://www.w3.org/2000/svg">
													<path fillRule="evenodd" clipRule="evenodd" d="M12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5ZM2.5 12C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12ZM12 6C9.79 6 8 7.79 8 10H10C10 8.9 10.9 8 12 8C13.1 8 14 8.9 14 10C14 10.8792 13.4202 11.3236 12.7704 11.8217C11.9421 12.4566 11 13.1787 11 15H13C13 13.9046 13.711 13.2833 14.4408 12.6455C15.21 11.9733 16 11.2829 16 10C16 7.79 14.21 6 12 6ZM13 18V16H11V18H13Z" fill="#8a8a8a" />
												</svg>
											</Link>}

										<Link to="/help" className="sidebar-text">
											Help
										</Link>
									</li>
								</ul>
							</nav>
						}
						<li className="show-on-mobile">
							<a href="/login" id="logout" onClick={this.logout} title="">
								<img src={getImgSrc('Logout.png')} alt="logout" className="logout-img-style" width={64} height={64} />
							</a>
						</li>
					</div>
					<div className="sn-bottom-logo">
						<img src={getImgSrc('sn-bottom-logo.png')} alt="sn-bottom-logo" />
						{loggedInUserType.includes('admin') ?
							<button onClick={this.toggleBOMModal} className="btn btn-link text-muted mt-2 fs-8-rem">App Version: {environment.appVersion}</button>
							:
							<p className="text-muted mt-2 fs-8-rem">App Version: {environment.appVersion}</p>
						}
					</div>

					<div className="bill-of-materials-modal-wrap">
						{/* Modal to add new license */}
						<CustomModal
							id="bill-of-materials-modal"
							dialogClassName="bill-of-materials-dialog"
							show={this.state.showBOMModal}
							isComponent={true}
							title="Bill of Materials"
							closeAction={() => this.toggleBOMModal()}>
							{this.state.showBOMModal ? <BillOfMaterialsContainer /> : ''}
						</CustomModal>
					</div>
				</div>
			</>
		)
	}
}

export default withRouter(props => <Sidemenu {...props} />);
