import { CKEditor } from 'ckeditor4-react';
import React, { Component } from 'react'
import { commentsEditorConfig } from '../../containers/CaseDetails/constants';
import { convertToPlainText, convertUTCToLocalDateTime, parseHTML } from '../../helpers/GlobalFunctions';
import { DataTable } from '../DataTable/DataTable';
import { actions } from '../../helpers/messages';
import ErrorMsgBlockComponent from '../Error/ErrorMessageBlock'


export default class CommentsHistoryComponent extends Component {
    render() {
        const { historyData, viewDetails, selectedComment, toggleCommentDetails, backToListView, commentHistoryLoading, commentsHistoryError } = this.props;
        /* istanbul ignore next  */
        const columns = [
            {
                Header: 'Comment',
                accessor: 'notes',
                Cell: ({ row }) => row.original.notes && row.original.notes ? convertToPlainText(row.original.notes).substring(0, 20) + '...' : 'N/A',
                Filter: ''
            },
            {
                Header: 'Updated By',
                accessor: 'notesUpdatedBy',
                Cell: ({ row }) => row.original.notesUpdatedBy && row.original.notesUpdatedBy ? row.original.notesUpdatedBy : 'N/A',
                Filter: ''
            },
            {
                Header: 'Updated On',
                accessor: 'notesUpdatedDate',
                Cell: ({ row }) => row.original.notesUpdatedDate ? convertUTCToLocalDateTime(row.original.notesUpdatedDate) : 'N/A',
                
                Filter: ''
            },
            {
                Header: ' ',
                commentHistoryAction: true
            }
        ];
        return (
            <>
                {viewDetails ?
                    <>
                        <button onClick={backToListView} className="btn btn-link px-0 fs-8-rem text-dark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                            </svg>
                            <span className="ms-1">Back to History List</span>
                        </button>
                        {selectedComment ?
                            <>
                                <CKEditor
                                    id="ck_details_view_only"
                                    readOnly={true}
                                    config={commentsEditorConfig({ height: 400 })}
                                    data={parseHTML(selectedComment.notes)}
                                    initData={parseHTML(selectedComment.notes)}
                                />
                                <div className="last-updated-text mt-2">
                                    {selectedComment.notesUpdatedBy ?
                                        <div className='button-wrap'>Updated by : <span>{selectedComment.notesUpdatedBy}</span></div> : ''}
                                    {selectedComment.notesUpdatedDate ?
                                        <div className="button-wrap">Updated at : <span>{convertUTCToLocalDateTime(selectedComment.notesUpdatedDate)}</span></div> : ''}
                                </div>
                            </>
                            : commentsHistoryError ? <ErrorMsgBlockComponent errorObject={commentsHistoryError} />
                                : <ErrorMsgBlockComponent noData={true} />}
                    </>
                    : <div className="bom-modal">
                        {commentHistoryLoading ?
                            <div className="loading-overlay"><span>{actions.load}</span></div>
                            : historyData ? <DataTable 
                            listType="comment-history" 
                            columns={columns} 
                            dataSet={historyData} 
                            showPagination={false} 
                            toggleCommentDetails={toggleCommentDetails}
                            disableColmSort={true} />
                            : <ErrorMsgBlockComponent noData={true} />
                        }

                    </div>

                }
            </>
        )
    }
}
