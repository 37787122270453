import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import { graphContextMenuItems } from '../../../helpers/constants';
import { exportAddOns } from '../../../helpers/ChartExports';
import { manualPromScoreFields, PROMScoreTimeIntervals, PROMScoreTypes, scoreTypesForManualScore } from '../../../containers/CaseDetails/constants';
import { convertUTCToLocalDateTime, roundedOffPROMScore, toTitleCase } from '../../../helpers/GlobalFunctions';
import HC_grouped from "highcharts-grouped-categories";

HC_grouped(Highcharts);

// Written outside the class since the dataLabels formatter function is unable to access the correct y value of a point.
/* istanbul ignore next  */
const formatDataLabels = (scoreType, value, noData = false) => {
    if (noData) {
        return '';
    }

    return roundedOffPROMScore(value, scoreType);
}

export default class AvgPROMScores extends Component {
    constructor(props) {
        super(props);
        var min = 0;
        var max = 100;
        var yAxisTickMarks = [0, 50, 100];
        switch (props.defaultScoreType) {
            case 'Oxford Knee Score':
                min = 0;
                max = 48;
                yAxisTickMarks = [0, 24, 48];
                break;
            case 'PROMIS-Global':
                min = 0;
                max = 20;
                yAxisTickMarks = [0, 10, 20];
                break;
            case 'EQ5D':
                min = 1;
                max = 5;
                yAxisTickMarks = [1, 3, 5];
                break;

            default:
                min = 0;
                max = 100;
                yAxisTickMarks = [0, 50, 100];
                break;
        }
        this.state = {
            selectedTab: 'global',
            scoreType: props.defaultScoreType || '',
            min,
            max,
            yAxisTickMarks
        }
    }

    componentDidMount() {

    }

    /**
    * @description Function to get my case or global case data set for no subscore PROMs
    * @memberof AvgPROMScores
    */
    getScoreData(type) {
        const { totalCasesDetails } = this.props;
        return type && totalCasesDetails[type]?.length ? totalCasesDetails[type] : null;
    }

    /**
    * @description Function to get X-axis categories for the graph
    * @memberof AvgPROMScores
    */
    /* istanbul ignore next  */
    getXAxisCategories() {
        const { defaultScoreType } = this.props;
        let myCasesData = this.getScoreData('myCases');
        let globalCasesData = this.getScoreData('globalCases');
        let categories = [];
        if (scoreTypesForManualScore.indexOf(defaultScoreType) > -1) {
            let subScoresTypes = manualPromScoreFields[defaultScoreType] || [];
            PROMScoreTimeIntervals.forEach(timeInterval => {
                if (!categories.find(x => x.name === timeInterval.value)) {
                    let globalCase = globalCasesData?.find(x => x?.timeInterval === timeInterval.value);
                    let myCase = myCasesData?.find(x => x?.timeInterval === timeInterval.value);
                    if (globalCase || myCase) {
                        let categoryObject = {
                            name: timeInterval.value,
                            categories: subScoresTypes.map(x => x.label)
                        }
                        categories.push(categoryObject);
                    }
                }
            })
        } else {
            PROMScoreTimeIntervals.forEach(timeInterval => {
                if (!categories.find(x => x.name === timeInterval.value)) {
                    let globalCase = globalCasesData?.find(x => x?.timeInterval === timeInterval.value);
                    let myCase = myCasesData?.find(x => x?.timeInterval === timeInterval.value);
                    if (globalCase || myCase) {
                        categories.push(timeInterval.value);
                    }
                }
            })
        }

        return categories;
    }

    /**
    * @description Function to get the common config for both the series
    * @memberof AvgPROMScores
    */
    getSeriesCommonConfig() {
        return [
            {
                name: 'Global Cases',
                color: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, '#7ABA43'],
                        [1, '#78CBC1']
                    ]
                },
                dataLabels: {},
                data: [],
            },
            {
                name: 'My Cases',
                color: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                    stops: [
                        [0, '#C22326'],
                        [1, '#F99D20']
                    ]
                },
                dataLabels: {},
                data: [],
            }
        ]
    }

    /**
    * @description Function to get the each data object for subscores
    * @memberof AvgPROMScores
    */
    getDataArray(caseDataSet) {
        let categories = this.getXAxisCategories();
        if (!caseDataSet?.length) {
            return [];
        }
        var data = [];
        const { defaultScoreType, activeTab } = this.props;

        caseDataSet.forEach(caseData => {
            categories.forEach((category, i) => {
                if (category.name === caseData?.timeInterval && caseData?.subScoreTypeList) {
                    const subScoresTypes = manualPromScoreFields[defaultScoreType] || [];
                    const subScoreTypeList = caseData.subScoreTypeList;
                    category?.categories.forEach((cat, j) => {
                        let subscoreObj = subScoresTypes.find(x => x.label === cat);
                        let subscore = subScoreTypeList.find(x => x.type === subscoreObj.type);
                        if (subscore) {
                            data.push({
                                x: j + (i * subScoresTypes.length),
                                y: roundedOffPROMScore(subscore[activeTab], defaultScoreType),
                                z: parseInt(subscore.totalCount)
                            });
                        }
                    })
                } else if (category === caseData?.timeInterval) {
                    data.push({
                        x: i,
                        y: roundedOffPROMScore(caseData[activeTab], defaultScoreType),
                        z: parseInt(caseData.totalCount)
                    });
                }
            })
        })

        return data;
    }

    /**
    * @description Function to get the each data object for subscores if there is no data available
    * @memberof AvgPROMScores
    */
    getNoDataArray(categories) {
        let data = [];
        /* istanbul ignore next  */
        categories.forEach((cat, index) => {
            data.push({
                x: index,
                y: null,
                z: null
            });
        });

        return data;
    }

    /**
    * @description Function to get the series for subscore type PROMs
    * @memberof AvgPROMScores
    */
    handleSeriesSubScore() {
        const { defaultScoreType } = this.props;
        let myCases = this.getScoreData('myCases');
        let globalCases = this.getScoreData('globalCases');
        let series = this.getSeriesCommonConfig();
        series.forEach(serie => {
            serie.dataLabels = {
                formatter: function () {
                    let noData = this.point && this.point.z === null;
                    return formatDataLabels(defaultScoreType, this.y, noData);
                }
            }
            switch (serie.name) {
                case 'Global Cases':
                    serie.data = this.getDataArray(globalCases);
                    break;
                case 'My Cases':
                    serie.data = this.getDataArray(myCases);
                    break;

                default:
                    break;
            }

        });

        return series;
    }

    /**
    * @description Function to get the series for no subscore type PROMs
    * @memberof AvgPROMScores
    */
    handleSeriesNoSubScore() {
        const { defaultScoreType } = this.props;
        let myCases = this.getScoreData('myCases');
        let globalCases = this.getScoreData('globalCases');
        let noMyCaseData = (myCases === null);
        let noGlobalData = (globalCases === null);
        let series = this.getSeriesCommonConfig();
        /* istanbul ignore next  */
        series.forEach(serie => {
            switch (serie.name) {
                case 'Global Cases':
                    serie.dataLabels = {
                        formatter: function () {
                            return formatDataLabels(defaultScoreType, this.y, noGlobalData);
                        }
                    }
                    serie.data = this.getDataArray(globalCases);
                    break;

                case 'My Cases':
                    serie.dataLabels = {
                        formatter: function () {
                            return formatDataLabels(defaultScoreType, this.y, noMyCaseData);
                        }
                    }
                    serie.data = this.getDataArray(myCases);
                    break;

                default:
                    break;
            }
        });

        return series;
    }

    /**
    * @description Function to check if any data is there for plotting
    * @memberof AvgPROMScores
    */
    handleNoDataScenario() {
        let myCasesData = this.getScoreData('myCases');
        let globalCasesData = this.getScoreData('globalCases');

        return !myCasesData && !globalCasesData;
    }

    render() {
        const { totalCasesDetails, operativeProcedure, outcomeScoreMessage, handleOutcomeScoreValueChange, activeTab, defaultScoreType, titleText } = this.props;
        const { min, max, yAxisTickMarks } = this.state;
        const lastCalculationTime = totalCasesDetails && totalCasesDetails.lastCalculationTime ? totalCasesDetails.lastCalculationTime : null;
        const scoreTypeDropdown = PROMScoreTypes[operativeProcedure];
        const isSubScore = (scoreTypesForManualScore.indexOf(defaultScoreType) > -1);
        let isNoData = this.handleNoDataScenario();
        let xAxisCategories = this.getXAxisCategories();
        let series;
        if (isSubScore) {
            series = this.handleSeriesSubScore();
        } else {
            series = this.handleSeriesNoSubScore();
        }
        /* istanbul ignore next  */
        var config = {
            exporting: {
                sourceWidth: 900,
                sourceHeight: isSubScore ? 450 : 350,
                filename: defaultScoreType,
                buttons: {
                    contextButton: {
                        menuItems: graphContextMenuItems
                    },
                },
                csv: {
                    columnHeaderFormatter: function (item, key, keyLength) {
                        return keyLength ? `${item.name} (${defaultScoreType})` : false;
                    }
                },
                chartOptions: {
                    chart: {
                        marginTop: 100,
                        marginBottom: isSubScore ? 150 : 80,
                        events: {
                            load: function () {
                                exportAddOns(this);
                            }
                        }
                    },
                    subtitle: {
                        text: defaultScoreType
                    },
                    title: {
                        text: 'Outcomes Scores',
                        style: {
                            fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif",
                            color: '#333333',
                            fontSize: 18,
                            textAlign: 'center'
                        }
                    }
                }
            },
            chart: {
                type: 'column',
                spacingRight: 0,
                spacingLeft: 5,
                marginTop: 25,
                ignoreHiddenSeries: false,
                events: {
                    load: function () {
                        this.reflow();
                    },
                    exportData: function (e) {
                       e.dataRows.forEach(function(el, index) {
                        if(index !==0 && el.length && el[0].name && el[0].parent) {
                            el[0] = `${el[0].name} - ${el[0].parent?.name}`
                        }
                      });
                    }
                }
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: xAxisCategories,
                startOnTick: true,
                endOnTick: true,
                minRange: isSubScore ? 1 : undefined,
                title: {
                    text: ''
                },
                labels: {
                    rotation: isSubScore && xAxisCategories?.length > 2 ? -90 : 0,
                    align: isSubScore && xAxisCategories?.length > 2 ? "right" : "center",
                    y: isSubScore && xAxisCategories?.length > 2 ? 6 : 18,
                    style: {
                        color: "rgb(102, 102, 102)",
                        fontFamily: "Verdana, sans-serif",
                        fontSize: "13px",
                        border: 'none'
                    },
                    groupedOptions: [{
                        rotation: 0,
                        style: {
                            color: "rgb(102, 102, 102)",
                            fontWeight: "600"
                        }
                    }]
                }
            },
            yAxis: {
                min: min,
                max: max,
                tickPositions: yAxisTickMarks,
                title: ''
            },
            zAxis: {
                visible: false
            },
            plotOptions: {
                column: {
                    shadow: false,
                    centerInCategory: true,
                    borderWidth: 0,
                    minPointLength: 3,
                    grouping: true,
                    groupPadding: 0.1,
                    // dataLabels: {
                    //     enabled: true,
                    //     inside: false,
                    //     crop: true,
                    //     color: 'black',
                    //     overflow: 'allow',
                    //     style: {
                    //         fontSize: '11px',
                    //         textOutline: 'none',
                    //     }
                    // }
                }
            },
            tooltip: {
                formatter: function () {
                    let caseGrammar = this.point.z && this.point.z > 1 ? 'Cases' : 'Case';
                    let tooltip = `<strong>${this.series.name} ${toTitleCase(activeTab)}</strong><br/>`;
                    tooltip += `${this.key.name}: <strong>${this.point.y} </strong><br/>`;
                    tooltip += `Based on <strong>${this.point.z ? this.point.z + '</strong> ' + caseGrammar : 'N/A'}`;
                    return tooltip;
                }
            },
            series: series,
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 600
                        },
                        chartOptions: {
                            xAxis: {
                                labels: {
                                    y: isSubScore && xAxisCategories?.length > 2 ? 6 : 13,
                                    style: {
                                        fontSize: "10px"
                                    },
                                }
                            },
                            plotOptions: {
                                column: {
                                    dataLabels: {
                                        style: {
                                            fontSize: '10px'
                                        }
                                    }
                                }
                            },
                        }
                    },
                    {
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            xAxis: {
                                labels: {
                                    style: {
                                        fontSize: "8px"
                                    },
                                }
                            },
                            plotOptions: {
                                column: {
                                    dataLabels: {
                                        style: {
                                            fontSize: '8px'
                                        }
                                    }
                                }
                            },
                        }
                    }
                ]
            }
        };
        
        return (<>
            <div className="dashboard-blocks">
                <div className="dashboard-blocks-body">
                    <div className="row">
                        <div className="col-md-4 dashboard-block-title outcome-score-title">{titleText || "Outcomes Scores"}</div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <select id="scoreType" defaultValue={defaultScoreType} className="form-select" onChange={(e) => handleOutcomeScoreValueChange(e, 'scoreType')}>
                                {scoreTypeDropdown && scoreTypeDropdown.length && scoreTypeDropdown.map((score, index) => <option key={`score-type-${index}`} value={score.value}>{score.label}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="quadrant-tabs">
                        {isNoData ? <div className="no-data-found">{outcomeScoreMessage ? outcomeScoreMessage.noData : ''}</div>
                            : <HighchartsReact
                                highcharts={Highcharts}
                                options={config}
                                containerProps={{ style: { height: '320px' } }}
                            />}

                    </div>
                    <div className="d-flex align-items-center justify-content-end mt-2">
                        <div className="last-updated-text">Last updated at : <span>{lastCalculationTime ? convertUTCToLocalDateTime(lastCalculationTime) : 'N/A'}</span></div>
                    </div>
                </div>
            </div>
        </>);
    }
}