import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { SurgeonList } from './SurgeonList';
import AddSurgeon from "./AddSurgeon";
import CustomModal from '../../../shared/CustomModal';
import CORISetupPdf from '../../../../src/assets/pdf/CORI_setup_guide.pdf'
import ErrorMsgBlockComponent from '../../Error/ErrorMessageBlock';

class RarComponent extends Component {
	render() {
		const { sinceLastCaseUpload, reviewCaseCount, handleInputChange, findSurgeons, formErrors, searchResults, lastCaseUploadError, reviewCaseCountError, searchResultsError,
			enableAddSurgeonModal, showAddSurgeonModal, isLoading, refreshSurgeonList, refreshDataList, onEnterPress } = this.props;
		const columns = [
			{
				Header: 'Name',
				accessor: 'name',
				Filter: ''
			},
			{
				Header: 'No. of Cases',
				accessor: 'noOfcases',
				Filter: ''
			},
			{
				Header: ' ',
				action: true
			}
		]
		return (<div className="data-list-tabs">
			<div className="row">
				<div className="col-md-3">
					<div className="dashboard-blocks">
						<div className="rar-dashboard-blocks-body rar-dashboard-block-height d-flex flex-column justify-content-evenly">
							{reviewCaseCountError ? <ErrorMsgBlockComponent errorObject={reviewCaseCountError} /> :
								<>
									<div className="rar-dashboard-block-title">{reviewCaseCount || '0'}</div>
									<div className="rar-dashboard-block-sub-title">cases flagged for review</div>
									<div className="rar-dashboard-btn">
										<Link className="btn btn-primary rar-btn-color" id="view-case" to={"/reviewcases"}>Review Cases</Link>
									</div>
								</>}
						</div>
					</div>
					<div className="dashboard-blocks">
						<div className="rar-dashboard-blocks-body rar-dashboard-block-height d-flex flex-column justify-content-evenly">
							{lastCaseUploadError ? <ErrorMsgBlockComponent errorObject={lastCaseUploadError} /> : <>
								<div className="rar-dashboard-block-title">{sinceLastCaseUpload}</div>
								{sinceLastCaseUpload === 'No data uploaded' ? null :
									<div className="rar-dashboard-block-sub-title">since last case upload</div>}
								<div className="rar-dashboard-btn">
									<Link className="btn btn-primary rar-btn-color" id="upload-case" to={"/upload"}>Upload Cases</Link>
								</div>
							</>}
						</div>
					</div>
					<div className="dashboard-blocks">
						<div className="rar-dashboard-blocks-body rar-dashboard-block-height d-flex flex-column justify-content-evenly">
							<div className="rar-dashboard-block-sub-title">Lookup Global IDs</div>
							<div className="rar-dashboard-btn">
								<Link className="btn btn-primary rar-btn-color" id="global-id-btn" to={"/lookup"}>Search</Link>
							</div>
						</div>
					</div>
					<div className="dashboard-blocks">
						<div className="rar-dashboard-blocks-body rar-dashboard-block-height d-flex flex-column justify-content-evenly">
							<div className="rar-dashboard-block-sub-title">Learn how to setup CORI for new RI.Insights customers</div>
							<div className="rar-dashboard-btn">
								<a href={CORISetupPdf} without="true" rel="noopener noreferrer" className="btn btn-primary rar-btn-color" target="_blank">
									CORI Setup
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-9">
					<div className="dashboard-blocks">
						<div className="rar-dashboard-blocks-body rar-dashboard-surgeon-list-body d-flex flex-column justify-content-start">
							<div className="d-flex justify-content-between align-items-center">
                                <div className="dashboard-block-title">Surgeons</div>
                                <div className="add-surgeon-btn mt-1">
                                    <div className="find-surgeon" id="openSurgeonModal" onClick={() => enableAddSurgeonModal(true)}>Find Surgeons {'>>'}</div>
                                </div>
                            </div>
							<div className="rar-surgeon-list-block">
								<SurgeonList columns={columns} showPagination={false} disableColmSort={false} listType="surgeon-map" refreshSurgeonList={refreshSurgeonList} />
							</div>
						</div>
					</div>
				</div>
				<div className="add-surgeon-modal">
					{/* Modal to add surgeon to mapping list */}
					<CustomModal
						id="addSurgeonModal"
						dialogClassName="add-surgeon-dialog"
						show={showAddSurgeonModal}
						isComponent={true}
						isAlert={true}
						alertText="Close"
						title="Add Surgeon"
						closeAction={() => enableAddSurgeonModal(false)}>
						<AddSurgeon
							handleInputChange={handleInputChange}
							isLoading={isLoading}
							onEnterPress={onEnterPress}
							findSurgeons={findSurgeons}
							searchError={searchResultsError}
							formErrors={formErrors}
							searchResults={searchResults}
							refreshDataList={refreshDataList}
						/>
					</CustomModal>
				</div>
			</div>

		</div>);
	}
}
const mapStateToProps = (state) => (
	{
		state
	}
)
const mapDispatchToProps = (dispatch) => ({

})
export default connect(mapStateToProps, mapDispatchToProps)(RarComponent);
