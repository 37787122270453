import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import { noDataFound } from '../../../helpers/messages';
import { graphContextMenuItems, operativeProcedureTypes } from '../../../helpers/constants';
import { exportAddOns } from '../../../helpers/ChartExports';
import { getLearningCurveDisclaimer, getTimingDisclaimer } from '../../../helpers/GlobalFunctions';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import ErrorMsgBlockComponent from '../../Error/ErrorMessageBlock';

class AverageCaseTimeDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: 'avg'
		}
	}

	/**
	* @description Function to handle tab change for case time quadrant
	* @param key The identifier of the selected tab
	* @memberof AverageCaseTimeDetails
	*/
	handleTabs = (key) => {
		if (key) {
			this.setState({ activeTab: key });
		}
	}

	render() {
		const { operativeProcedure, setuptimePreference } = this.props;
		const averageCaseTime = this.props.averageCaseTime ? this.props.averageCaseTime : {};
		const totalTimeData = this.props.totalTimeData ? this.props.totalTimeData : [];
		var timingDataBySurgeryOrder = {};
		var colors = [];
		switch (operativeProcedure) {
			case operativeProcedureTypes.TKA.text:
				if (averageCaseTime && Object.keys(averageCaseTime).length && setuptimePreference) {
					// as per the requirement, Misc should be rolled into Setup
					averageCaseTime.setup = averageCaseTime.setup + (averageCaseTime.misc ? averageCaseTime.misc : '');
					delete averageCaseTime.misc;
				}
				// Check if setuptimePreference is true
				if (setuptimePreference) {
					// If setuptimePreference is true, show Setup
					timingDataBySurgeryOrder = {
						"Setup": averageCaseTime.setup,
						"Registration": averageCaseTime.registration,
						"Planning": averageCaseTime.planning,
						"Cutting": averageCaseTime.cutting,
						"PostOp": averageCaseTime.postOp
					}
					colors = [
						"#79c3ec",
						"#45b864",
						"#e2333b",
						"#a98bc0",
						"#f99d20"
					]
				} else {
					timingDataBySurgeryOrder = {
						"Registration": averageCaseTime.registration,
						"Planning": averageCaseTime.planning,
						"Cutting": averageCaseTime.cutting,
						"PostOp": averageCaseTime.postOp
					};
					colors = [
						"#45b864",
						"#e2333b",
						"#a98bc0",
						"#f99d20"
					];
				}
				break;
			case operativeProcedureTypes.HIP.text:
				timingDataBySurgeryOrder = {
					"Registration": averageCaseTime.registration,
					"Navigation": averageCaseTime.navigation
				}
				colors = [
					"#45b864",
					"#e2333b"
				]
				break;
			default:
				break;
		}

		let timingData = []
		var totalMin = 0;
		for (const [key, value] of Object.entries(timingDataBySurgeryOrder)) {
			var getMin = parseFloat(value / 60).toFixed(1);
			var dataArry = [key, parseFloat(getMin)] // Convert from second to minute 
			totalMin += !isNaN(parseFloat(getMin)) ? parseFloat(getMin) : 0;
			timingData.push(dataArry);
		}

		/* istanbul ignore next  */
		var pieChartConfig = {
			exporting: {
				sourceWidth: 900,
				sourceHeight: 350,
				buttons: {
					contextButton: {
						menuItems: graphContextMenuItems
					},
				},
				chartOptions: {
					title: {
						y: 30,
						x: 0,
						style: {
							fontSize: 10
						}
					},
					plotOptions: {
						pie: {
							size: 210,
							innerSize: 100,
							dataLabels: {
								distance: 20,
								style: {
									fontSize: 10
								}
							}
						}
					},
					chart: {
						marginTop: 40,
						marginBottom: 50,
						spacingBottom: 60,
						events: {
							load: function () {
								exportAddOns(this);
							}
						}
					},
					legend: {
						margin: 0,
						floating: true
					},
					subtitle: {
						text: 'Average Case Time',
						style: {
							fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif",
							color: '#333333',
							fontSize: 18,
							textAlign: 'center'
						}
					}
				}
			},
			chart: {
				type: 'pie',
				events: {
					redraw: function () {
						if (this.fullscreen && this.fullscreen.isOpen) {
							this.update({
								title: { y: 20 },
								subtitle: {
									text: 'Average Case Time',
									style: {
										fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif",
										color: '#333333',
										fontSize: 18,
										textAlign: 'center'
									}
								}
							}, false)
						} else {
							this.update({ subtitle: { text: '' }, title: { y: 10 } }, false)
						}
					}
				}
			},
			plotOptions: {
				pie: {
					center: ["50%", "50%"],
					colors: colors,
					innerSize: 100,
					showInLegend: true,
				}
			},
			tooltip: {
				enabled: true,
				formatter: function () {
					return '<b>' + this.point.name + '</b>: ' + parseFloat(this.point.y) + ' MINUTES ';
				}
			},
			legend: {
				layout: 'vertical',
				align: 'right',
				verticalAlign: 'middle',
				floating: false,
				fontSize: '20px',
				style: {
					fontWeight: 'bold',
					fontSize: '12px',
					color: '#7c7c7b'
				},
				borderWidth: 1
			},
			title: {
				text: parseFloat(totalMin.toFixed(1)) + '<br/> Minutes',
				align: 'center',
				verticalAlign: 'middle',
				y: 10,
				x: -60,
				style: {
					fontWeight: 'bold',
					fontSize: '12px',
					color: '#7c7c7b'
				},
			},
			series: [{
				name: 'Timing Data',
				data: timingData,
				dataLabels: {
					enabled: true,
					formatter: function () {
						return parseFloat(this.point.y);
					}
				}
			}]
		};

		let DataMyCase = []
		let DataGlobalCase = []
		let xAxis = []
		totalTimeData.forEach((data, i) => {
			xAxis.push(i + 1);
			DataMyCase.push({
				x: i,
				y: data.myCase ? parseFloat(parseFloat(data.myCase / 60).toFixed(1)) : null
			})
			DataGlobalCase.push({
				x: i,
				y: data.globalAverage ? parseFloat(parseFloat(data.globalAverage / 60).toFixed(1)) : null
			})
		})
		/* istanbul ignore next  */
		var lineChartConfig = {
			exporting: {
				sourceWidth: 900,
				sourceHeight: 350,
				filename: 'Learning Curve',
				buttons: {
					contextButton: {
						menuItems: graphContextMenuItems
					},
				},
				chartOptions: {
					title: {
						y: 30,
						x: 0,
						style: {
							fontSize: 10
						}
					},
					plotOptions: {
						pie: {
							size: 210,
							innerSize: 100,
							dataLabels: {
								distance: 20,
								style: {
									fontSize: 10
								}
							}
						}
					},
					chart: {
						marginTop: 40,
						marginBottom:  150,
						spacingBottom: 60,
						events: {
							load: function () {
								exportAddOns(this);
							}
						}
					},
					legend: {
						margin: 0,
						floating: true
					},
					subtitle: {
						text: 'Learning Curve',
						style: {
							fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif",
							color: '#333333',
							fontSize: 18,
							textAlign: 'center'
						}
					}
				}
			},
			chart: {
				type: 'spline',
				events: {
					redraw: function () {
						if (this.fullscreen && this.fullscreen.isOpen) {
							this.update({
								title: { y: 20 },
								subtitle: {
									text: 'Average Case Time',
									style: {
										fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial, Helvetica, sans-serif",
										color: '#333333',
										fontSize: 18,
										textAlign: 'center'
									}
								}
							}, false)
						} else {
							this.update({ subtitle: { text: '' }, title: { y: 10 } }, false)
						}
					}
				}
			},
			xAxis: {
				categories: xAxis,
				title: {
					text: 'CASES'
				}
			},
			yAxis: {
				title: {
					text: 'MINUTES'
				}
			},
			tooltip: {
				enabled: true,
				formatter: function () {
                    function getTooltip(yValue, serie) {
                        const val = Math.abs(yValue); // Taking absolute value from the plotted point
                        return `<strong>${serie.name}: ${val} Minutes </strong>`;
                    }
                    var tooltip = getTooltip(this.y, this.series)
                    const series = this.series.chart.series;
                    for (let index = 0; index < series.length; index++) {
                        const serie = series[index];
                        const yValue = serie.processedYData[this.point.index];
                        if (yValue === this.y && this.series.name !== serie.name && serie.visible) {
                            tooltip += `<br/>${getTooltip(yValue, serie)}`;
                        }
                    }

                    return tooltip;
                }
			},
			title: {
				text: ''
			},
			series: [{
				name: 'Global Cases',
				data: DataGlobalCase,
				// dataLabels: {
				// 	enabled: true,
				// 	formatter: function () {
				// 		return parseFloat(this.point.y);
				// 	}
				// },
				color: '#7ABA43'
			},
			{
				name: 'My Cases',
				data: DataMyCase,
				// dataLabels: {
				// 	enabled: true,
				// 	formatter: function () {
				// 		return parseFloat(this.point.y);
				// 	}
				// },
				color: '#DB6D4F'
			}]
		};
		return (
			<div className="dashboard-blocks">
				<div className="dashboard-blocks-body">
					<div className="quadrant-tabs">
						<Tab.Container defaultActiveKey={this.state.activeTab} onSelect={this.handleTabs} >
							<Tab.Content >
								<Tab.Pane eventKey="avg">
									<div className="dashboard-block-title d-flex align-items-center justify-content-between">
										<span>Average Case Time</span>
									</div>
									<div className="avg-casetime-graph mt-2">
										{Object.keys(averageCaseTime).length !== 0 ?
											<>
												<HighchartsReact highcharts={Highcharts} options={pieChartConfig} containerProps={{ style: { height: operativeProcedure === operativeProcedureTypes.HIP.text ? '265px' : '290px' } }} />
												{operativeProcedure === operativeProcedureTypes.HIP.text ? <div className="d-flex align-items-center justify-content-between mt-2">{getTimingDisclaimer()}</div> : ''}
											</>
											:
											<div className="no-data-found"> {noDataFound.noData} </div>}
										<br />
									</div>
								</Tab.Pane>
								<Tab.Pane eventKey="compare">
									<div className="dashboard-block-title d-flex align-items-center justify-content-between">
										<span>Learning Curve</span>
										<div className="mt-2">{getLearningCurveDisclaimer()}</div>
									</div>
									<div className="avg-casetime-graph mt-2">
										{Object.keys(totalTimeData).length !== 0 ?
											<>
												<HighchartsReact highcharts={Highcharts} options={lineChartConfig} containerProps={{ style: { height: operativeProcedure === operativeProcedureTypes.HIP.text ? '265px' : '290px' } }} />
												{operativeProcedure === operativeProcedureTypes.HIP.text ? <div className="d-flex align-items-center justify-content-between mt-2">{getTimingDisclaimer()}</div> : ''}
											</>
											:
											<ErrorMsgBlockComponent noData={true} className="dashboard-blocks-body d-flex align-items-center justify-content-center" />
										}
										<br />
									</div>
								</Tab.Pane>
							</Tab.Content>
							<Nav variant="pills" className="justify-content-center">
								<Nav.Item>
									<Nav.Link eventKey="avg">Average</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="compare">Learning Curve</Nav.Link>
								</Nav.Item>
							</Nav>
						</Tab.Container>
					</div>
				</div>
			</div>
		);
	}
}
export default AverageCaseTimeDetails;
