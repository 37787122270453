import React, { Component } from "react";
import { contactForm } from "../../helpers/messages";
import { CKEditor } from "ckeditor4-react";
export default class ContactForm extends Component {
	constructor() {
		super();
		this.editor = null;
	}
	/* istanbul ignore next */
	componentDidUpdate() {
		if (this.props.isSubmitted && this.editor) {
			this.editor.setData('');
			this.props.toggleIsSubmitted(false);
		}
	}

	/**
	 * @description Function to set an instance for the editor
	 * @param event On ready event from the editor
	 */
	onReady = (event) => {
		this.editor = event.editor;
		this.props.handleInputChange(event);
	}

	render() {
		const { config, isEditMode, editorData, handleInputChange, subject, handleSubmit, maxWordCount, handleChange, isError, errorMessage, maxWordCountError, wordLeft, errors } = this.props;
		
		return (
			<div className="row">
				<div className="col-md-12">
					{isError === true ? <div className="text-success">{contactForm.feedbackSend} </div> : ''}
					{isError === false ? <div className="text-danger"> {errorMessage} </div> : ''}
				</div>
				<div className="col-md-12">
					<form onSubmit={handleSubmit}>
						<label className="mt-3">Subject</label>
						<input
							type="text"
							id="subject"
							className="form-control"
							name="subject"
							placeholder="Subject"
							value={subject}
							onChange={(e) => handleChange(e.target.name, e.target.value, "subject") }
						/>
						<span className="text-danger">{errors.subject}</span>
						<div className="editor-wrapper contact-form mt-4">
							<label>Feedback</label>
							<CKEditor
								id="ck_id"
								readOnly={isEditMode}
								config={config}
								data={editorData}
								onInstanceReady={this.onReady}
								onChange={handleInputChange}
							/>
						</div>
						<span className="m-0 text-danger error-msg-block">
							{errors.editorData}
						</span>
						<div className="editor-footer d-flex justify-content-between align-items-center">
							<div className="error-block">
								{maxWordCountError && <div className="error">{contactForm.wordsExceeded(maxWordCount)}</div>}
							</div>
							<div className="word-count">
								<strong>Words Remaining: </strong>
								{wordLeft}
							</div>
						</div>
						<div className="text-end mt-3">
							<button id="send-btn" type="button" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
